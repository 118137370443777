<template>
  <div>
    <LeadCreateEditDialog
        v-if="showLead"
        :key="showLeadKey"
        :showRightDialog.sync="showLead"
        :clientProp="leadProp"
        :isEdit="editLead"
        @forceUpdate="forceUpdate"
        @getLeads="getReports"
    />
    <div id="warningMsgContainer" v-if="unassignedClients.length" @click="()=>this.$router.push('/queues/clients/unassigned')" style="background-color: #D21919;border-radius: 20px;cursor: pointer" class="mx-5 mb-3 pa-2 d-flex text-center justify-center align-center">
      <h2 style="color: whitesmoke" id="warningMsg">Your firm has new clients who have not been assigned a team member. Click here to assign them.</h2>
    </div>
    <v-data-table
        :items="items"
        :headers="headers"
        @click:row="openLead"
        disable-pagination
        class="row-pointer"
        hide-default-footer
        mobile-breakpoint="0"
        :loading="loading"
    >
      <template v-slot:item.contact_email="{ item }">
        <a :href="`mailto:${item.contact_email}`">
          {{ item.contact_email }}
        </a>
      </template>

      <template v-slot:item.contact_phone="{ item }">
        <a :href="`tel:${item.contact_phone}`">
          {{ item.contact_phone }}
        </a>
      </template>

      <template v-slot:item.originator="{ item }">
        <a :href="`mailto:${item.originator_email}`">
          {{ item.originator }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import LeadCreateEditDialog from "@/components/Lead/LeadCreateEditDialog";

export default {
  name: "HomeManagingPartner",
  components: {LeadCreateEditDialog},
  data: () => ({
    items: [],
    headers: [
      {text: 'Age', value: 'age'},
      {text: 'Status', value: 'lead_status'},
      {text: 'Trade name', value: 'trade_name'},
      {text: 'Point of contact', value: 'point_of_contact'},
      {text: 'Email', value: 'contact_email'},
      {text: 'Phone', value: 'contact_phone'},
      {text: 'Originator', value: 'originator'}
    ],
    loading: true,
    editLead: false,
    leadProp: {},
    showLead: false,
    showLeadKey: 76,
    unassignedClients:[]
  }),
  methods: {
    forceUpdate() {
      this.leads = [];
      this.getReports()
    },
    getSingleLead(item) {
      ClientsService.getSingleLead(item.lead_id)
          .then(res => {
            this.leadProp = res
            this.editLead = true
            this.showLead = true
            this.showLeadKey++
          })
    },
    async openLead(lead) {
      await this.getSingleLead(lead)

    },
    getUnassigned(){
      ClientsService.getUnassignedClients().then(res=>{
        this.items=res
      })
    },
    getReports() {
      this.loading = true
      ClientsService.getReportsForManagingParter()
          .then(res => {
            this.items = res
            this.loading = false
          }).catch(() => this.loading = false)
    }
  },
  created() {
    this.getReports()
    this.getUnassigned()
  }
}
</script>

<style scoped>
#warningMsg{
  font-size: 1.2rem;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
