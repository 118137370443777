import { render, staticRenderFns } from "./NotifSigned.vue?vue&type=template&id=70fca176&scoped=true&"
import script from "./NotifSigned.vue?vue&type=script&lang=js&"
export * from "./NotifSigned.vue?vue&type=script&lang=js&"
import style0 from "./NotifSigned.vue?vue&type=style&index=0&id=70fca176&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70fca176",
  null
  
)

export default component.exports