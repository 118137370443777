<template>

<div>
  <div class="d-flex justify-center">
    <v-btn color="#263B4F" class="white--text" :disabled="!selected.length" @click="postToFinal">Sanitize</v-btn>
  </div>
  <div class="d-flex justify-center">
    <v-radio-group
      v-model="radioRow"
      row
    >
      <v-radio
        label="943"
        value="radio-1"
      ></v-radio>
      <v-radio
        label="944"
        value="radio-2"
      ></v-radio>
    </v-radio-group>
  </div>
    <v-data-table
    :headers="headers"
    :items="items"
    class="elevation-1 mt-5"
    hide-default-footer
    :loading="loadingTable"
    disable-pagination
    loading-text="Loading... Please wait"
    show-select
    v-model="selected"
  ></v-data-table>
  <h3 v-if="items.length"  class="mt-4 ml-2">Total client count: {{items.length}}</h3>
</div>

</template>
<script>
import ClientsService from "@/services/clients.service";
export default {
name: 'ReadyToSanitize',
data(){
    return{
        singleSelect: false,
        selected: [],
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Attorney of record', value: 'aor' },
          { text: 'Legal name', value: 'legal_name' },
          { text: 'Trade name', value: 'trade_name' },
          { text: 'ein', value: 'ein' },
          { text: 'Document score', value: 'document_score' },
          { text: 'Days in system', value: 'days_in_system' },
          { text: 'Triage score', value: 'triage_score' },
        ],
        items:[],
        loadingTable:false,
        radioRow:'' ,
    }
},
watch:{
      radioRow(newChoice, oldChoice){
        const payload= {
        business_type:'941'
      }
      if (newChoice=='radio-1'){
        payload.business_type='943'
      } else if (newChoice=='radio-2') {
        payload.business_type='944'
      }
        this.getReadyToSanitize(payload)
      }
    },
methods:{
    getReadyToSanitize(payload) {
        this.loadingTable=true
      ClientsService.getReadyToSanitize(payload)
          .then(res => {
            console.log('res',res)
            this.loadingTable=false
            this.items = res
          }).catch(() => {})
    },
  postToFinal() {
    this.loadingTable = true
    const model = this.selected.map(e=>e.id)
    ClientsService.moveToReadyToFinal(model).then(res=>{
      this.getReadyToSanitize()
      this.loadingTable=false
    }).catch(err=>{
      this.loadingTable=false
      this.getReadyToSanitize()
      this.selected=[]
    })
  }
},
created() {
    this.getReadyToSanitize()
}
};
</script>
<style>
</style>

