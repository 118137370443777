<template>
  <v-card-text class="mt-10">
    <v-dialog
        v-model="errorMessage"
        width="500"
    >
      <v-card class="pa-16" style="background-color: #263B4F">
        <h2 style="color: #FFFFFF" class="text-center mb-7">The chosen file is over the File Size limit of 25Mb. Please
          try another file.</h2>
        <v-progress-linear absolute color="red"
                           :v-model="errProgressBarValue"
                           :buffer-value="bufferValue">
        </v-progress-linear>
      </v-card>


    </v-dialog>
    <v-container>
      <div class="d-flex justify-start flex-column py-5" v-if="processingFile == true">
        <v-progress-linear
            indeterminate
            color="green"
        />
        <div> Processing file... Please wait this might take some time</div>
      </div>
      <v-overlay :value="loadingOnPost">
        <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-row class="d-flex justify-center">
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-card
              @drop.prevent="drop($event)"
              @dragover.prevent="dragoverData = true"
              @dragenter.prevent="dragoverData = true"
              @dragleave.prevent="dragoverData = false"
              height="20vh"
              class="d-flex  align-center justify-center"
              style="border: 5px dashed lightgray; box-shadow: none">
            <v-card-text>
              <v-row class="d-flex justify-center align-center">
                <div
                    class="p-12"
                >
                  <input type="file" multiple name="fields[assetsFieldHandleUploads][]"
                         id="assetsFieldHandleUploads"
                         class="d-none absolute" @input="onChange" ref="file"
                  />

                  <label for="assetsFieldHandleUploads" class="block cursor-pointer">
                    <span class="underline"> CHOOSE  A FILE </span> OR DRAG IT <span style="color: blue;"> HERE </span>
                  </label>
                  <div v-if="$refs.file" cols="12" class="displayFileName">
          <span v-for="(file,index) in $refs.file.files"
                style="font-size: 12px"
                :key="index + 999">{{ file.name }}
            <span v-if="index + 1 != $refs.file.files.length"> ,</span>
          </span>
                  </div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="justify-start align-start d-flex flex-column">
          <h3>Awaiting Review :</h3>
          <v-progress-linear
              indeterminate
              color="green"
              v-if="loading"
          />
        </v-col>
        <v-col>
          <v-list class="mt-4" v-if="this.awaiting_review.length" dense>
            <v-list-item
                v-for="(file, index) in awaiting_review"

                class="pa-3"
                :class="{'activeItemClassAwaitingReview': index % 2 !== 0}"
                style="background-color: #E0B0B0"
            >
              <UploadItem
                  @getUploads="getData"
                  @checkOutstanding="getOutstanding"
                  v-show="awaiting_review.length"
                  :key="`${index + loopKeyMultiplier}-file`"
                  :file="file"
                  :reqDocuments="outstanding"
                  :allReqDocuments="reqDocuments"
                  :current-user="currentUser"
                  @patchUpload="patchUpload"
                  @deleteUpload="deleteUpload"/>
            </v-list-item>
          </v-list>
          <h4 v-if="!this.awaiting_review.length && !loading">There are no documents awaiting review for this
            client.</h4>
        </v-col>
      </v-row>

      <v-row v-if="this.reviewed.length">
        <v-col cols="12" class="justify-start align-start d-flex flex-column">
          <h3>Reviewed :</h3>
<!--          <v-progress-linear-->
<!--              indeterminate-->
<!--              color="green"-->
<!--              v-if="loading"-->
<!--          />-->
        </v-col>
        <v-col>
          <v-list class="mt-4" dense>
            <v-list-item
                v-for="(file, index) in reviewed"

                class="pa-3"
                :class="{'activeItemClass': index % 2 !== 0}"
                style="background-color: #f0f0f0"
            >
              <UploadItem
                  @getUploads="getData"
                  @checkOutstanding="getOutstanding"
                  :key="`${index + 633 + loopKeyMultiplier}-file`"
                  v-show="reviewed.length"
                  :is-reviewed="true"
                  :file="file"
                  :reqDocuments="outstanding"
                  :allReqDocuments="reqDocuments"
                  :current-user="currentUser"
                  @patchUpload="patchUpload"
                  @deleteUpload="deleteUpload"/>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row v-if="this.rejected.length">
        <v-col cols="12" class="justify-start align-start d-flex flex-column">
          <h3>Rejected :</h3>
        </v-col>
        <v-col>
          <v-list class="mt-4" v-if="this.rejected" dense>
            <v-list-item
                v-for="(file, index) in rejected"

                class="pa-3"
                :class="{'activeItemClass': index % 2 !== 0}"
                style="background-color: #f0f0f0"
            >
              <UploadItem
                  @getUploads="getData"
                  @checkOutstanding="getOutstanding"
                  :key="`${index + 112 + loopKeyMultiplier}-file`"
                  v-show="rejected.length"
                  :allReqDocuments="reqDocuments"
                  :file="file"
                  :reqDocuments="outstanding"
                  :current-user="currentUser"
                  @patchUpload="patchUpload"
                  @deleteUpload="deleteUpload"/>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row v-if="this.outstanding.length">
        <v-col cols="12" class="justify-start align-start d-flex flex-column">
          <h3>Outstanding :</h3>
        </v-col>
        <v-col>
          <v-list class="mt-1" dense>
            <v-list-item
                v-for="(file, index) in outstanding"
                :key="`${index}-outstanding-file`"
            >
             <h3 v-if="file.document !== 19" style="color: #D21919;font-weight: bold">{{file.document_name}}</h3>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" v-if="currentUser.role_id == 1">
          <v-btn color="#263B4F" @click="sendEmail" class="white--text">Request Outstanding</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer></v-spacer>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
          :disabled="!isActiveButton"
          color="#263B4F"
          v-show="awaiting_review.length > 0"
          text
          @click="saveData"
      >
        save
      </v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
import ClientService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import $axios from "@/plugins/axios";
import UploadItem from "@/components/UploadItem";

export default {
  name: "Uploads",
  components: {
    AuthService,
    ClientService,
    UploadItem
  },
  data() {
    return {
      loopKeyMultiplier :1,
      reqDocuments: [],
      isActiveButton: true,
      dragoverData: false,
      forDate: new Date(),
      processingFile: false,
      currentUser: null,
      loading: false,
      uploadingFiles: false,
      loadingOnPost: false,
      errorMessage: false,
      errProgressBarValue: 0,
      bufferValue: 0,
      interval: 0,
      awaiting_review: [],
      rejected: [],
      reviewed: [],
      outstanding:[]
      // fileDropedNotSaved: false,
    }
  },
  props: {
    clientId: {
      type: Number,
      default: 0
    },
    clientProp: {
      type: Object
    }
  },
  methods: {
    sendEmail(){
       ClientService.sendEmailOutstandingUploads(this.clientId).then(()=>this.$func.popMessage('Email sent successfully!','success'))
    },
    patchUpload(data) {
      const model = {
        "document": data.document,
        "document_status": data.docStatus,
        "client_id" : this.clientId
      }
      this.loading = true
      ClientService.patchUpload(data.uploadId, model)
          .then(res => {
            // if (data.file.review_status == 0){
            //   this.loopKeyMultiplier += 25
            //   this.getData()
            // }
            this.loading = false
          }).catch(err => this.loading = false)
    },
    onChange() {
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        if (this.$refs.file.files[i].size < 25000000) {
          this.isActiveButton = true;
          let uploadedFile = this.$refs.file.files[i]
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.currentUser.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          }
          if (uploadedFile) {
            this.$emit('fileDropedNotSaved', true)
            this.awaiting_review.push(newFile)
          }
        } else {
          const removeMessageAfter = 2500
          this.errorMessage = true;
          this.startBuffer()
          setTimeout(() => {
            this.errorMessage = false
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter)
        }
      }
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.errProgressBarValue += 2;
        this.bufferValue += 2;
      }, 50);
    },
    async deleteUpload(file) {
      this.loading = true

      await ClientService.deleteUpload(file.id)
          .then((res) => {
            this.loading = false
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: 'File deleted successfully',
              duration: 4000,
              mode: 'success'
            });
            this.getData()
          }).catch(err => {
            console.log(err)
          })
    },

    saveData() {
      this.loading = true
      this.isActiveButton = false
      this.$emit('fileDropedNotSaved', false)
      this.awaiting_review.forEach((e, index) => {
        if (!e.id) {
          let formData = new FormData();
          formData.append('uploaded_file', e.uploadedFile);
          formData.append('upload_type', 'filedrop');
          // this.awaiting_review[index].uploadingFiles = true;
          this.postItem(formData, index)
        }
      })
      setTimeout(()=>{
        this.getData()
      },500)
    },
    postItem(formData, index) {
      ClientService.uploadClientCSVFileWithReturningData(this.clientId, formData)
          .then((res) => {
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: 'File uploaded successfully',
              duration: 4000,
              mode: 'success'
            });
            // this.awaiting_review[index] = res.data;
            // this.awaiting_review = [...this.awaiting_review]
            this.getData()
          }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
        this.awaiting_review[index].uploadingFiles = false;
        this.isActiveButton = true
      })

    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    ////
    getOutstanding(){
      ClientService.getOutstandingUploads(this.clientId).then(res=>{
        this.outstanding = res
      })
    },
    getData() {
      $axios.get(`clients/${this.clientId}/uploads`,
      ).then(res => {
        this.awaiting_review=[]
        this.reviewed=[]
        this.rejected=[]
        this.awaiting_review = [...res.data.awaiting_review]
        this.rejected = [...res.data.rejected]
        this.reviewed = [...res.data.reviewed]
        this.loading = false

      }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
        this.loading = false
      })
    },
  },
  async created() {
    await this.getOutstanding()
    const data = await AuthService.getCurrentUserFromCookie()
    this.currentUser = data.data
    await ClientService.getUploadReqDocs().then(res => {
      this.reqDocuments = res
      if (this.clientProp.uses_peo) {
        this.reqDocuments.push({
          "document": 19,
          "document_status": 0,
          "migrated": 0,
          "document_name": "Peo Document"
        })
      }
    })
    this.loading = true
    this.getData()

  },
}
</script>

<style scoped>
.activeItemClass {
  background-color: #FFFFFF !important;
  margin: 2px 0;
}

.activeItemClassAwaitingReview {
  background-color: #F4DCDC !important;
  margin: 2px 0;
}

[v-cloak] {
  display: none;
}

.displayFileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  max-width: 400px;
}


</style>
