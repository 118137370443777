<template>
  <div>

    <DialogRightAlign :showDialog.sync="showRightDialogAddUser" :titleProp="`Edit User`">
      <v-overlay :value="loading">
        <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="mt-10">
        <v-container>
          <v-row class="d-flex justify-center">
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <!--					 <v-autocomplete-->
              <!--						v-model="editUserForm.parent_entity"-->
              <!--						:items="entityOptions"-->
              <!--						item-text="name"-->
              <!--						item-value="id"-->
              <!--						label="Search"-->
              <!--					></v-autocomplete>-->

              <!--						<v-select-->
              <!--							v-model="editUserForm.parent_entity"-->
              <!--							:items="entityOptions"-->
              <!--							item-text="name"-->
              <!--							item-value="id"-->
              <!--              class="mb-3"-->
              <!--							label="Entity"-->
              <!--							required-->
              <!--							@blur="$v.editUserForm.parent_entity.$touch()"-->
              <!--							dense-->
              <!--						></v-select>-->

              <v-select
                  v-model="editUserForm.role"
                  :items="roles"
                  item-text="role_name"
                  item-value="role_id"
                  label="Role"
                  required
                  :error-messages="roleErrors"
                  @blur="$v.editUserForm.role.$touch()"
                  dense
              ></v-select>

              <v-row class="d-flex justify-center">
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                  <v-text-field
                      class="auth-form__field"
                      required
                      placeholder="First Name"
                      v-model="editUserForm.first"
                      :error-messages="firstNameErrors"
                      @blur="$v.editUserForm.first.$touch()"
                  />
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                  <v-text-field
                      class="auth-form__field"
                      required
                      placeholder="Last Name"
                      v-model="editUserForm.last"
                      :error-messages="firstNameErrors"
                      @blur="$v.editUserForm.last.$touch()"
                  />
                </v-col>
              </v-row>

              <v-text-field
                  v-model="editUserForm.email"
                  :error-messages="emailErrors"
                  label="Email"
                  required
                  @blur="$v.editUserForm.email.$touch()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-spacer></v-spacer>


      <v-card-actions>
        <v-btn color="red" text @click.native="deleteUser"> DELETE</v-btn>

        <v-spacer></v-spacer>

        <v-btn
            color="#263B4F"
            text
            @click.native="closeDialog"
        >
          cancel
        </v-btn>

        <v-btn
            color="#263B4F"
            text
            @click.native="editUser"
            :disabled="$v.editUserForm.$invalid"
        >
          edit user
        </v-btn>
      </v-card-actions>
      <v-overlay :value="getUsrLoading">
        <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
    </DialogRightAlign>
  </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import ClientsService from "@/services/clients.service";
import DialogRightAlign from "../Base/DialogRightAlign";
import {mapGetters} from "vuex";

export default {
  name: "UserAddDialog",
  components: {DialogRightAlign},
  validations: {

    editUserForm: {
      first: {required},
      last: {required},
      email: {required, email},
      role: {required},
    },
  },

  data: () => ({
    loading: false,
    buttonLoadingSpinner: false,
    getUsrLoading: true,
    editUserForm: {
      //parent_entity: '',
      username: '',
      email: '',
      first: '',
      last: '',
      role: '',
    },
    entityOptions: [],
    roles: [
      {
        id: 3,
        name: 'Tax attorney'
      },
      {
        id: 4,
        name: 'Managing attorney'
      },
      {
        id: 5,
        name: 'Office manager'
      },
      {
        id: 6,
        name: 'Client'
      },
      {
        id: 7,
        name: 'Attorney'
      }

    ]
  }),
  // mounted() {
  // 	console.log(this.user)
  // 	this.editUserForm.first = this.firstName
  // 	this.editUserForm.last = this.lastName
  // 	this.editUserForm.email = this.email
  // 	this.editUserForm.role = this.user.role_id
  // },

  methods: {

    closeDialog() {
      this.$emit('updateUsers', true);
      this.$emit('update:showRightDialogAddUser', false);
    },
    // getUsers(){
    // ClientsService.getUsers().then(res =>{
    // 	this.users = res.users
    // })
    // },
    editUser() {
      this.loading = true;
      // const { parent_entity, ...employeeRest } = this.editUserForm;
      const editFormModel = {
        parent_entity: this.editUserForm.parent_entity,
        username: this.editUserForm.email,
        email: this.editUserForm.email,
        first: this.editUserForm.first,
        last: this.editUserForm.last,
        role: this.editUserForm.role,
      }
      ClientsService.editUser(this.userID, editFormModel)
          .then(res => {
            this.$func.popMessage('User editted successfully', 'success')
            this.closeDialog()
          }).catch(() => this.loading = false)
    },
    deleteUser() {
      this.loading = true;
      ClientsService.deleteUser(this.userID)
          .then(res => {
            this.$func.popMessage('User deleted successfully', 'success')
            this.closeDialog()
            this.getUsers()
          }).catch(() => this.loading = false)

    }

  },


  computed: {

    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),
    emailErrors() {

      const errors = [];
      if (!this.$v.editUserForm.email.$dirty) return errors;
      !this.$v.editUserForm.email.email && errors.push('Must be valid e-mail');
      !this.$v.editUserForm.email.required && errors.push('E-mail is required');
      return errors;
    },

    roleErrors() {
      const errors = [];
      if (!this.$v.editUserForm.role.$dirty) return errors;
      !this.$v.editUserForm.role.required && errors.push('Role is required');
      return errors;
    },

    firstNameErrors() {
      const errors = []
      if (!this.$v.editUserForm.first.$dirty) return errors
      !this.$v.editUserForm.first.required && errors.push('Name is required.')
      return errors
    },

    lastNameErrors() {
      const errors = []
      if (!this.$v.editUserForm.last.$dirty) return errors
      !this.$v.editUserForm.last.required && errors.push('Name is required.')
      return errors
    },
  },
  async created() {
    await ClientsService.getEntities()
        .then(res => this.entityOptions = res)
    await ClientsService.getEntitiesFiltered('my')
        .then(res => this.roles = res.available_roles)
    // this.roles.map(e=>{
    //   if(e.id == currUser.role_id){
    //     userRole = e.id
    //   }
    // })
    this.editUserForm.first = this.user.first
    this.editUserForm.last = this.user.last
    this.editUserForm.email = this.user.email
    this.editUserForm.role = this.user.role_id
    this.getUsrLoading = false

  },

  props: {
    showRightDialogAddUser: {
      type: Boolean,
      default: false
    },
    userID: {
      type: Number
    },
    user: {
      type: Object
    },
  },
};
</script>

<style scoped>

</style>
