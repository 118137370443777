<template>
	<DialogRightAlign :showDialog.sync="showRightDialog" :titleProp="cardTitle">
		<v-card-text>
			<v-container fluid>
				<v-row class="d-flex justify-center align-center">

					<v-col cols="12" style="padding-top: 15px!important;">
						<v-text-field
							id="business-name"
							v-model="client.legal_name"
							outlined
							label="Legal Business Name"
							color="black"/>
					</v-col>
					<v-col cols="12">
						<v-text-field
							id="trade-name"
							v-model="client.trade_name"
							:error-messages="trade_nameErrors"
							required
							outlined
							@input="$v.client.trade_name.$touch()"
							@blur="$v.client.trade_name.$touch()"
							label="Trade Name"
							color="black"/>
					</v-col>
          <v-col cols="12">
            <v-switch v-model="client.uses_peo" :value="1" :input-value="0" class="mt-0 mb-3" label="Client Uses PEO"></v-switch>
          </v-col>
					<v-col cols="12" id="radioGroup">
						<v-container fluid class="pa-0">
						<v-radio-group
						v-model="business_type"
						row
						>
						<v-radio
							v-for="(radio, i) in radioButtons"
							:key="i"
							:label="`${radio.name}`"
							:value="i+1"
							@input="$v.client.business_type.$touch()"
							></v-radio>
						</v-radio-group>
					</v-container>
					</v-col>
					<v-col cols="12">
						<v-text-field
							id="ein"
              @keydown.space.prevent
							v-model="client.ein"
							:error-messages="einErrors"
							required
							outlined
							@input="$v.client.ein.$touch()"
							@blur="$v.client.ein.$touch()"
							label="EIN"
              maxlength="10"
							color="black"/>
					</v-col>

          <v-col cols="12">
            <v-text-field
                id="contact_phone"
                v-model="client.contact_phone"
                outlined
                label="Contact phone"
                maxlength="10"
                color="black"/>
          </v-col>

					<v-col cols="12">
						<v-text-field
							id="street"
							v-model="client.street"
							outlined
							label="Street"
							color="black"/>
					</v-col>

					<v-col cols="12">

						<v-row>
							<v-col cols="4">
								<v-text-field
									id="city"
									v-model="client.city"
									outlined
									label="City"
									color="black"/>
							</v-col>
							<v-col cols="4">
								<v-select
									id="state"
									:append-icon="'mdi-arrow-down'"
									outlined
									:items="states"
									v-model="client.state"
									:item-text="'name'"
									:item-value="'abbreviation'"
									label="States"
								/>
							</v-col>
							<v-col cols="4">
								<v-text-field
									id="zip"
									v-model="client.zip"
									type="number"
									outlined
									label="ZIP"
									color="black"
								/>
							</v-col>
						</v-row>
					</v-col>

					<v-col cols="12">
					<v-row>
					
						
						<v-col cols="6">
						<v-select
							id="contact-saluattion"
							:append-icon="'mdi-arrow-down'"
							outlined
							:items="salutationList"
							:error-messages="salutation_err"
							required
							v-model="client.contact_salutation"
							@input="$v.client.salutation.$touch()"
							@blur="$v.client.salutation.$touch()"
							:item-text="'name'"
							:item-value="'name'"
							label="Mrs"
						></v-select>
						</v-col>

						<v-col cols="6">
						<v-text-field
							id="contact-first-name-full"
							v-model="client.contact_first"
							:error-messages="contact_firstErrors"
							required
							outlined
							@input="$v.client.contact_first.$touch()"
							@blur="$v.client.contact_first.$touch()"
							label="First Name for the point of contact"
							color="black"/>
						</v-col>
					
					</v-row>

					</v-col>

					<v-col cols="12">
						<v-text-field
							id="contact-last-name"
							v-model="client.contact_last"
							:error-messages="contact_lastErrors"
							required
							outlined
							@input="$v.client.contact_last.$touch()"
							@blur="$v.client.contact_last.$touch()"
							label="Last Name for the point of contact"
							color="black"/>
					</v-col>

					<v-col cols="12">
						<v-text-field
							id="contact-email"
							v-model="client.contact_email"
							:error-messages="contact_emailErrors"
							required
							outlined
							@input="$v.client.contact_email.$touch()"
							@blur="$v.client.contact_email.$touch()"
							label="Email for the point of contact"
							color="black"/>
					</v-col>

					<v-col cols="12">
						<v-select
							id="tax-firm"
							:append-icon="'mdi-arrow-down'"
							v-model="client.tax_firm"
							:error-messages="tax_firmErrors"
							required
							type="number"
							outlined
							@input="$v.client.tax_firm.$touch()"
							@blur="$v.client.tax_firm.$touch()"
							label="Tax Firm"
							:items="taxes"
							:item-text="'name'"
							:item-value="'id'"
						/>
					</v-col>

					<v-col cols="12">
						<v-select
							id="managing-firm"
							:append-icon="'mdi-arrow-down'"
							v-model="client.managing_firm"
							@input="$v.client.managing_firm.$touch()"
							@blur="$v.client.managing_firm.$touch()"
							:error-messages="managing_firmErrors"
							required
							outlined
              				:items="laws"
							:item-text="'name'"
							:item-value="'id'"
							label="Managing Firm"
						/>
          </v-col>
          <v-col cols="12">
            <v-select
                v-model="client.attorney_of_record"
                item-text="name"
                return-object
                :append-icon="'mdi-arrow-down'"
                :items="attorneys"
                label="Attorney of Record"
                outlined
            ></v-select>
          </v-col>
            <v-col cols="12">
              <v-text-field
                  @keydown.space.prevent
                  id="contingency-rate"
                  v-model="client.contingency_rate"
                  required
                  outlined
                  label="Contingency rate (in points)"
                  color="black"
              />
					</v-col>
				</v-row>
				<v-overlay :value="overlay">
					<v-progress-circular
						indeterminate
						size="64"
					></v-progress-circular>
				</v-overlay>
			</v-container>
		</v-card-text>

		<v-spacer></v-spacer>

		<v-card-actions>
			<v-btn
				v-if="clientProp"
				text
				@click.native="deleteClient"
			>
				Delete Client
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn
				color="#263B4F"
				text
				@click.native="closeDialog"
			>
				close
			</v-btn>

			<v-btn
				color="#263B4F"
				text
				@click.native="createNewClient"
				:disabled="$v.client.$invalid"
			>
				{{ btnName }}
			</v-btn>

		</v-card-actions>
	</DialogRightAlign>
</template>

<script>
	import {email, required, helpers} from 'vuelidate/lib/validators';

	import DialogRightAlign from "@/components/Base/DialogRightAlign";
	import ClientService from "../../services/clients.service";
	import {statesConstData} from '@/constants'
	import {mapGetters} from "vuex"
	import TaxService from "../../services/tax.service";
	import LawService from "../../services/law.service"
	import AuthService from "../../services/auth.service"

	const einValidator = helpers.regex('einValidator', /[0-9]{2}-[0-9]{7}/)

	export default {
		name: "CreateClientDialog",
		components: {DialogRightAlign},

		props: {
			showRightDialog: {
				type: Boolean,
				default: false
			},

			clientProp: {
				type: Object,
				default: null
			}
		},

		data: () => ({
			btnName: '',
			cardTitle: '',
      business_type:1,
			overlay: false,
     	row: null,
			radioButtons:[],
			salutationList: [
				{
					name: 'Mr.'
				},
				{
					name: 'Mrs.'
				},
				{
					name: 'Ms.'
				},
				{
					name: 'Dr.'
				},
			],
			client: {
				legal_name: "",
				trade_name: "",
				ein: "",
        contact_phone: "",
				street: "",
				city: "",
				state: "",
				zip: "",
				contact_first: "",
				contact_last: "",
				contact_email: "",
				attorney_of_record: '',
				owner: '',
				tax_firm: '',
				managing_firm: '',
				managing_attorney: '',
        contingency_rate: '',
        contact_salutation: "",
        business_type: '0',
        uses_peo: 0
			},
			states: statesConstData,
			taxes: [],
			laws: [],
			clientInitData: {},
			currentUserEntityId: null,
			attorneys: [],
		}),

		validations: {
			client: {
				trade_name: {
					// required,
				},
				business_type:{
					// required
				},
				ein: {
					required,
					einValidator
				},
				contact_first: {
					required,
				},
				contact_last: {
					required,
				},
				contact_email: {
					required,
					email
				},
				tax_firm: {
					required,
				},
				managing_firm: {
					required,
				},
        contact_salutation: {
          required,
        }
			}
		},

		computed: {
			...mapGetters(
				{
					currentUser: 'auth/getCurrentUser',
				},
			),

			selectTaxOrLawFirmIfIdTheSame() {
				this.laws.forEach(law => {
					if (this.currentUserEntityId == law.id) {
						this.client.managing_firm = this.currentUserEntityId;
					}
				});

				this.taxes.forEach(tax => {
					if (this.currentUserEntityId == tax.id) {
						this.client.tax_firm = this.currentUserEntityId;
					}
				});
			},

      contingency_rateErrors() {
        return this.handleErrors(this.$v.client.contingency_rate, 'Please enter a contingency rate. 20 is normal.');
      },
			legal_nameErrors() {
				return this.handleErrors(this.$v.client.legal_name, 'Legal Name Field is required.');
			},
      salutation_err() {
        return this.handleErrors(this.$v.client.contact_salutation, 'Salutation Field is required.');
      },
			trade_nameErrors() {
				return this.handleErrors(this.$v.client.trade_name, 'Trade Name Field is required.');
			},

			streetErrors() {
				return this.handleErrors(this.$v.client.street, 'Street Field is required.');
			},

			cityErrors() {
				return this.handleErrors(this.$v.client.city, 'City Field is required.');
			},

			stateErrors() {
				return this.handleErrors(this.$v.client.state, 'State Field is required.');
			},

			zipErrors() {
				return this.handleErrors(this.$v.client.zip, 'ZIP Field is required.');
			},

			contact_firstErrors() {
				return this.handleErrors(this.$v.client.contact_first, 'Contact First Field is required.');
			},

			contact_lastErrors() {
				return this.handleErrors(this.$v.client.contact_last, 'Contact Last Field is required.');
			},

			einErrors() {
				const errors = []
				if (!this.$v.client.ein.$dirty) return errors
				!this.$v.client.ein.einValidator && errors.push('Your EIN format is wrong. Ex: 00-0000000 (Use Digits only)')
				!this.$v.client.ein.required && errors.push('EIN is required.')
				return errors
			},

			contact_emailErrors() {
				const errors = []
				if (!this.$v.client.contact_email.$dirty) return errors
				!this.$v.client.contact_email.email && errors.push('Please add a valid email.')
				!this.$v.client.contact_email.required && errors.push('Email Field is required.')
				return errors
			},

			tax_firmErrors() {
				return this.handleErrors(this.$v.client.tax_firm, 'Tax Firm Field is required.');
			},

			managing_firmErrors() {
				return this.handleErrors(this.$v.client.managing_firm, 'Managing Firm Field is required.');
			}
		},

		methods: {
			deleteClient() {
				this.$emit('deleteDialog');
			},

			handleErrors(valObject, errorMsg) {
				const errors = [];

				if (!valObject.$error) return errors;
				!valObject.required && errors.push(errorMsg);
				return errors;
			},

			createNewClient() {
        this.client.business_type = this.business_type
				if (this.clientProp) {
					const combinedObject = {...this.clientProp, ...this.client}
					const diff = Object.entries(combinedObject).reduce((acc, [key, value]) => {
            if (
                this.clientProp[key] !== value ||
                this.client[key] !== value
                // this.client[key].id !== value.id

							// !Object.values(this.clientProp).includes(value) ||
							// !Object.values(this.client).includes(value)
						) {
              acc[key] = value
              if (key == "attorney_of_record") acc[key] = value.id
            }
						return acc
					}, {})
          // diff.contact_salutation= this.client.salutation
		  		// 	this.client.business_type=this.business_type
		  			
					return ClientService.editClient(this.client.id, diff)
						.then((data) => {
							// console.log('update client',this.client)
							this.closeDialog();
							this.$emit('getCurrentClient', this.client.id);
							this.$emit('forceUpdate');
							this.$store.dispatch('snackbar/showSnackbarMessage',
								{message: 'Client was updated!', duration: 6000})
						})
						.catch((error) => {
              this.$func.popMessage(`${error.response.data.error}`, 'fail')
						});
				} else {

					this.client.attorney_of_record = this.currentUser.current_user_id;
					this.client.owner = this.currentUser.current_user_id;
					// this.client.business_type=this.business_type
					// console.log('create client',this.client)
					return ClientService.createClient({...this.client})
						.then(async (data) => {
							await this.$emit('getCurrentClient', data.id);
							await this.$emit('forceUpdate');
							await this.$emit('openClientProfileDialog');
							this.$emit('forceUpdate');
							this.closeDialog();

							this.$store.dispatch('snackbar/showSnackbarMessage',
								{message: 'Client was created!', duration: 6000})

						})
						.catch((error) => {

              this.$func.popMessage(`${error.response.data.error}`, 'fail')
						});
				}
			},

			getAllTaxes() {
				return TaxService.getTaxes()
					.then((data) => {
						this.taxes = data;
					})
			},

			getAllLaws() {
				return LawService.getLaws()
					.then((data) => {
						this.laws = data;
					})
			},

			closeDialog() {
				this.$emit('update:showRightDialog', false);
			},

			getCurrentUserEntityId() {
				return AuthService.getCurrentUser(this.currentUser.current_user_id)
					.then((res) => {
						this.currentUserEntityId = res.data.parent_entity;
					})
			},
			//Loads managing attorneys per entity
			loadAttorneys(){
				ClientService.getEntitiesFiltered('my')
          			.then(res => {
                  res.users.map(e=>{
                    if(e.role_id === 7) this.attorneys.push(e)
                  })
                  this.attorneys.push(this.client.attorney_of_record)
                })
			},
			loadBusinessTypes(){
				ClientService.getBusinessTypes().then(res=>{
					this.radioButtons=res
				})
				
			}
			
		},


		watch: {
      laws(value) {
        if (value.length == 1) {
          this.client.managing_firm = value[0].id
        }
      },

      taxes(value) {
        if (value.length == 1) {
          this.client.tax_firm = value[0].id
        }
      },
    },

		async created() {
			await this.loadBusinessTypes()
			// await ClientService.getOneClient(this.currentUserEntityId) 
          	// .then(res => console.log('client:', res))		
			if (this.clientProp) {
        console.log('Client props', this.clientProp.managing_firm.name)
        this.client = Object.assign({},this.clientProp)
        // if (this.clientProp.business_type) this.client.business_type = this.clientProp.business_type
        // this.client = structuredClone(this.clientProp)
        // this.client = {...this.clientProp}
        this.business_type = this.client.business_type
        // this.client.business_type:this.clientProp.business_type
        this.client.attorney_of_record.name = this.client.attorney_of_record.first + " " + this.client.attorney_of_record.last
        if (this.clientProp.contact_salutation?.length){
          this.client.contact_salutation = this.clientProp.contact_salutation;
        }
				this.cardTitle = 'Save Client Profile';
				this.btnName = 'Save Client Profile';
			} else {
        this.client.contingency_rate = 20;
				this.cardTitle = 'Create Client Profile';
				this.btnName = 'Create Client Profile';
			}
			await this.getCurrentUserEntityId();
			await this.getAllTaxes();
			await this.getAllLaws();
			await this.loadAttorneys();
      // this.business_type = this.client.business_type
		}
	};
</script>

<style scoped>

	.delete_btn {
		background-color: red;
	}

	.col-12 {
		padding: 0px !important;
	}

	.v-text-field {
		border-top: 0px !important;
	}

	#radioGroup{
		margin-top: -25px;
	}
</style>
