<template>
  <div id="page">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
          integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"/>
    <div id="header" v-for="item in entries" @click="openEditor">
      <Header :editing="editing" v-bind:item="item" v-if="item.layout == 'header'" :item="item"/>
    </div>
    <div id="content" v-for="item in entries" :key="item.id" @click="openEditor">
      <!--      {{item}}-->
      <Featured :editing="editing" v-bind:item="item" v-if="item.layout == 'featured'" :item="item"/>
      <Features :editing="editing" v-bind:item="item" v-if="item.layout == 'features'" :item="item"/>
      <Post :editing="editing" v-bind:item="item" v-if="item.layout == 'post'"/>
      <News v-bind:edit="editing" v-bind:item="item" v-if="item.layout == 'news'"/>
      <NewsLeft :editing="editing" v-bind:item="item" v-if="item.layout == 'newsleft'"/>
    </div>
    <Editor @refreshItem="refreshItem" :editing="editing" v-bind:layouts="layouts" v-bind:entries="entries"/>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Features from '../components/PageBuilderComponents/Features.vue'
import Featured from "@/components/PageBuilderComponents/Featured.vue";
import Editor from "@/components/PageBuilderComponents/editor/Editor.vue";
import Post from "@/components/PageBuilderComponents/Post.vue";
import News from "@/components/PageBuilderComponents/News.vue";
import NewsLeft from "@/components/PageBuilderComponents/NewsLeft.vue";
import Header from "@/components/PageBuilderComponents/Header.vue";
import {mapGetters} from "vuex";
import ResourceService from "@/services/resource.service";

export default {
  name: "PageBuilder",
  components: {Header, NewsLeft, News, Post, Editor, Featured, Features},
  data: () => ({
    loading: false,
    editing: true,
    editorOpened: false,
    editorKey: 4,
    layouts: ['featured', 'post', 'news', 'newsleft', 'features'],
    entries: [{
      id: "item-1",
      layout: "header",
      title: "ERC Pagebuilder",
      subtitle: "Lorem ipsum dolor site amet."
    },
      {
        id: "item-2",
        layout: "post",
        title: "Mauris eleifend ligula",
        body: " Vivamus in nisi commodo, auctor magna vel, viverra turpis. Quisque dapibus risus nec justo euismod, id fringilla dui lobortis. Mauris vitae semper arcu. Ut ac lorem felis."
      },
      {
        id: "item-3",
        layout: "news",
        title: "Add image or viemo video link",
        body: "Example link : https://player.vimeo.com/video/786646692?h=f2ca1d6121",
        vimeo_link: 'https://player.vimeo.com/video/786646692?h=f2ca1d6121'
      },
      {
        id: "item-4",
        layout: "features",
        title: "Lorem Ipsum",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend ligula ut augue scelerisque venenatis."
      }
    ],
  }),
  computed: {
    ...mapGetters(
        {
          pageData: 'pagebuilder/getPageData'
        },
    ),
  },
  methods: {
    openEditor() {
      if (this.editing) {
        this.editorOpened = true
        this.editorKey++
      }
    },
    refreshItem(item) {


      // Object.assign({},this.item,item)
      // console.log(this.entries)
      // console.log(item)
      // console.log(item)
      this.entries.forEach((e, index) => {
        console.log(e.id, item.id)
        if (e.id == item.id) {
          this.entries.splice(index, 1, item)

          // this.entries[index] = item
          console.log(this.entries)
        }
      })
      setTimeout(() => {
        this.entries = [...this.entries]
      }, 500)
    }
  },
  created() {
    const id = this.$route.params.id
    if (parseInt(id) !== 0) {
      this.loading = true
      ResourceService.getResourcePage(id)
          .then(res => {
            this.entries = res
            this.editing = false
            this.loading = false;
          })
    }

    // const recaptchaScript = document.createElement("script");
    // recaptchaScript.setAttribute(
    //     "src",
    //     "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
    // );
    // document.head.appendChild(recaptchaScript);
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css')
    // recaptchaScript.setAttribute('integrity', 'sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z' )
    // recaptchaScript.setAttribute('type', 'application/json' )
    // recaptchaScript.setAttribute('crossorigin', 'anonymous' )
    // document.head.appendChild(recaptchaScript)
    // let fontAws = document.createElement('script')
    // fontAws.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css')
    // fontAws.setAttribute('type', 'application/json')
    // document.head.appendChild(fontAws)

  }
}
</script>

<style scoped>
@import "~@/style.css";
</style>
