<template>
  <DialogRightAlign :showDialog.sync="showRightDialog" :titleProp="`Upload CSV`">
    <v-card-text class="mt-10">
      <v-container>
        <div class="d-flex justify-start flex-column py-5" v-if="processingFile == true">
          <v-progress-linear
              indeterminate
              color="green"
          />
          <div> Processing file... Please wait this might take some time</div>
        </div>

        <v-row class="d-flex justify-center">
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-card
                @drop.prevent="drop($event)"
                @dragover.prevent="dragoverData = true"
                @dragenter.prevent="dragoverData = true"
                @dragleave.prevent="dragoverData = false"
                :class="{ 'grey lighten-2': dragoverData }"
                height="50vh"
                class="d-flex  align-center justify-center"
                style="border: 5px dashed lightgray; box-shadow: none"
            >
              <v-card-text>
                <v-row class="d-flex justify-center align-center">
                  <div
                      class="p-12"
                      @drop="drop"
                  >
                    <input type="file" multiple name="fields[assetsFieldHandle][]"
                           id="assetsFieldHandle"
                           class="d-none absolute" @change="onChange" ref="file" accept=".csv"/>

                    <label v-if="fileList.length < 1 " for="assetsFieldHandle"
                           class="block cursor-pointer d-flex justify-center align-center">
                      <span class="underline"> CHOOSE  A FILE OR DRAG IT</span>  <span
                        style="color: blue;"> &nbsp HERE </span>
                    </label>
                    <label v-if="fileList.length >= 1" for="assetsFieldHandle"
                           class="block cursor-pointer d-flex justify-center align-center">
                      <span class="underline"> To upload a different file, click<span
                          style="color: blue;"> &nbsp HERE </span> , </span> OR DRAG IT <span
                        style="color: blue;"> &nbsp HERE </span>
                    </label>
                    <v-list class="mt-4" v-if="this.fileList.length" dense>
                      <v-list-item
                          v-for="(file, index) in fileList"
                          :key="`${index}-file`"
                          style="border: dashed lightgray;"
                      >
                        {{ file.name }}
                        <v-spacer></v-spacer>
                        <button class="ml-2" type="button"
                                @click="remove(fileList.indexOf(file))"
                                title="Remove file"
                        >

                          <v-icon>
                            mdi-trash-can-outline
                          </v-icon>
                        </button>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
          color="#263B4F"
          text
          @click.native="closeDialog"
      >
        close
      </v-btn>

      <v-btn
          color="#263B4F"

          text
          @click.native="save"
      >
        save
      </v-btn>
    </v-card-actions>
  </DialogRightAlign>

</template>

<script>
import DialogRightAlign from "../Base/DialogRightAlign";
import ClientService from "../../services/clients.service";

export default {
  name: "ClientDragAndDropCSVFile",
  components: {DialogRightAlign},
  props: {
    showRightDialog: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    },

    clientIdProp: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      dragoverData: false,
      fileList: [], // Store our uploaded files
      dataFromCsvFile: '',
      processingFile: false,
    };
  },

  methods: {
    upload() {
      const formData = new FormData();
      const csvFile = [this.$refs.file.files[0]];
      formData.append("uploaded_file", csvFile[0]);
      formData.append('upload_type', 'payroll');
      ClientService.uploadClientCSVFile(this.clientIdProp, formData)
          .then(() => {
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: 'CSV uploaded successfully',
              duration: 4000,
              mode: 'success'
            });
            this.closeDialog();
            this.$emit('getDataForOwnersDialog');
            this.$emit('getUpdatedInfo');
          }).catch(()=>this.processingFile = false)
    },

    onChange() {
      this.fileList = [this.$refs.file.files[0]];
    },

    remove(i) {
      this.fileList.splice(i, 1);
      this.$refs.file.files = []
      this.dataFromCsvFile = [];
    },

    csvToArray(str, delimiter = ",") {
      const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

      const rows = str.slice(str.indexOf("\n") + 1).split("\n");

      const arr = rows.map((row) => {
        const values = row.split(delimiter);
        const el = headers.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return el;
      });

      return arr;
    },

    async drop(event) {
      event.preventDefault();
      this.dragoverData = false;

      const input = event.dataTransfer.files[0];
      const reader = new FileReader();

      reader.readAsText(input);

      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    closeDialog() {
      this.$emit('update:showRightDialog', false);
    },


    save(value) {
      this.upload();
      this.buttonLoadingSpinner = true;
      this.processingFile = true;

      setTimeout(() => {
        this.processingFile = false;
      }, 5000)
    }
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

</style>