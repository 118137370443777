<template>
  <div class="text-center">
    <v-btn :loading="loading" @click="downloadCalc" :disabled="loading" class="mt-14" color="#263B4F">
      <span style="color: #FFFFFF">Download Calculation</span>
    </v-btn>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "Downloads",
  data: () => ({
    loading: false
  }),
  methods: {
    downloadURI(dataurl, filename) {
      const link = document.createElement("a");
      link.href = dataurl;
      link.download = filename;
      link.click();
    },
    async downloadCalc() {
      this.loading = true
      await ClientsService.downloadCalculations(this.clientId).then((res) => {
        this.loading = false
        this.downloadURI(res.url,res.original_file_name)
        // window.open(res.url);
        // window.focus()

      }).catch(() => this.loading = false)
    }
  },
  props: {
    clientId: {
      type: Number,
      default: 0
    },
    clientProp: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
