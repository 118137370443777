<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <h1 class="my-4">Signature Verification</h1>
    <v-data-table
        :headers="headers"
        :items="items"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="client_id"
        disable-pagination
        hide-default-footer
        show-expand
        class="elevation-1"
    >
      <template v-slot:item.trade_name="{ item }">
        <div style="cursor: pointer" @click="goToClient(item.client_id)">
          {{item.trade_name}}
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
       <v-row class=" pa-4">
         <v-col>Enter the pages that contain the
           corresponding signatures below, and click
           "Verified" once you have verified all
           signatures. If a signature is not required,
           click "N/A"</v-col>
         <v-col><a :download="item.submission_original_file_name"
                   :href="item.submission_download"
                   target="_blank">Download Submission</a><v-icon>mdi-download</v-icon></v-col>
         <v-col class="d-flex flex-column" id="quarters">
           <div class="d-flex flex-row align-center" v-for="q in item.quarters">
             <span>Q{{q.quarter}}</span>
             <v-text-field class="mx-2" v-model="q.pdf_page" style="max-width: 70px"></v-text-field>
             <v-checkbox  v-model="q.checked"></v-checkbox>
             <span>N/A</span>
           </div>
           
         </v-col>
         <v-row id="confirm">
          <v-col><v-btn @click="confirmSignature(item)" color="primary">Confirm Signature</v-btn></v-col>
        </v-row>
         </v-row>
   
  
       <v-row></v-row>
        </td>
      </template>
    </v-data-table>
    <ProfileClientDialog
        v-if="showRightDialogChosenClient"
        :key="dialogKeyChosenClient"
        :showRightDialogChosenClient.sync="showRightDialogChosenClient"
        :clientProp="clientProp"
        @openClientProfileDialog="openClientProfileDialog"
  
    />
  </div>
  </template>
  
  <script>
  import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
  import ClientsService from "@/services/clients.service";
  import ClientService from "@/services/clients.service";
  export default {
    name: "SignatureVerification",
    components: {ProfileClientDialog},
    data:()=>({
      expanded: [],
      singleExpand: true,
      items:[],
      loading:false,
      showRightDialogChosenClient:false,
      dialogKeyChosenClient:33,
      clientId:'',
      clientProp:{},
      selectedClient:{},
      statuses:[],
      rejectReason:'',
      rejDialog:false,
      claimed:0,
      blocked:0,
      headers:[
        {
          text: 'Trade Name',
          value: 'trade_name'
        },
        {
          text: 'Legal Name',
          value: 'legal_name'
        },
        {
          text: 'Days in queue',
          value: 'days_in_queue'
        }
        ,
        {
          text: 'AOR',
          value: 'attorney_of_record'
        },
        {
          text: 'EIN',
          value: 'ein'
        }
      ]
  }),
    methods:{
      goToClient(id){
        this.loading = true
        return ClientService.getOneClient(id)
            .then((data) => {
              this.clientProp = data;
              this.openClientProfileDialog();
              this.clientId = Number(this.clientProp.id);
              // this.showResults = false;
              // this.inputSearch = null;
            })
            .catch((error) => {
              this.loading = false
              this.$store.dispatch('snackbar/showSnackbarMessage', {
                message: `${error.response.data.error}. Please, try again.`,
                duration: 4000,
                mode: 'fail'
              });
            });
      },
      openClientProfileDialog() {
        this.showRightDialogChosenClient = true;
        this.dialogKeyChosenClient++;
        this.loading = false
        this.showRightDialog = false;
      },
      openRejectDialog(item){
        this.rejDialog = true
        this.selectedClient=item
      },
      async confirmSignature(item){
        this.loading=true
        for (let i = 0; i < item.quarters.length; i++) {
          let status = 0
          if (item.quarters[i].checked && !item.quarters[i].pdf_page.length) {
            status = 2
          }else if(item.quarters[i].pdf_page.length){
            status = 1
          }else if(!item.quarters[i].pdf_page.length && !item.quarters[i].checked){
            status = 2
          }
          const model = {
            status:status,
            pdf_page:item.quarters[i].pdf_page
          }
          await this.postQuarters(item.quarters[i].verification_id,model,i)
        }
      },
      postQuarters(id,data,index){
        ClientsService.patchSignatureQuarters(id,data)
            .then(res=>{
              setTimeout(()=>{
                this.$func.popMessage('Client Verified successfully!','success')
                this.getData()
              },700)
  
            })
      },
      getData(){
        this.loading = true
        ClientsService.getSignatureVerification().then(res=>{
          this.items=res
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].quarters.map(x=>{
              x.checked=false
              x.pdf_page=''
              if (x.status == 2) x.checked = true
            })
          }
          this.items = [...this.items]
          this.loading=false
        })
      }
    },
    created() {
      this.getData()
    }
  }
  </script>
  
  <style scoped>
  #quarters div:nth-child(4){
    order: -1
  }
  
  #confirm {
    width: 100%;
    margin: 0 0.2rem;
  }
  @media only screen and (min-width: 400px) {
    #confirm .col{
    position: relative;
    min-height: 60px;
    }
    #confirm .col button{
      position: absolute;
      right:330px;
    }
  }
  
  </style>
  
