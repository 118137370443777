<template>
<div>
  <v-overlay :value="loading">
    <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
    ></v-progress-circular>
  </v-overlay>
<!--  <v-data-table-->
<!--      disable-pagination-->
<!--      mobile-breakpoint="0"-->
<!--      @click:row="openClientDialog"-->
<!--      hide-default-footer-->
<!--      :items="items"-->
<!--      :headers="headers"-->

<!--  ></v-data-table>-->
  <v-container fluid>
    <v-radio-group
      v-model="radioRow"
      row
    >
      <v-radio
        label="943"
        value="radio-1"
      ></v-radio>
      <v-radio
        label="944"
        value="radio-2"
      ></v-radio>
    </v-radio-group>
  </v-container>
  <v-data-table
      :single-expand="false"
      :expanded.sync="expanded"
      :headers="headers"
      :items="items"
      class="elevation-5 mt-6"
      disable-pagination
      hide-default-footer
      show-expand
      item-key="id"
  >
    <template v-slot:item.document_score="{ item }">
      <v-progress-linear
          :value="item.document_score*(100/item.document_max)"
          height="20"
      >
<!--        <strong>{{ Math.ceil(item.document_score) }}%</strong>-->
        <strong>{{ item.document_score }} /{{item.document_max}}</strong>
      </v-progress-linear>
    </template>
    <template v-slot:item.trade_name="{ item }">
      <div style="cursor: pointer" @click="goToClient(item.id)">
        {{item.trade_name}}
      </div>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-data-table
            disable-pagination
            hide-default-footer
            style="background-color: #ededed"
            :headers="headers2"
            :items="item.documents">
          <template v-slot:item.document_status="{ item }">
             <span v-if="item.document_status == 0" style="font-weight: bold;color: #AF0808">OUTSTANDING</span>
             <span v-if="item.document_status == 1" style="color: #4CAF50;">OK</span>
          </template>

        </v-data-table>
      </td>
    </template>
  </v-data-table>
  <ProfileClientDialog
      v-if="showRightDialogChosenClient"
      :key="dialogKeyChosenClient"
      :showRightDialogChosenClient.sync="showRightDialogChosenClient"
      :clientProp="clientProp"
      @openClientProfileDialog="openClientProfileDialog"

  />
</div>
</template>

<script>
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
import ClientsService from "@/services/clients.service";
export default {
  name: "Documents",
  components: {ProfileClientDialog},
  data() {
    return {
      showRightDialogChosenClient: false,
      dialogKeyChosenClient: 0,
      clientProp:{},
       items:[],
      loading:true,
      expanded: [],
      showRightDialogChosenClient:false,
      dialogKeyChosenClient:13,
      clientProp:{},
      headers:[
        { text: 'Days in system', value: 'days_in_system' },
        { text: 'Client', value: 'trade_name' },
        { text: 'EIN', value: 'ein' },
        { text: 'Document Score', value: 'document_score' },
      ],
      headers2:[
        { text: 'Client', value: 'client' },
        { text: 'Status', value: 'document_status' },
        { text: 'Document ', value: 'document_name' },
      ],
      radioRow:'' ,
    }
    },
    watch:{
      radioRow(newChoice, oldChoice){
        const payload= {
        business_type:'941'
      }
      if (newChoice=='radio-1'){
        payload.business_type='943'
      } else if (newChoice=='radio-2') {
        payload.business_type='944'
      }
        this.getRequiredDocuments(payload)
      }
    },
  methods:{
    async getRequiredDocuments(payload){
      await ClientsService.getClientsRequiredDocuments(payload)
         .then(res=>{
           this.items = res
           this.loading=false
         })
    },
    goToClient(id) {
      this.loading = true
      return ClientsService.getOneClient(id)
          .then((data) => {
            this.clientProp = data;
            this.select = null;
            this.openClientProfileDialog();
            this.clientId = Number(this.clientProp.id);
            // this.showResults = false;
            // this.inputSearch = null;
          })
          .catch((error) => {
            this.loading = false
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: `${error.response.data.error}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false
      this.showRightDialog = false;
    },
  },
  async created() {
     await ClientsService.getClientsRequiredDocuments()
         .then(res=>{
           this.items = res
           this.loading=false
         })
  }
}
</script>

<style scoped>

</style>
