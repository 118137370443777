<template>
  <v-card-text class="mt-2">
    <v-container>
      <div class="d-flex justify-start flex-column py-5" v-if="loadingPostRequest">
        <v-progress-linear
            indeterminate
            color="green"
        />
        <div> Processing file... Please wait this might take some time</div>
      </div>
      <v-select
          v-model="selectedPackages"
          :items="finalPackages"
          item-text="label"
          item-value="id"
          no-data-text="No final packages generated."
          label="Choose File"
          outlined
      ></v-select>
      <v-row class="d-flex justify-center">
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
          <v-card
              @drop.prevent="drop($event)"
              @dragover.prevent="dragoverData = true"
              @dragenter.prevent="dragoverData = true"
              @dragleave.prevent="dragoverData = false"
              height="20vh"
              class="d-flex  align-center justify-center"
              style="border: 5px dashed lightgray; box-shadow: none">
            <v-card-text>
              <v-row class="d-flex justify-center align-center">
                <div
                    class="p-12"
                >
                  <input type="file" name="fields[assetsFieldHandle][]"
                         id="assetsFieldHandleSubmission"
                         class="d-none absolute" @input="onChange" ref="file" accept=".pdf"
                  />

                  <label for="assetsFieldHandleSubmission" class="block cursor-pointer">
                    <span class="underline"> CHOOSE  A FILE </span> OR DRAG IT <span style="color: blue;"> HERE </span>
                  </label>
                  <div style="font-weight: 600;overflow: hidden" v-if="csvFile">{{csvFile.name}}</div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
          <v-btn color="#263b4f" @click="postSubmission" v-show="!loadingPostRequest"
                 class="mt-5" width="200px"><span style="color: #FFFFFF">SUBMIT</span></v-btn>

        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list class="mt-4" dense  v-if="this.fileList" >
<!--              Rendered files go here !! -->
            <v-card elevation="6" v-for="(file, index) in fileList" class="pa-8 mb-4"
                         :class="{ 'focused': file.focused }"
                         :key="index">
              <div class="d-flex flex-column" style="width: 100%;">
                <h3 class="text-left pb-2">{{file.original_file_name}}</h3>
                <h5 class="text-left" style="opacity:80%">Uploaded by : {{file.uploaded_by_name}}</h5>
                <h5 class="text-left" style="opacity:80%">{{file.created_on}}</h5>
                <h4 class="text-left mt-2"> <span v-for="q in quarters"> Q{{q.quarter}} </span> </h4>
                <v-row class="mt-3" align-content="center">
                    <v-col cols="8" style="position: relative">
                <v-text-field
                    label="FedEx tracking number"
                    v-model="file.fedex_tracking"
                    ref="textAreaRef"
                    :disabled="file.show_submit !== 1"
                    @focus="fileList[index].focused = true"
                    @blur="file.focused = false"
                ></v-text-field>

                    </v-col>
                    <v-col  class="pt-6" cols="3" style="position: relative">
                       <v-btn v-show="file.show_submit == 1" @click="postFedexNumber(file.id, file.fedex_tracking, file)" color="#263b4f"><span style="color: #FFFFFF">SAVE</span></v-btn>
                      <v-icon style="left: 0;position: absolute" class="mt-3"
                              @click="() => file.show_submit = 1"
                              v-show="file.show_submit !== 1">mdi-pencil</v-icon>
                    </v-col>
                  </v-row>
                <h4 class="text-right mt-11 mr-6" @click="deleteSubmission(file.id, index)" style="color: #e57d7d;cursor:pointer;">DELETE</h4>
              </div>
            </v-card>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer></v-spacer>

    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card-text>
</template>

<script>
import ClientService from "@/services/clients.service";

export default {
  name: "Submission",
  data(){
    return{
      finalPackages:[],
      selectedPackages: null,
      dragoverData: false,
      forDate: new Date().toUTCString(),
      fileList: [], // Store our uploaded files
      csvFile: null,
      processingFile: false,
      loadingPostRequest: false,
      newUploadClicked: true,
      focused: false,
      quarters: null,
      uploadedFile: null,
    }
  },
  props: {
    clientId: {
      type: Number,
      default: 0
    },
    clientProp: {
      type: Object
    }
  },
  methods:{
    onChange() {
      const uploadedFile = this.$refs.file.files[0]
      if (uploadedFile) {
        this.csvFile = uploadedFile
        this.saveData()
      }
    },
    saveData() {
      this.loadingPostRequest = true
      let formData = new FormData();
      formData.append('uploaded_file', this.csvFile);
      formData.append('upload_type', 'submission');
      ClientService.uploadClientSubmissionCSVFile(this.clientId, formData)
          .then(res => {
            this.uploadedFile = res
            this.loadingPostRequest = false

          }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
        this.loadingPostRequest=false
      })
    },
    postSubmission(){
      //console.log(this.uploadedFile)

      if(this.csvFile !== null && this.finalPackages.length != [])
      {
        this.loadingPostRequest = true
        const body = {
          "upload": this.uploadedFile?.id,
          "package": this.selectedPackages
        }
        ClientService.uploadClientSubmissionCSVFileFinal(this.clientId,body)
            .then(res => {
              res.show_submit = 1
              this.fileList.push(res)
              this.$func.popMessage('Add Fed-Ex Number once this file is submitted to the IRS', 'success')
              this.csvFile = null
              this.loadingPostRequest = false

            }).catch(error=>{
          this.loadingPostRequest = false
          this.$func.popMessage(`${error.response.data.error}`, 'fail')
        })
      }
      else{
        this.$func.popMessage('Please upload a file to create a submission', 'fail')
      }
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    getQuarters(){
      ClientService.getRefunds(this.clientId)
      .then(res => {
        this.quarters = res
      })
    },
    getData(){
      ClientService.getPackages(this.clientId)
      .then(res => {
        if (res.length < 2){
          this.selectedPackages = res[0].id
        }
        this.finalPackages = res
      })
    },
    getSubmissions(){
      ClientService.getSubmissions(this.clientId)
      .then(res => {
        this.fileList = res
        this.fileList.map(e=>{
          e.focused = false
        })
      })
    },
    postFedexNumber(id, fedexNum, file){
      this.loadingPostRequest = true
      const number =  { "fedex_tracking": `${fedexNum}` }
      ClientService.uploadClientSubmissionFedexNumber(id ,number)
      .then(() => {
        this.loadingPostRequest = false
        this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'FedEx tracking number updated successfully',
          duration: 4000,
          mode: 'success'
        });
        file.show_submit = 0
      }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
        this.loadingPostRequest=false
      })
    },
    deleteSubmission(file, index){
      ClientService.deleteClientSubmission(file)
      .then(this.fileList.splice(index,1))
    },
  },
  mounted() {
    this.getData()
    this.getQuarters()
    this.getSubmissions()
  }
}
</script>

<style scoped>
.focused {
  border: 4px solid #129bfe;
}
</style>