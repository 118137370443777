<template>
  <div class="mt-2 ">
    <v-overlay :value="loadingScreen">
      <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <!--		<DialogRightAlign :showDialog.sync="showRightDialog"/>-->
    <div v-if="usersRole == 10 && !loadingScreen" >
      <div class="d-flex flex-column justify-center align-center">
        <h2>Converted Leads</h2>
      </div>
      <v-data-table
          :headers="convertedLeadsHeaders"
          :items="convertedLeads"
          class="elevation-5 mt-6"
          disable-pagination
          hide-default-footer
      ></v-data-table>
    </div>
    <div v-else-if="!loadingScreen">

      <CreateClientDialog
          v-if="showRightDialog"
          :key="dialogKey"
          :showRightDialog.sync="showRightDialog"
          :clientProp="clientProp"
          @deleteDialog="openDeleteDialog"
          @forceUpdate="forceUpdate"
          @getCurrentClient="getCurrentClient"
          @openClientProfileDialog="openClientProfileDialog"
      />

      <ProfileClientDialog
          v-if="showRightDialogChosenClient"
          :key="dialogKeyChosenClient"
          :showRightDialogChosenClient.sync="showRightDialogChosenClient"
          :clientProp="clientProp"
          @openEditClientDialog="openEditClientDialog"
          @getCurrentClient="getCurrentClient"
      />

      <ClientDeleteDialog
          v-if="showDialogDelete"
          v-model="showDialogDelete"
          :edited-item-prop="clientProp"
          @deleteDialog="openDeleteDialog"
      />



      <v-row class="d-flex align-center justify-start ml-lg-5 pb-0" >
        <v-col
            cols="12"
            md="9"
            class="pb-0"
        >
          <v-text-field
              class="pt-5 mr-2"
              v-model="inputSearch"
              label="Search..."
              @keydown.enter="searchFromApi"
          />
        </v-col>

        <v-col
            class="d-flex align-start"
            cols="12"
            md="2"
        >
          <v-btn
              @click.native="searchFromApi"
              color="primary"
              rounded
              block
              large
          >
            Search
          </v-btn>
        </v-col>

        <v-col
            md="12"
            sm="12"
            xs="12"
            v-if="loadingSearch"
        >
          <v-progress-linear
              indeterminate
              color="green"
          />
        </v-col>

        <v-col
            md="12"
            sm="12"
            xs="12"
            v-if="showResults && items.length <= 0 && !loadingSearch"
        >
          <div
              indeterminate
              color="green"
          >
            No results found. Please try a different search
          </div>
        </v-col>


        <v-col
            md="7"
            sm="12"
            xs="12"
            class="pa-0"
            v-if="showResults && !(usersRole==1 || usersRole==4)"
        >

          <v-list >
            <v-list-item
                v-for="(field, i) in items" :key="i"
                @click.native="select = field.id"
                link
            >
              <v-list-item-content
                  :style="{'background': i % 2 === 0 ? '' : 'rgba(190, 199, 230, 0.5)' }"

              >
                <v-list-item-title v-text="field.name"></v-list-item-title>
                <!--							<v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>-->
              </v-list-item-content>
            </v-list-item>
          </v-list>


        </v-col>



        <v-col  md="10"
                sm="12"
                xs="12"
                class="pa-0"
                v-if="showResults && this.items.length && usersRole === 1">
          <v-list >
            <v-row class="justify-space-between ml-2 mb-2">
              <v-col>Legal Name</v-col>
              <v-col>Trade Name</v-col>
              <v-col>EIN</v-col>
              <v-col>Attorney of Record</v-col>
              <v-col>Business Type</v-col>
              
            </v-row>
            <v-list-item
                v-for="(field, i) in items" :key="i"
                @click.native="select = field.id"
                link
            >
              <v-list-item-content
                  :style="{'background': i % 2 === 0 ? '' : 'rgba(190, 199, 230, 0.5)' }"

              >
                <v-row class="justify-space-between ml-2">
                  <v-col>{{field.legal_name}}</v-col>
                  <v-col>{{field.trade_name}}</v-col>
                  <v-col>{{field.ein}}</v-col>
                  <v-col>{{field.aor}}</v-col>
                  <v-col>{{field.business_type}}</v-col>
                </v-row>
                <!--							<v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>-->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>


        <v-col  md="10"
                sm="12"
                xs="12"
                class="pa-0"
                v-if="showResults && this.items.length && usersRole === 4">
          <v-list >
            <v-row class="justify-space-between ml-2 mb-2">
              <v-col>Clients</v-col>
              <v-col>Attorney of Record</v-col>

            </v-row>
            <v-divider class="mx-4 mb-3"></v-divider>
            <v-list-item
                v-for="(field, i) in items" :key="i"
                @click.native="select = field.id"
                link
            >
              <v-list-item-content
                  :style="{'background': i % 2 === 0 ? '' : 'rgba(190, 199, 230, 0.5)' }"

              >
                <v-row class="justify-space-between ml-2">
                  <v-col>{{field.name}}</v-col>
                  <v-col>{{field.aor}}</v-col>
                </v-row>
                <!--							<v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>-->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <template v-if="usersRole === 9">
        <v-data-table
            :headers="managingPartnerHeaders"
            :items="managingPartnerItems"
            class="elevation-1 mt-5"
            disable-pagination
            hide-default-footer
        >

          <template v-slot:item.value="{ item }">
            {{ item.attorney_of_record.first }} {{ item.attorney_of_record.last }}
          </template>

        </v-data-table>


      </template>

      <v-row class="d-flex align-center ml-lg-5">

      </v-row>

      <v-row class="d-flex justify-center align-center mt-10" v-if="usersRole === 1 || usersRole === 4 || usersRole === 7">
        <v-col
            md="2"
            sm="4"
            class="btn-client"
        >
          <v-btn
              @click="openCreateNewClientDialog"
              color="primary"
              block
              rounded
              x-large>
            Create New Client
          </v-btn>
        </v-col>

        <v-fab-transition>
          <v-btn
              @click="openCreateNewClientDialog"
              style="position:fixed; right:0; bottom:0;"
              fab
              large
              dark
              bottom
              right
              class="v-btn--example ma-5"
          >
            <v-icon>mdi-plus</v-icon>

          </v-btn>
        </v-fab-transition>
      </v-row>

    </div>
  </div>

</template>

<script>

import UserAddDialog from "../components/User/UserAddDialog";
import UserManageDialog from "../components/User/UserManageDialog";
import PPPRoundDialog from "../components/PPPRoundDialog";
import ClientDeleteDialog from "../components/Client/ClientDeleteDialog";
import OwnerSelectDialog from "../components/OwnerSelectDialog";
import CreateClientDialog from "../components/Client/CreateClientDialog";
import ProfileClientDialog from "../components/Client/ProfileClientDialog";
import ClientService from "../services/clients.service";
import SearchService from "../services/search.service";
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";
import LeadCreateEditDialog from "@/components/Lead/LeadCreateEditDialog";
import LeadService from "@/services/lead.service";
export default {
  name: 'Clients',
  components: {
    LeadCreateEditDialog,
    OwnerSelectDialog,
    CreateClientDialog,
    ProfileClientDialog,
    ClientDeleteDialog,
    PPPRoundDialog,
    UserManageDialog,
    UserAddDialog,
  },

  data: () => ({
    managingPartnerHeaders: [
      {
        text: 'Trade Name',
        align: 'start',
        value: 'trade_name',
      },
      { text: 'Attorney Of Record', value: 'value' },
    ],
    managingPartnerItems: [],
    leadProp:{},
    loadingScreen:true,
    leadHeaders:[
      { text: 'Trade name', value: 'trade_name', sortable: false},
      { text: 'First name', value: 'contact_first', sortable: false},
      { text: 'Last name', value: 'contact_last', sortable: false},
      { text: 'Email', value: 'contact_email', sortable: false},
      { text: 'Phone number', value: 'contact_phone', sortable: false},
      { text: 'Status', value: 'status.name', sortable: false},
    ],
    leads:[],
    convertedLeads:[],
    convertedLeadsHeaders:[
      { text: 'Trade name', value: 'trade_name', sortable: false},
      { text: 'Converted to', value: 'converted_on', sortable: false},
      { text: 'Attorney assigned', value: 'assigned_attorney', sortable: false},
      { text: 'Status', value: 'status', sortable: false}
    ],
    currentRoundNumber: 0,
    showRightRoundDialog: false,
    showRightDialog: false,
    dialogKey: 0,
    loading: false,
    items: [],
    clientProp: {},
    search: null,
    select: null,
    clients: [],
    showRightDialogChosenClient: false,
    dialogKeyChosenClient: 100,
    showDialogDelete: false,
    dialogKeyDelete: 200,
    dialogRoundKey: 300,
    currentRound: null,
    clientId: 0,
    inputSearch: null,
    showResults: false,
    loadingSearch: false,
    usersRole: false,
    showLead:false,
    showLeadKey:13,
    editLead:false,
    radioRow:'' ,
  }),

  watch: {
    select(val) {
      if (val) {
        this.getCurrentClient(this.select);
      }
    },
    search(val) {

      val && val !== this.select && this.querySelections(val)
    },
  },

  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),
  },

  methods: {

    async searchFromApi() {
      if (this.inputSearch) {
        this.loadingSearch = true;
        this.showResults = false;

        await SearchService.getSearchResults(this.inputSearch).then(res=>{
          this.items = res;
          this.showResults = true;
          this.loadingSearch = false;
        })
        // new Promise(resolve => {
        //   setTimeout(async () => {
        //     this.items = await SearchService.getSearchResults(this.inputSearch);
        //     this.showResults = true;
        //     this.loadingSearch = false;
        //
        //   }, 500);
        // });
      }
    },
    searchMethod() {

      this.querySelections(this.inputSearch);
      this.showResults = true;
      // this.select && this.select !== this.select && this.querySelections(val)
    },

    // openRoundInfoDialog(number) {
    //   this.showRightRoundDialog = true;
    //   this.dialogRoundKey++;
    //   this.currentRound = number;
    // },

    openDeleteDialog() {
      this.showDialogDelete = true;
      this.dialogKeyDelete++;
    },

    forceUpdate() {
      this.items = [];
      if (this.usersRole == 10){
        this.getLeads()
      }else{
        this.getAllClients();
      }
      this.$forceUpdate();
    },
    addHashToLocation(params) {
      history.pushState(
          {},
          null,
          this.$route.path + '#' + encodeURIComponent(params)
      )
    },
    getCurrentClient(id) {
      return ClientService.getOneClient(id)
          .then((data) => {
            this.clientProp = data;
            this.select = null;
            this.openClientProfileDialog();
            this.addHashToLocation(id)
            this.clientId = Number(this.clientProp.id);
            // this.showResults = false;
            // this.inputSearch = null;
          })
          .catch((error) => {

            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: `${error.response.data.error}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;

      this.showRightDialog = false;
    },

    openEditClientDialog() {
      this.showRightDialog = true;
      this.dialogKey++;

      if (this.clientProp) {
        // this.showRightDialogChosenClient = false;
      }
    },

    openCreateNewClientDialog() {
      this.showRightDialog = true;
      this.dialogKey++;

      if (this.clientProp) {
        this.clientProp = null;
      }
    },

    querySelections(v) {

      this.loading = true

      setTimeout(() => {
        this.items = this.clients.filter(e => {
          return (e.legal_name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },

    getAllClients() {
      return ClientService.getClients()

          .then((data) => {
            this.clients = data;
            this.managingPartnerItems = data
          })
          .catch((error) => {

            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: `${error.response.data.error}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    async getUser(){
      await AuthService.getCurrentUser(this.currentUser.current_user_id)
          .then(res=>{
            this.usersRole = res.data.role_id
          })
    },

    doNothing() {

    }
  },

  async created() {

    await this.getUser()
    this.getAllClients();
    if (this.$route.hash) {
      await this.getCurrentClient(this.$route.hash.substring(1))
    }
    if (this.usersRole==10){
      LeadService.getConvertedLeads().then(res=>{
        this.convertedLeads=res;
      })
    }
    this.loadingScreen=false

  }
}
</script>

<style lang="scss" scoped>

#lateral .v-btn--example {
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}

</style>
