<template>
    <div>
        <v-system-bar app color="#263B4F" elevation="4" height="72" class="pl-7 pr-7 " style="box-shadow: 0px -2px 15px 0px rgba(0,0, 0,.8);
                   z-index: 99;
      ">
            <v-app-bar-nav-icon class="d-md-block d-lg-none " @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <router-link to="/">
                <v-img max-height="64px" max-width="252px" id="home-logo-button"
                    src="@/assets/img/logo-top-menu.png"></v-img>
            </router-link>
            <v-spacer></v-spacer>

<!--            <h3 style="color: #fff" id="userFullName" class="mr-12"> {{ userName }} </h3>-->
            <h3 style="color: #fff" id="userFullName" > {{ userName }} </h3>
<!--            <div :class="{ 'notifBg': notificationCount > 0 }" class="notifGenericBg" v-if="showAlert">-->
<!--                &lt;!&ndash; <v-icon id="notifIcon" color="black" style="font-size: 26px;cursor: pointer"-->
<!--                    @click="openNotifications">mdi-bell</v-icon> &ndash;&gt;-->
<!--                <div class="button__badge" v-if="notificationCount > 0">{{ notificationCount }}</div>-->
<!--            </div>-->
            <!--			<v-tooltip bottom>-->
            <!--				<template v-slot:activator="{ on, attrs }">-->
            <!--					<v-btn-->
            <!--						color="white"-->
            <!--						id="settings-button"-->
            <!--						v-bind="attrs"-->
            <!--						v-on="on"-->
            <!--						icon-->
            <!--					>-->
            <!--						<v-icon size="24" color="white"> mdi-cog</v-icon>-->
            <!--					</v-btn>-->
            <!--				</template>-->
            <!--				<span>Settings</span>-->
            <!--			</v-tooltip>-->

            <!--			<v-tooltip bottom>-->
            <!--				<template v-slot:activator="{ on, attrs }">-->
            <!--					<v-btn-->
            <!--						class="ml-5"-->
            <!--						id="notification-button"-->
            <!--						v-bind="attrs"-->
            <!--						v-on="on"-->
            <!--						icon-->
            <!--					>-->
            <!--						<v-icon size="24" color="white"> mdi-bell-outline-->
            <!--						</v-icon>-->
            <!--					</v-btn>-->
            <!--				</template>-->
            <!--				<span>Notifications</span>-->
            <!--			</v-tooltip>-->
            <v-menu offset-y offset-x transition="slide-y-transition" bottom ref="parentMenuRef">
                <template v-slot:activator="{ on, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn class="ml-5" id="profile-button" v-bind="attrs" v-on="on" icon>
                                <v-icon size="48" color="white"> mdi-account-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>My account</span>
                    </v-tooltip>
                </template>

                <v-list class="main-list">
                    <v-list-item class="permissions-dropdown-item" @click="goToPermissionsPage">
                        <v-list-item-title class="permissions-dropdown"><v-icon
                                color="black">mdi-checkbox-marked</v-icon>
                            Permissions</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="permissions-dropdown-item" @click.stop="logout">
                        <v-list-item-title class="permissions-dropdown"><v-icon color="black">mdi-logout</v-icon>
                            Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </v-system-bar>

        <v-main>
            <v-navigation-drawer v-model="drawer" app>
                <TheLayoutWrapMenuList />
            </v-navigation-drawer>

            <transition name="scale" mode="out-in">
                <v-container :key="key">
                    <router-view></router-view>
                </v-container>
            </transition>
        </v-main>
        <Notifications :unread-notifications="unreadNotifications" :show-notif="showNotificationPage"
            :key="notificationKey" v-if="showNotificationPage" />
    </div>
</template>

<script>
import TheLayoutWrapMenuList from "./TheLayoutWrapMenuList";
import AuthService from "../services/auth.service";
import { mapGetters } from "vuex";
import Notifications from "@/components/Client/Notifications";
import { Stomp } from "@stomp/stompjs";
import NotificationService from "@/services/notification.service";
export default {
    name: "TheLayoutWrap",

    components: {
        Notifications,
        TheLayoutWrapMenuList
    },

    data: () => ({
        showNotificationPage: false,
        notificationKey: 44,
        notificationCount: 0,
        drawer: null,
        showPermissionsMenu: false,
        userName: null,
        userRole: null,
        connection: null,
        received_messages: [],
        send_message: null,
        connected: false,
        unreadNotifications: ''
    }),

    methods: {
        hashAsync(algo, str) {
            return crypto.subtle.digest(algo, new TextEncoder("utf-8").encode(str)).then(buf => {
                return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
            });
        },

        openNotifications() {
            this.notificationKey++;
            this.showNotificationPage = true;
        },

        goToPermissionsPage() {
            this.$router.replace('/permissions');
        },

        logout() {
            this.$refs.parentMenuRef.isActive = false
            this.$store.dispatch('auth/signOut').then((res) => {
                if (this.currentUser == null) {
                    this.$router.replace('/login');
                }
            });
        },

        connectNotifSocket() {
            let queueName = '/queue/patriot.users.messages';
            // this.hashAsync("SHA-256", this.currentUser.id)
            //     .then(hash => queueName = `/queue/patriot.user.${hash}`);

            // let url = new WebSocket(process.env.VUE_APP_MESSAGING_BASE_URL);
            // let client = Stomp.over(url);
            let client = Stomp.over(function(){
              return new WebSocket(process.env.VUE_APP_MESSAGING_BASE_URL)
            });
            client.debug = () => { };

            let on_connect = function (x) {
                client.subscribe(queueName, function (m) {
                    console.log(m);
                });
            };

            client.connect(process.env.VUE_APP_MESSAGING_USER, process.env.VUE_APP_MESSAGING_PASS, on_connect);
        },
    },

    computed: {
        showAlert(){
          switch (this.userRole) {
            case 1:
              return true
            case 4:
              return true
            case 7:
              return true
            default:
              return false
          }
        },
        key() {
            return this.$route.path;
        },

        ...mapGetters(
            {
                currentUser: 'auth/getCurrentUser',
            },
        ),
    },

    mounted() {
        // let recaptchaScript = document.createElement('script')
        // recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/stomp.js/2.3.3/stomp.js')
        // recaptchaScript.setAttribute('integrity', 'sha512-tL4PIUsPy+Rks1go4kQG8M8/ItpRMvKnbBjQm4d2DQnFwgcBYRRN00QdyQnWSCwNMsoY/MfJY8nHp2CzlNdtZA==')
        // recaptchaScript.setAttribute('crossOrigin', 'anonymous')
        // recaptchaScript.setAttribute('referrerpolicy', 'no-referrer')
        //
        //
        //  document.head.appendChild(recaptchaScript)


        // Removed untill rabitmq added to prod server
        // this.connectNotifSocket()
    },

    async created() {
        let res = await AuthService.getCurrentUserFromCookie();
        this.userName = res.data.first + ' ' + res.data.last;
        this.userRole = res.data.role_id
        // NotificationService.getAllNotfications().then(res => {
        //     this.notificationCount = res.total_new
        //     this.unreadNotifications = res
        // })
        // this.connectNotifSocket()

        // this.connectNotifSocket()
        // this.connect()
        // console.log("Starting connection to WebSocket Server")
        // this.connection = new WebSocket("wss://dev.patriotdataprocessing.com:5672/ws")
        //
        // this.connection.onmessage = function(event) {
        //   console.log(event);
        // }
        //
        // this.connection.onopen = function(event) {
        //   console.log(event)
        //   console.log("Successfully connected to the echo websocket server...")
        // }
    },
    watch: {
        async currentUser() {
            let res = await AuthService.getCurrentUserFromCookie();
            this.userName = res.data.first + ' ' + res.data.last;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";

#notifIcon {
    margin: -4px 1px 0px 0px;
    display: inline-block;
    /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
    position: relative;
}

.notifGenericBg {
    position: absolute;
    top: 20px;
    right: 81px;
}

.notifBg {
    top: 20px;
    right: 81px;
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 20px;
    color: #ffffff;
}

.notifBg:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #ff3232;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.7;
}

.notifBg:before {
    animation: pulse 1s ease-out infinite;
}

.notifBg:after {
    animation: pulse 1s 1s ease-out infinite;
}

@keyframes pulse {
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

.button__badge {
    background-color: #f34b4b;
    border-radius: 18px;
    color: white;

    padding: 1px 5px;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
    /* Position the badge within the relatively positioned button */
    top: 17px;
    right: -3px;
}

.scale-enter-active,
.scale-leave-active {
    transition: all 0.4s ease;
}

.theme--light.v-system-bar .v-icon {
    color: white !important;
}

.v-menu__content--fixed {
    top: 75px !important;
}

.permissions-dropdown {
    cursor: pointer;
}

.main-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.permissions-dropdown-item {
    transition: color 0.2s;

    &:hover {
        background-color: rgba(38, 59, 79, 1);
        color: white !important;


        .v-icon.v-icon {
            color: white !important;
        }
    }
}


.scale-enter-from,
.scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
}

#home-logo-button {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    #home-logo-button {
        width: 140px;
        margin-left: 10px;
    }
}
#userFullName{
  font-size: 1.2rem;
}
@media only screen and (max-width: 600px) {
  #userFullName{
    font-size: 0.8rem;
  }
    #userName {
        margin-left: 15px;
        font-size: 11px;
    }
}
</style>
