<template>
  <DialogRightAlign
    :showDialog.sync="showRightDialog"
    :titleProp="`Edit Entity`"
    :closeOutside="false"
    :clickedOutside="false"
  >
  <v-card-text class="mt-5">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="12" md="12">
            <!-- {{entityID}} {{entityName}} {{ein}} -->

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                class="auth-form__field"
                required
                placeholder="Firm Name"
                v-model="addUserForm.entityName"
                :error-messages="firmNameErrors"
                @input="$v.addUserForm.entityName.$touch()"
                @blur="$v.addUserForm.entityName.$touch()"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                class="auth-form__field"
                required
                placeholder="EIN"
                v-model="addUserForm.ein"
                :maxlength="10"
                :error-messages="einErrors"
                @input="$v.addUserForm.ein.$touch()"
                @blur="$v.addUserForm.ein.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-select
                v-model="addUserForm.firm_type"
                required
                :error-messages="firmTypeErrors"
                :items="firm_types"
                item-text="firm_type_name"
                item-value="firm_type"
                label="Firm type"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                class="auth-form__field"
                required
                placeholder="First Name"
                v-model="addUserForm.first_name"
                :error-messages="firstNameErrors"
                @blur="$v.addUserForm.first_name.$touch()"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                class="auth-form__field"
                required
                placeholder="Last Name"
                v-model="addUserForm.last_name"
                :error-messages="lastNameErrors"
                @blur="$v.addUserForm.last_name.$touch()"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="addUserForm.email"
                :error-messages="emailErrors"
                label="Email Address"
                required
                @blur="$v.addUserForm.email.$touch()"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="#263B4F" text @click.native="closeDialog"> cancel </v-btn>

      <v-btn v-if="entityObject == null" color="#263B4F" text @click.native="save">
        create entity
      </v-btn>
      <v-btn
        v-else-if="entityObject != null"
        color="#263B4F"
        text
        @click.native="edit"
      >
        edit entity
      </v-btn>
    </v-card-actions>
  </DialogRightAlign>
</template>

<script>
import ClientsService from "@/services/clients.service";
import { email, required, helpers } from "vuelidate/lib/validators";

import DialogRightAlign from "../Base/DialogRightAlign";

const einValidator = helpers.regex("einValidator", /[0-9]{2}-[0-9]{7}/);

export default {
  name: "EntitesDialog",
  components: { DialogRightAlign },

  props: {
    showRightDialog: {
      type: Boolean,
      default: false,
    },
    entityObject: {
      type: Object,
      default: null,
    },
  },

  validations() {
    return {
      addUserForm: {
        entityName: { required },
        ein: { required, einValidator },
        firm_type: { required },
        first_name: { required },
        last_name: { required },
        email: { required, email },
        role_id: { required },
      },
    };
  },

  data: () => ({
    buttonLoadingSpinner: false,
    isUserExisting: false,
    newEntityID: null,
    userID: null,

    addUserForm: {
      entityName: "",
      ein: "",
      firm_type: 1,
      first_name: "",
      last_name: "",
      email: "",
    },

    firm_types: [
      { firm_type: 1, firm_type_name: "Processor" },
      { firm_type: 2, firm_type_name: "Managing Firm" },
      { firm_type: 3, firm_type_name: "Tax Firm" },
      { firm_type: 4, firm_type_name: "Referral Partner" }
    ],
  }),

  methods: {
    closeDialog() {
      this.$emit("update:showRightDialog", false);
    },
    async save() {
      // await ClientsService.getUserByMail(this.addUserForm.email)
      //   .then(res => {
      //       if(res[0].id){
      //         this.isUserExisting = true
      //         //console.log(this.isUserExisting)
      //       }
      //     })
      //   .catch(err => {
      //     if(err.response.status === 404)
      //       this.isUserExisting = false
      //   })

      // if(this.isUserExisting === true){

      //console.log('we are creating the new entity')

      const entityModel = {
        name: this.addUserForm.entityName,
        ein: this.addUserForm.ein,
        firm_type: this.addUserForm.firm_type,
        user_first: this.addUserForm.first_name,
        user_last: this.addUserForm.last_name,
        user_email: this.addUserForm.email,
      };
      await ClientsService.postEntities(entityModel).then((res) => {
        this.$func.popMessage("Entity created.", "success");
      });

      this.$emit("entity-create", "entity-create");
      this.closeDialog();

      // await ClientsService.postEntities(entityModel)
      //   .then(res => this.newEntityID = res.id
      //   ).catch(err => console.log(err))

      // const newUserModel = {
      // "email": this.addUserForm.email,
      // "role": 6,
      // "first": this.addUserForm.first_name,
      // "last": this.addUserForm.last_name
      // }

      //console.log(this.newEntityID)

      //  await ClientsService.createNewUser(this.newEntityID, newUserModel)
      //     .then(res => {this.userID = res.id, console.log(res.id)}).catch(err => console.log(err))

      //     const editModel = [{
      //       "name": this.addUserForm.entityName,
      //       "ein": this.addUserForm.ein,
      //       "user_first": this.addUserForm.first_name,
      //       "user_last": this.addUserForm.last_name,
      //       "user_email": this.addUserForm.email,
      //       "firm_type": this.addUserForm.firm_type,
      //       "primary_user": this.userID
      //     }]

      //     await ClientsService.editEntities(this.newEntityID ,editModel)
      //       .then(res=>  this.$func.popMessage("User and Entity created successfully.")).catch(err => console.log(err))
      // }
    },
    async edit() {
      //console.log('edit entity', this.entityID)

      this.entityObject.ein=this.addUserForm.ein
      await ClientsService.getUserByMail(this.addUserForm.email).then((res) => {
        this.userID = res[0].id;
        //console.log(res[0].id)
      });

      const model = 
        {
          name: this.addUserForm.entityName,
          ein: this.addUserForm.ein,
          firm_type: this.addUserForm.firm_type,
          first: this.addUserForm.first_name,
          last: this.addUserForm.last_name,
          email: this.addUserForm.email
        }
      
      await ClientsService.editEntities(this.entityObject.id, model)
        .then((res) => this.$func.popMessage("Entity edited successfully."))
        .catch();

      this.$emit("updatedFirmType", this.addUserForm.firm_type);
      this.$emit("entity-update", "entity-update");
      this.closeDialog();
    },
  },
  mounted() {
    if (this.entityID !== null) {
      this.addUserForm.entityName = this.entityObject.name;
      this.addUserForm.ein = this.entityObject.ein;
      this.addUserForm.firm_type = this.entityObject.firm_type.id;
      this.addUserForm.first_name = this.entityObject.primary_user.first;
      this.addUserForm.last_name = this.entityObject.primary_user.last;
      this.addUserForm.email = this.entityObject.primary_user.email;
    }
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.addUserForm.email.$dirty) return errors;
      !this.$v.addUserForm.email.email && errors.push("Must be valid e-mail");
      !this.$v.addUserForm.email.required && errors.push("E-mail is required");
      return errors;
    },
    einErrors() {
      const errors = [];
      if (!this.$v.addUserForm.ein.$dirty) return errors;
      !this.$v.addUserForm.ein.einValidator &&
        errors.push(
          "Your EIN format is wrong. Ex: 00-0000000 (Use Digits only)"
        );
      !this.$v.addUserForm.ein.required && errors.push("EIN is required.");
      return errors;
    },
    firmNameErrors() {
      const errors = [];
      if (!this.$v.addUserForm.entityName.$dirty) return errors;
      !this.$v.addUserForm.entityName.required &&
        errors.push("Firm Name required.");
      return errors;
    },
    firmTypeErrors() {
      const errors = [];
      if (!this.$v.addUserForm.firm_type.$dirty) return errors;
      !this.$v.addUserForm.firm_type.required &&
        errors.push("Firm Type is required.");
      return errors;
    },

    firstNameErrors() {
      const errors = [];
      if (!this.$v.addUserForm.first_name.$dirty) return errors;
      !this.$v.addUserForm.first_name.required &&
        errors.push("First Name is required.");
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.addUserForm.last_name.$dirty) return errors;
      !this.$v.addUserForm.last_name.required &&
        errors.push("Last Name is required.");
      return errors;
    },
  },
};
</script>

<style scoped></style>
