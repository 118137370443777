<template>
 <div>
   <v-overlay :value="loading">
     <v-progress-circular
         :size="70"
         :width="7"
         color="white"
         indeterminate
     ></v-progress-circular>
   </v-overlay>
   <v-container fluid>
      <v-radio-group
        v-model="radioRow"
        row
      >
        <v-radio
          label="943"
          value="radio-1"
        ></v-radio>
        <v-radio
          label="944"
          value="radio-2"
        ></v-radio>
      </v-radio-group>
    </v-container>
   <v-data-table
       :single-expand="false"
       :headers="headers"
       :items="items"
       class="elevation-5 mt-6"
       disable-pagination
       hide-default-footer
       item-key="id"
   > <template v-slot:item.trade_name="{ item }">
     <div style="cursor: pointer" @click="goToClient(item.client)">
       {{item.trade_name}}
     </div>
   </template></v-data-table>
   <ProfileClientDialog
       v-if="showRightDialogChosenClient"
       :key="dialogKeyChosenClient"
       :showRightDialogChosenClient.sync="showRightDialogChosenClient"
       :clientProp="clientProp"
       @openClientProfileDialog="openClientProfileDialog"

   />
 </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ClientService from "@/services/clients.service";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
import {mapGetters} from "vuex";
export default {
  name: "UnreviwedUploads",
  components: {ProfileClientDialog},
  data() {
    return {
      items:[],
      loading:true,
      showRightDialogChosenClient:false,
      dialogKeyChosenClient:13,
      clientId:'',
      clientProp:{},
      headers:[
        { text: 'Client', value: 'trade_name' },
        { text: 'Attorney of record', value: 'aor_name' },
        { text: 'Legal name', value: 'legal_name' },
        { text: 'Uploads Awaiting Review', value: 'uploads_awaiting_review' },
        { text: 'Most Recent Upload Date', value: 'most_recent_upload_formatted' },
      ],
      radioRow:'' 
    }
  },
  methods:{
    getData(payload){
      this.loading=true
      ClientsService.getUnreviwedDocs(payload).then(res=>{
        this.items=res
        this.loading=false
      }).catch(()=>this.loading=false)
    },
    goToClient(id){
      this.loading = true
      return ClientService.getOneClient(id)
          .then((data) => {
            this.clientProp = data;
            this.openClientProfileDialog();
            this.clientId = Number(this.clientProp.id);
            // this.showResults = false;
            // this.inputSearch = null;
          })
          .catch((error) => {
            this.loading = false
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: `${error.response.data.error}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },
    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false
      this.showRightDialog = false;
    },
  },
  watch:{
    radioRow(newChoice, oldChoice){
        const payload= {
        business_type:'941'
      }
      if (newChoice=='radio-1'){
        payload.business_type='943'
      } else if (newChoice=='radio-2') {
        payload.business_type='944'
      }
        this.getData(payload)
      },
    refreshPage(val){
     if (val === true) {
       setTimeout(()=>{
         this.getData()
         this.$store.dispatch('reports/changeRefreshUploadReports', false)
       },300)
     }
    }
  },
  computed:{
    ...mapGetters(
        {
          refreshPage: 'reports/getRefreshUploadReports'
        },
    ),
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
