<template>
<div>
    <span v-if="showCounter>0 && (this.displayMinutes!=0 || this.displaySeconds!=0)" class="ml-2">
        {{ displayDays }}:{{ displayHours }}:{{ displayMinutes }}:{{ displaySeconds }}
    </span>
    
</div>
</template>
<script>
export default {
    props: ['snoozedItem','notif'],
    data(){
        return {
            displayDays:0,
            displayHours:0,
            displayMinutes:0,
            displaySeconds:0,
            showCounter:false,
          destroyed:false,
          timer:''
        }
    },
    computed:{
        _seconds:() => 1000,
        _minutes(){
            return this._seconds*60
        },
        _hours(){
            return this._minutes*60
        },
        _days(){
            return this._hours*24
        }
    },
    watch: {
        snoozedItem: {
            handler(){
               if (!this.destroyed) this.showRemaining()
            }
        }
    },
    methods:{
        showRemaining(){
            // if (!this.notif.status) return
            this.showCounter=true;
            let day= 0
            let hour = 0
            let minute= 0
            let seconds =0
              const today = parseInt((new Date(Date.now()).getTime() / 1000).toFixed(0))
              let secondsUntilSnooze = this.snoozedItem - today
              secondsUntilSnooze = Math.floor(secondsUntilSnooze)
              let finalSeconds = 0;
              let minutesUntilSnooze = 0
              if (secondsUntilSnooze > 60) {
                minutesUntilSnooze = Math.floor(secondsUntilSnooze / 60)
                finalSeconds = secondsUntilSnooze - minutesUntilSnooze * 60
              }
              minute = minutesUntilSnooze
              seconds = finalSeconds
              if(Math.floor(secondsUntilSnooze/3600)>=0) hour = Math.floor(secondsUntilSnooze/3600)
            this.timer = setInterval(()=>{
              if (this.destroyed) return;
                let distance = false;
                if (hour + seconds + minute + day === 0) distance = true
                if (distance) {
                    clearInterval(this.timer);
                    this.showCounter=false;
                    this.$emit('enable-card')
                    return
                }
                seconds--
              if (seconds <= 0) {
                minute--;
                seconds = 59;
                if (minute <= 0 && hour>0) {
                  minute = 59;
                    hour--
                    if (hour==0 && day>0){
                      day--
                      hour=23
                    }
                }
              }
                this.displayMinutes = minute
                this.displaySeconds = seconds
                this.displayHours = hour
                this.displayDays = day
            },1000)
        }
    },
  beforeDestroy() {
    this.destroyed = true
    clearInterval(this.timer)
  },
  created(){
      this.showRemaining()
      setTimeout(() => {
        this.$emit('update-parent')
      }, 1000);
    }
};
</script>
<style scoped>
</style>
