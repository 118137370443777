<template>
  <div class="mt-2">
    <DialogRightAlign :showDialog.sync="showRightDialogUsers">
      <div class="d-flex" style="background: rgb(38, 59, 79);position: relative">
        <h3 class="mb-4 ml-4" style="color: #FFFFFF;">Manage Users</h3>
        <v-btn v-if="validUser" @click.prevent="addNewUser" style="position: absolute;right: 0;top: -7px"
               class="mr-4">Add new user
        </v-btn>
      </div>

      <v-card-text class="mt-5">

      <v-container>


        <div
          v-if="usersRole === 1"
          class="d-flex flex-column flex-sm-row align-center justify-space-between"
        >

          <v-text-field
            label="Search user..."
            v-model="searchValue"
            :loading="btnLoading"
            @keyup.enter.native="searchUser"
            class="mr-3"
            :style="`width: ${$vuetify.breakpoint.sm ? 'auto' : '100'}%`"
          ></v-text-field>
          <v-btn
            :style="`width: ${$vuetify.breakpoint.xs ? '100%' : '20%'}`"
            :loading="btnLoading"
            @click="searchUser"
            color="#263B4F"
          >
            <span slot="loader" style="color: white; font-size: 0.7rem">searching...</span>
            <span style="color: #ffffff">Search</span>
          </v-btn>
        </div>

        <v-col class="text-subtitle-1 text-center" cols="12" v-if="loading">
            Loading...
          </v-col>
          <v-col cols="12">
            <v-progress-linear
              :active="loading"
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>

        <v-list-item cols="12" class="d-flex mt-3" style="position: relative; height: 80px; cursor: pointer" v-ripple
                v-for="(user, index) in users" :key="index"  @click="openEditUserDialog(user.id, index,user)">
          <div class="d-flex align-center justify-center" style="background-color: #129bfe;height: 45px;
                     width: 45px;border-radius: 50%;display: inline-block;">
            <v-icon>mdi-account</v-icon>
          </div>
          <div class="d-flex flex-column flex-md-row">
           <span class="mt-3 ml-2">{{user.first}}</span>
           <span class="mt-3 ml-2">{{user.last}}</span>
           </div>
           <span class="mt-3" style="position: absolute;right: 10px">{{user.role}}</span>
        </v-list-item>

      </v-container>

      </v-card-text>

      <v-spacer></v-spacer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="#263B4F"
            text
            @click.native="closeDialog"
        >
          close
        </v-btn>

      </v-card-actions>
    </DialogRightAlign>
    <UserAddDialog
        v-if="showRightDialogAddUser"
        :showRightDialogAddUser.sync="showRightDialogAddUser"
        :key="addUserKey"
        :availableRoles="availableRoles"
    />

    <UserEditDialog
        v-if="showRightDialogEditUser"
        :showRightDialogAddUser.sync="showRightDialogEditUser"
        :user="selectedUser"
        :key="editUserKey"
        :userID="userID"
        @updateUsers="getUsers"
    />
  </div>
</template>


<script>
import DialogRightAlign from "@/components/Base/DialogRightAlign";
import {mapGetters} from "vuex";
import AuthService from "@/services/auth.service";
import UserAddDialog from "@/components/User/UserAddDialog";
import UserEditDialog from "@/components/User/UserEditDialog";
import ClientsService from "@/services/clients.service";
export default {
  name: 'Users',
  components: {
    UserAddDialog,
    UserEditDialog,
    DialogRightAlign,
    AuthService,
    ClientsService
  },

  data: () => ({
    // showRightDialogUsers: false,
    usersRole: null,
    showRightDialogAddUser: false,
    showRightDialogEditUser: false,
    addUserKey: 513,
    editUserKey: 514,
    users: [],
    availableRoles: [],
    selectedUser:{},
    btnLoading: false,
    //user:{},
    loading:true,
    searchValue:''
  }),
  methods: {
    async searchUser(){
      this.btnLoading = true;
      this.loading = true

      //Checks if input is email and if so, runs searchUserByMail() instead
      if (this.searchValue.indexOf('@') > -1) {
        this.searchUserByMail()
        return
      }
      await ClientsService.searchUser(this.searchValue).then(res =>{
        this.users = res
        
      }).catch(()=>this.loading=false)
      this.btnLoading = false;
      this.loading = false;
    },
    async searchUserByMail(){
      this.btnLoading = true;
      this.loading = true
      await ClientsService.getUserByMail(this.searchValue).then(res =>{
        this.users = res
      }).catch(()=>this.loading=false)

      this.btnLoading = false;
      this.loading = false;
    },
    addNewUser() {
      this.showRightDialogAddUser = true
      this.addUserKey++
    },
    openEditUserDialog(id, index,user){
      this.userID = id

      this.selectedUser = user;

      this.showRightDialogEditUser = true
      this.editUserKey++;
    },
    closeDialog() {
      this.$emit('update:showRightDialogUsers', false);
    },
    getUsers(){
      this.loading=true
      ClientsService.getUsers().then(res =>{
        this.availableRoles = res.available_roles
        this.users = res.users
        this.loading = false
      }).catch(()=>this.loading=false)
    },
    getEntityRoles(){
      ClientsService.getEntity(23)
          .then(res=>  {
            //console.log('entities available roles: ', res)
          })
    }
  },
  watch:{
    showRightDialogAddUser(val){
      if (val == false){
        this.getUsers()
      }
    }
  },
  computed: {
    validUser(){
      switch (this.usersRole) {
        case 1:
          return true
        case 4:
          return true
        case 3:
          return true
        case 9:
          return true
        default:
          return false
      }
    },
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),
  },
  props:{
    showRightDialogUsers: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    // this.getEntityRoles()
    AuthService.getCurrentUser(this.currentUser.current_user_id)
        .then(res => {
          this.usersRole = res.data.role_id
          this.user = res.data
        })
  },
  mounted() {
   this.getUsers()
    // this.showRightDialogUsers = true
  },
}
</script>
