<template>
  <div>
    <v-row class="px-3 flex-column flex-lg-row">
      <v-col cols="12" class="d-flex justify-center align-center">
        <h3>Your affiliate link: </h3><a :href="contractorLink"> {{contractorLink}}</a>
      </v-col>
      <v-card
          @click="()=>this.$router.push('/leads')"
          elevation="2"
          class="mt-3 mr-3 d-flex align-center"
          :style="`width: ${$vuetify.breakpoint.lgAndUp ? '23.5' : '100'}%`"
      >
        <v-col class="d-flex flex-column align-center">
          <h2 class="statsText mb-4">Leads</h2>
          <h1 class="headerNumbers">{{leads}}</h1>
        </v-col>
      </v-card>

      <v-card
          @click="()=>this.$router.push('/clients')"
          elevation="2"
          class="mt-3 mr-3"
          :style="`width: ${$vuetify.breakpoint.lgAndUp ? '23.5' : '100'}%`"
      >
        <v-col class="d-flex flex-column align-center">
          <h2 class="statsText mb-4">Clients</h2>
          <h1 class="headerNumbers">{{clients}}</h1>
        </v-col>
      </v-card>

      <v-card
          elevation="2"
          class="mt-3 mr-3"
          :style="`width: ${$vuetify.breakpoint.lgAndUp ? '23' : '100'}%`"
      >
        <v-col class="d-flex flex-column align-center">
          <h2 class="statsText mb-4">Total commissions earned</h2>
          <h1 class="headerNumbers">{{total_commissions_earned_formatted}}</h1>
        </v-col>
      </v-card>

      <v-card   elevation="2"
                class="mt-3"
                :style="`width: ${$vuetify.breakpoint.lgAndUp ? '23' : '100'}%`">
        <v-col class="d-flex flex-column align-center">
          <h2 class="statsText mb-4">Total commissions team</h2>
          <h1 class="headerNumbers">{{total_commissions_team_formatted}}</h1>
        </v-col>
      </v-card>

    </v-row>

    <v-row class="justify-space-between ml-2 mb-2 mt-16">
      <v-col class="tableHeaders">Trade Name</v-col>
      <v-col class="tableHeaders">Status</v-col>
      <v-col class="tableHeaders">Email</v-col>
    </v-row>
    <v-list-item
        v-for="(field, i) in items" :key="i"
        @click.native="selectLead(field)"
        link
    >
      <v-list-item-content
          :style="{'background': i % 2 === 0 ? '' : 'rgba(190, 199, 230, 0.5)' }"

      >
        <v-row class="justify-space-between ml-2">
          <v-col>{{field.trade_name}}</v-col>
          <v-col>{{field.status.name}}</v-col>
          <v-col>{{field.contact_email}}</v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <LeadCreateEditDialog
        v-if="showLead"
        :key="showLeadKey"
        :showRightDialog.sync="showLead"
        :clientProp="leadProp"
        :isEdit="true"
        @forceUpdate="forceUpdate"
        @getCurrentClient="getCurrentLead"
        @openClientProfileDialog="openLeadDialog"/>
  </div>
</template>

<script>
import LeadService from "@/services/lead.service";
import LeadCreateEditDialog from "@/components/Lead/LeadCreateEditDialog";
import ClientService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
export default {
  name: "HomeContractor",
  components:{LeadCreateEditDialog},
  data: () => ({
    items:[],
    selected:'',
    showLead:false,
    showLeadKey:13,
    editLead:true,
    leadProp:{},
    headerItems:[],
    leads:0,
    clients: 0,
    total_commissions_earned_formatted: "",
    total_commissions_team_formatted: "",
    contractorLink:'',
  }),
  methods:{
    selectLead(lead){
      this.leadProp = lead
      this.openLeadDialog()
    },
       getAllLeads(){
           LeadService.getLeads()
               .then(res=>{
                 this.items = res
                 // console.log('this.items',this.items)
               })
       },
    openLeadDialog(){
      this.showLead = true
      this.showLeadKey++
    },
    getCurrentLead(id) {
      return LeadService.getOneLead(id)
          .then((data) => {
            this.leadProp = data;
            this.selected = null;
            this.openLeadDialog();
          })
          .catch((error) => {
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: `${error.response.data.error}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },
    getLeadsScoreboard() {
      return LeadService.getScoreboard()
          .then((data) => {
            this.leads = data.leads;
            this.clients = data.clients;
            this.total_commissions_earned_formatted = data.total_commissions_earned_formatted;
            this.total_commissions_team_formatted = data.total_commissions_team_formatted;
          })
          .catch((error) => {
            console.log(error)
          });
    },
    forceUpdate() {
      this.items = [];
      this.getAllLeads();
      this.$forceUpdate();
    },
},

  created() {
    this.getAllLeads()
    this.getLeadsScoreboard()
    this.contractorLink = `https://www.ertcposteritylegal.com/?utm_source=${this.$store.state.auth.user.current_user_id}`
    // this.items = [
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //   {lead:'test1',status:"New Lead",followUp:"3/12/2022"},
    //
    // ]
  }

}
</script>

<style scoped>
.headerNumbers{
  font-size: 3rem;
}
.statsText{
  font-size: 1.3rem;
  font-weight: 400;
}
.tableHeaders{
  font-size: 2rem;
  font-weight: 550;
}
@media screen and (max-width: 1500px) {
  .headerNumbers{
    font-size: 2rem;
  }
  .tableHeaders{
    font-size: 1.2rem;
  }
  .statsText{
    font-size: 1rem;
  }
}
</style>
