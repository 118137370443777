<template>
  <div class="mt-2 ">
    <ProfileClientDialog
        v-if="showRightDialogChosenClient"
        :key="dialogKeyChosenClient"
        :showRightDialogChosenClient.sync="showRightDialogChosenClient"
        :clientProp="clientProp"
        @openClientProfileDialog="openClientProfileDialog"

    />

    <div class="text-center">
      <!-- <v-progress-circular
          v-if="loadingData"
          :size="150"
          :width="10"
          color="black"
          class="mt-16"
          indeterminate
      ></v-progress-circular> -->

      <div>

        <v-row v-if="currRole != 6" class="px-3 flex-column flex-lg-row">
          <v-card
              elevation="2"
              class="mt-3 mr-3 d-flex align-center"
              :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col >
              <h1 class="statsText">CSA Signed</h1>
              <h1 class="headerNumbers">{{dataForCards.client_count}}</h1>
              <h3>Clients</h3>
            </v-col>
          </v-card>

          <v-card
              elevation="2"
              class="mt-3 mr-3"
              :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col >
              <h1 class="statsText">Processed</h1>
              <h1 class="headerNumbers">{{dataForCards.clients_processed}}</h1>
              <h3>Clients</h3>
              <h3>Total W2s - {{dataForCards.processed_w2}}</h3>
              <h3>Credits - {{dataForCards.processed_credits_formatted}}</h3>
            </v-col>
          </v-card>

          <v-card
              elevation="2"
              class="mt-3"
              :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col >
              <h1 class="statsText">Filed with IRS</h1>
              <h1 class="headerNumbers">{{dataForCards.clients_filed}}</h1>
              <h3>Clients</h3>
              <h3>Total W2s - {{dataForCards.filed_w2}}</h3>
              <h3>Credits - {{dataForCards.filed_credits_formatted}}</h3>
            </v-col>
          </v-card>

        </v-row>

        <v-layout v-resize="onResize" column style="padding-top:56px;">

          <v-radio-group
              row
              v-model="statusRadio"
              class="ml-3"
          >

            <v-radio
                label="Active"
                :value="1"
                @click="dashboardStatus('active')"
            ></v-radio>
            <v-radio
                label="Completed"
                :value="2"
                @click="dashboardStatus('completed')"
            ></v-radio>
            <v-radio
                v-if="this.currRole == 1 || this.currRole == 7"
                label="Inactive"
                :value="3"
                @click="dashboardStatus('inactive')"
            ></v-radio>


          </v-radio-group>

          <v-data-table :loading="loadingData" :headers="headers" :items="dataTableItems" :hide-default-footer="true" disable-pagination fixed-header height="84vh" :class="{mobile: isMobile}"  @click:row="getCurrentClient($event.client)" style="cursor: pointer">

            <template v-slot:[`item.ppp`]="{ item }">
              <td><span class="dot" :style="[item.ppp == 1 ? {'backgroundColor' :'green'} : {'backgroundColor' :'red'}]"></span></td>
            </template>

            <template v-slot:[`item.sanitized_payroll`]="{ item }">
              <td> <span class="dot" :style="[item.sanitized_payroll == 1 ? {'backgroundColor' :'green'} : {'backgroundColor' :'red'}]"></span> </td>
            </template>

            <template v-slot:[`item.matched_owners`]="{ item }">
              <td> <span class="dot" :style="[item.matched_owners == 1 ? {'backgroundColor' :'green'} : {'backgroundColor' :'red'}]"></span></td>
            </template>

            <template v-slot:[`item.wages`]="{ item }">
              <td> <span class="dot" :style="[item.wages == 1 ? {'backgroundColor' :'green'} : {'backgroundColor' :'red'}]"></span></td>
            </template>

            <template v-slot:[`item.employees`]="{ item }">
              <td> <span class="dot" :style="[item.employees == 1 ? {'backgroundColor' :'green'} : {'backgroundColor' :'red'}]"></span></td>
            </template>

            <template v-slot:[`item.final`]="{ item }">
              <td> <span class="dot" :style="[item.final == 1 ? {'backgroundColor' :'green'} : {'backgroundColor' :'red'}]"></span></td>
            </template>

            <template v-slot:[`item.executed`]="{ item }">
              <td> <span class="dot" :style="[item.executed == 1 ? {'backgroundColor' :'green'} : {'backgroundColor' :'red'}]"></span> </td>
            </template>

            <template v-slot:[`item.filed`]="{ item }">
              <td> <span class="dot" :style="[item.filed == 1 ? {'backgroundColor' :'green'} : {'backgroundColor' :'red'}]"></span></td>
            </template>

            <!-- <template slot="items">


              <tr v-if="!isMobile">

              </tr>
              <tr v-else>
                <td>
                  <ul class="flex-content">

                  </ul>
                </td>
              </tr>
            </template> -->
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-layout>
      </div>
    </div>

    <!--		&lt;!&ndash;		<DialogRightAlign :showDialog.sync="showRightDialog"/>&ndash;&gt;-->
    <!--&lt;!&ndash;		<ClientDragAndDropCSVFile :showRightDialog.sync="showRightDialog"/>&ndash;&gt;-->
    <!--&lt;!&ndash;		<UserAddDialog :showRightDialog.sync="showRightDialog"></UserAddDialog>&ndash;&gt;-->
    <!--		&lt;!&ndash;<UserManageDialog :showRightDialog.sync="showRightDialog"></UserManageDialog>&ndash;&gt;-->
    <!--&lt;!&ndash;		<PPPRoundDialog :showRightDialog.sync="showRightDialog" :titleRoundProp="'PPP Round 1'"></PPPRoundDialog>&ndash;&gt;-->
    <!--&lt;!&ndash;		<PPPRoundDialog :showRightDialog.sync="showRightDialog" :titleRoundProp="'PPP Round 2'"></PPPRoundDialog>&ndash;&gt;-->
    <!--&lt;!&ndash;		<ClientDelteDialog  v-model="showRightDialog"></ClientDelteDialog>&ndash;&gt;-->
    <!--		<OwnerSelectDialog :showRightDialog.sync="showRightDialog" :titleRoundProp="'Select Owners'"> </OwnerSelectDialog>-->

    <!--		<v-row class="d-flex align-center ml-lg-5">-->
    <!--			<v-col-->
    <!--				md="10"-->
    <!--				sm="12"-->
    <!--				xs="12"-->
    <!--			>-->
    <!--				<v-autocomplete-->
    <!--					v-model="select"-->
    <!--					:loading="loading"-->
    <!--					:items="items"-->
    <!--					:search-input.sync="search"-->
    <!--					class="mx-4"-->
    <!--					hide-no-data-->
    <!--					hide-details-->
    <!--					label="Search..."-->
    <!--				>-->

    <!--				</v-autocomplete>-->
    <!--			</v-col>-->
    <!--		</v-row>-->

    <!--		<v-row class="d-flex justify-center align-center mt-10">-->
    <!--			<v-col-->
    <!--				md="2"-->
    <!--				sm="4"-->
    <!--			>-->
    <!--				<v-btn-->
    <!--					color="primary"-->
    <!--					block-->
    <!--					rounded-->
    <!--					x-large-->
    <!--				>-->
    <!--					Create New Client-->
    <!--				</v-btn>-->
    <!--			</v-col>-->

    <!--			<v-fab-transition>-->
    <!--				<v-btn-->
    <!--					style="position:fixed; right:0; bottom:0;"-->
    <!--					fab-->
    <!--					large-->
    <!--					dark-->
    <!--					bottom-->
    <!--					right-->
    <!--					class="v-btn&#45;&#45;example ma-5"-->
    <!--				>-->
    <!--					<v-icon>mdi-plus</v-icon>-->

    <!--				</v-btn>-->
    <!--			</v-fab-transition>-->
    <!--		</v-row>-->

    <v-overlay :value="loadingClient">
      <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

  </div>

</template>

<script>

import DragAndDropCSVFile from "../Client/ClientDragAndDropCSVFile";
import ClientDragAndDropCSVFile from "../Client/ClientDragAndDropCSVFile";
import UserAddDialog from "../User/UserAddDialog";
import UserManageDialog from "../User/UserManageDialog";
import PPPRoundDialog from "../PPPRoundDialog";
import ClientDelteDialog from "../Client/ClientDeleteDialog";
import OwnerSelectDialog from "../OwnerSelectDialog";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog";
import ClientService from "@/services/clients.service";
import {mapGetters} from "vuex";
import ClientsService from "../../services/clients.service";

export default {
  name: 'Home',
  components: {
    ProfileClientDialog,
    OwnerSelectDialog,
    ClientDelteDialog,
    PPPRoundDialog, UserManageDialog, UserAddDialog, ClientDragAndDropCSVFile, DragAndDropCSVFile
  },

  data: () => ({
    statusRadio: 1,
    isMobile: true,
    loadingData: false,
    showRightDialog: true,
    loading: false,
    items: [],
    search: null,
    showRightDialogChosenClient: false,
    dialogKeyChosenClient: 0,
    clientProp:{},
    loadingClient:false,
    headers: [
      {text: '', value: 'trade_name'},
      {
        text: 'Days In System',
        value: 'days_in_system', sortable: false
      },
      {
        text: 'PPP',
        value: 'ppp', sortable: false
      },
      // {
      //   text: 'PPP2',
      //   value: 'ppp2', sortable: false
      // },
      {
        text: 'Sanitized Payroll',
        value: 'sanitized_payroll', sortable: false
      },
      {
        text: 'Matched Owners',
        value: 'matched_owners', sortable: false
      },
      {
        text: 'TSSW',
        value: 'wages', sortable: false
      },
      {
        text: 'Emp#',
        value: 'employees', sortable: false
      },
      // {
      //   text: 'Sanitized Payroll',
      //   value: 'sanitized_payroll', sortable: false
      // },
      {
        text: 'Final',
        value: 'final', sortable: false
      },
      {
        text: 'Executed',
        value: 'executed', sortable: false
      },
      {
        text: 'Filed',
        value: 'filed', sortable: false
      },
    ],
    dataTableItems: [],
    dataForCards: {client_count:'0', clients_processed: '0', processed_w2: '0', processed_credits_formatted: '0', clients_filed: '0', filed_w2: '0', filed_credits_formatted: '0'},
    select: null,
    currRole:null,
    clients: [
      'National Fish Market',
      'National Art Studio',
      'National Construction Center',
      'National Client Name'
    ]
  }),

  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
  },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),
  },
  methods: {
    onResize() {
      if (window.innerWidth < 769)
        this.isMobile = true;
      else
        this.isMobile = false;
    },
    addHashToLocation(params) {
      history.pushState(
          {},
          null,
          this.$route.path + '#' + encodeURIComponent(params)
      )
    },
    getCurrentClient(id) {
      this.loadingClient = true
      return ClientService.getOneClient(id)
          .then((data) => {
            this.clientProp = data;
            this.select = null;
            this.addHashToLocation(id)
            this.openClientProfileDialog();
            this.clientId = Number(this.clientProp.id);
            // this.showResults = false;
            // this.inputSearch = null;
          })
          .catch((error) => {
            this.loadingClient = false
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: `${error.response.data.error}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loadingClient = false
      this.showRightDialog = false;
    },
    getData(){
      this.loadingData = true;
      ClientsService.getClientReports()
          .then(e => {
            // console.log(e.signup_data)
            this.dataTableItems = e.clients
            this.dataForCards = e.signup_data;
            this.loadingData = false;
          }).catch(()=>this.loadingData = false)
    },
    dashboardStatus(filter){
      this.loadingData = true;

      ClientsService.getClientReportsStatus(filter)
          .then(e => {
            // console.log(e.signup_data)
            this.dataTableItems = e.clients
            this.dataForCards = e.signup_data;
            this.loadingData = false;
          }).catch(()=>this.loadingData = false)
      // if(this.statusRadio === 1){
      //   ClientsService.getClientReportsStatus(filter)
      //       .then(e => {
      //         // console.log(e.signup_data)
      //         this.dataTableItems = e.clients
      //         this.dataForCards = e.signup_data;
      //         this.loadingData = false;
      //       }).catch(()=>this.loadingData = false)
      // }
      // else if(this.statusRadio === 2){
      //   ClientsService.getClientReportsStatus(filter)
      //       .then(e => {
      //         // console.log(e.signup_data)
      //         this.dataTableItems = e.clients
      //         this.dataForCards = e.signup_data;
      //         this.loadingData = false;
      //       }).catch(()=>this.loadingData = false)
      // }
    },
    // querySelections(v) {
    //   this.loading = true
    //   // Simulated ajax query
    //   setTimeout(() => {
    //     this.items = this.clients.filter(e => {
    //       return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
    //     })
    //     this.loading = false
    //   }, 500)
    // },
  },
  async mounted() {
    await this.dashboardStatus('active')
    //await this.getData()
    let fullPath = this.$route.fullPath
    // const clientParam = fullPath.substring(fullPath.indexOf('#') + 1);
    if (this.$route.hash) {
      this.getCurrentClient(this.$route.hash.substring(1))
    }
    // console.log('The id is: ', clientParam);
    // this.getCurrentClient(clientParam)
    // console.log(this.currentUser)
    ClientsService.getUsersRole(this.currentUser.current_user_id)
        .then(res=> this.currRole = res.role_id)
  }
}
</script>

<style lang="scss" scoped>

#lateral .v-btn--example {
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}

.dot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
}
.headerNumbers{
  font-size: 3rem;
}
.green-bg { display: table-row; } .green-bg:hover {  background: #ffff7e !important; }


@media only screen and (max-width: 900px) {
  .statsText
  {
    font-size: 1rem;
  }

  .headerNumbers{
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: .5em;
    text-align: left;
    display: block;
    color: #999;

  }
  .v-datatable__actions__select
  {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }
  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }

}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}
</style>
