import $axios from '../plugins/axios'

function popFailure(message) {
	this.$store.dispatch('snackbar/showSnackbarMessage', {
		message: message,
		duration: 4000,
		mode: 'fail'
	});
}
class ClientsService {

	getClients() {
		return $axios.get('/clients')
			.then((res) => {

				return res.data;
			});
	}

	getBusinessTypes() {
		return $axios.get('clients/business-types')
			.then((res) => {
				return res.data;
			});
	}

	getOneClient(id) {
		return $axios.get(`/clients/${id}`)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				popFailure(`${error.response.data.error}`);
			})
	}

	getSanitazationJob(id) {
		return $axios.get(`sanitization/jobs/${id}`)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				popFailure(`${error.response.data.error}`);
			})
	}

	getSanitazationComments(id) {
		return $axios.get(`sanitization/jobs/${id}/comments`)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				popFailure(`${error.response.data.error}`);
			})
	}

	postSanitazationComment(id, body) {
		return $axios.post(`sanitization/jobs/${id}/comments`, body, {
		})
			.then((res) => {
				return res.data
			})
	}

	getSanitazationQueue(x) {
		return $axios.get('queues/sanitization', {params:x})
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				popFailure(`${error.response.data.error}`);
			})
	}


	getDownloadFiles(id) {
		return $axios.get(`clients/${id}/uploads/payroll`)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				popFailure(`${error.response.data.error}`);
			})
	}

	postSanitazationQueue(id, body) {
		return $axios.post(`/clients/${id}/notes`, body, {
		})
			.then((res) => {
				return res.data
			})
	}

	getSanitizeJobComments() {
		return $axios.get('')
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				popFailure(`${error.response.data.error}`);
			})
	}

	getAssignTools(clientID, filter) {
		return $axios.get(`/clients/${clientID}/assign-tool/${filter}`)
			.then((res) => {

				return res.data;
			})
			.catch((error) => {

				popFailure(`${error.response.data.error}`);
			})
	}

	patchAssignTools(clientID, data) {
		return $axios.patch(`/clients/${clientID}/assign-tool`, data)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}



	createClient(data) {
		return $axios.post(`/clients`, data)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}

	editClient(id, data) {
		return $axios.patch(`/clients/${id}`, data)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}

	deleteClient(id) {
		return $axios.delete(`/clients/${id}`)
			.then(res => {
				return res.data;
			})
			.catch((error) => {


				throw error;
			})
	}

	getRounds(data) {
		return $axios.get(`clients/${data.clientId}/rounds`)
			.then((res) => {

				return res.data;
			});
	}

	addRound(data) {
		return $axios.post(`clients/${data.clientId}/rounds`, data.round)
			.then((res) => {
				return res.data;
			});
	}

	editRound(data) {
		return $axios.patch(`rounds/${data.clientId}`, data.round)
			.then((res) => {
				return res.data;
			});
	}

	deleteRound(id) {
		return $axios.delete(`rounds/${id}`)
			.then((res) => {
				return res.data;
			});
	}

	uploadClientCSVFile(id, file) {
		return $axios.post(`/clients/${id}/uploads`, file, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(() => {
				return
			})
	}

	uploadClientCSVFileWithReturningData(id, file) {
		return $axios.post(`/clients/${id}/uploads`, file, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then((res) => {
				return res
			})
	}

	deleteUpload(id) {
		return $axios.delete(`clients/${id}/uploads`)
			.then((res) => {
				return res.data;
			});
	}


	getWages(id) {
		return $axios.get(`clients/${id}/941/wages`)
			.then((res) => {
				return res.data;
			});
	}

	deleteWages(id) {
		return $axios.delete(`clients/${id}/941/wages`)
			.then((res) => {
				return res.data;
			});
	}

	editWages(id, data) {
		return $axios.patch(`clients/${id}/941/wages`, data)
			.then((res) => {
				return res.data;
			});
	}

	saveWages(id, data) {
		return $axios.post(`clients/${id}/941/wages`, data)
			.then((res) => {
				return res.data;
			});
	}

	getEmployeesAmounts(id) {
		return $axios.get(`clients/${id}/941/employees`)
			.then((res) => {
				return res.data;
			});
	}

	deleteEmployeesAmounts(id) {
		return $axios.delete(`clients/${id}/941/employees`)
			.then((res) => {
				return res.data;
			});
	}

	saveEmployeesAmounts(id, data) {
		return $axios.post(`clients/${id}/941/employees`, data)
			.then((res) => {
				return res.data;
			});
	}

	patchEmployeesAmounts(id, data) {
		return $axios.patch(`clients/${id}/941/employees`, data)
			.then((res) => {
				return res.data;
			});
	}

	patchClientOnHold(id, data) {
		return $axios.patch(`clients/${id}/hold`, data)
			.then((res) => {
				return res.data;
			});
	}

	getRefunds(id) {
		return $axios.get(`clients/${id}/941/refunds`)
			.then((res) => {
				return res.data;
			});
	}

	deleteRefunds(id) {
		return $axios.delete(`clients/${id}/941/refunds`)
			.then((res) => {
				return res.data;
			});
	}

	saveRefunds(id, data) {
		return $axios.post(`clients/${id}/941/refunds`, data)
			.then((res) => {
				return res.data;
			});
	}

	getPackages(id) {
		return $axios.get(`clients/${id}/packages/final`)
			.then((res) => {
				return res.data;
			});
	}

	postPackage(id, data) {
		return $axios.post(`clients/${id}/packages`, data)
			.then((res) => {
				return res.data;
			});
	}

	getSubmissions(id) {
		return $axios.get(`clients/${id}/submissions`)
			.then((res) => {
				return res.data;
			});
	}

	getYearlyQuarters(id) {
		return $axios.get(`clients/${id}/refunds`)
			.then((res) => {
				return res.data;
			});
	}

	uploadClientSubmissionCSVFile(id, file) {
		return $axios.post(`/clients/${id}/uploads`, file, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then((res) => {
				return res.data
			})
	}

	deleteUpload(id) {
		return $axios.delete(`clients/${id}/uploads`)
			.then((res) => {
				return res.data;
			});
	}

	uploadClientSubmissionCSVFileFinal(id, body) {
		return $axios.post(`/clients/${id}/submissions`, body, {
		})
			.then((res) => {
				return res.data
			})
	}

	uploadClientSubmissionFedexNumber(id, file) {
		return $axios.patch(`/submissions/${id}`, file, {
		})
			.then((res) => {
				return res.data
			})
	}

	getUsersRole(id) {
		return $axios.get(`/auth/users/${id}`)
			.then((res) => {
				return res.data;
			});
	}

	deleteClientSubmission(id) {
		return $axios.delete(`/submissions/${id}`, {
		})
			.then(() => {
				return
			})
	}

	getClientReports() {
		return $axios.get(`/reports/clients`)
			.then((res) => {
				return res.data;
			});
	}

	getDownlineReport() {
		return $axios.get(`/reports/downline`)
			.then((res) => {
				return res.data;
			});
	}

	getClientReportsStatus(filter) {
		return $axios.get(`/reports/clients/${filter}`)
			.then((res) => {
				return res.data;
			});
	}


	postNote(id, body) {
		return $axios.post(`/clients/${id}/notes`, { 'note': body }, {
		})
			.then((res) => {
				return res.data
			})
	}

	getNotes(id) {
		return $axios.get(`/clients/${id}/notes`)
			.then((res) => {
				return res.data;
			});
	}

	patchNote(id, file) {
		return $axios.patch(`/notes/${id}`, { 'note': file }, {
		})
			.then((res) => {
				return res.data
			})
	}

	patchReadyToFile(payload) {
		return $axios.patch(`/reports/clients/filed/completed`, payload)
			.then((res) => {
				return res.data
			})
	}

	postReadyToShip(payload) {
		return $axios.post(`/queues/shipping`, payload)
			.then((res) => {
				return res.data
			})
	}

	getShippment(id) {
		return $axios.get(`/queues/shipping/${id}`)
			.then((res) => {
				return res.data
			})
	}

	deleteReadyToShip(id) {
		return $axios.delete(`/shipping/${id}`)
			.then((res) => {
				return res.data
			})
	}

	patchReadyToShip(id, payload) {
		return $axios.patch(`/shipments/${id}`, payload)
			.then((res) => {
				return res.data
			})
	}

	deleteReadyToShipClients(id, payload) {
		return $axios.delete(`/shipping/${id}/clients`, payload)
			.then((res) => {
				return res.data
			})
	}

	deleteNote(id) {
		return $axios.delete(`/notes/${id}`, {
		})
			.then(() => {
				return
			})
	}

	postRawClients(data, id) {
		return $axios.post(`/clients/${id}/family`, data)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}

	putRawClients(data, id) {
		return $axios.put(`/family/${id}`, data)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}

	getRawOwners(id) {
		return $axios.get(`/clients/${id}/family`)
			.then((res) => {
				return res.data;
			});
	}
	getUserByMail(email) {
		return $axios.get(`/users/email?q=${email}`)

			.then((res) => {
				return res.data
			})
	}
	postPackage(id, data) {
		return $axios.post(`clients/${id}/packages`, data)
			.then((res) => {
				return res.data;
			});
	}

	createNewUser(id, body) {
		return $axios.post(`/entities/${id}/users`, body, {
		})
			.then((res) => {
				return res.data
			})
	}

	getEntities() {
		return $axios.get(`entities/all`)
			.then((res) => {
				return res.data;
			});
	}

	searchEntity(searchTerm) {
		return $axios.get('/entities/search', {
			params: {
				q: searchTerm,
			}
		})
			.then((res) => {
				return res.data;
			});
	}

	getEntitiesFiltered(filter) {
		return $axios.get(`entities/${filter}`)
			.then((res) => {
				return res.data;
			});
	}

	postEntities(body) {
		return $axios.post(`/entities`, body, {
		})
			.then((res) => {
				return res.data
			})
	}


	editEntities(id, body) {
		return $axios.patch(`/entities/${id}`, body, {
		})
			.then((res) => {
				return res.body
			})
	}


	deleteEntities(id) {
		return $axios.delete(`/entities/${id}`)
			.then(res => {
				return res.data;
			})
			.catch((error) => {


				throw error;
			})
	}

	getReconciliationDeposits(filter) {
		return $axios.get(`/payments/${filter}`)
			.then((res) => {
				return res.data;
			});
	}

	getReadyToReconcileDocs(x) {
		return $axios.get(`/queues/reconciliation`,{params:x})
			.then((res) => {
				return res.data;
			});
	}

	getReconciliationQueueStatuses() {
		return $axios.get(`/queues/reconciliation/statuses`)
			.then((res) => {
				return res.data;
			});
	}

	patchReadyToReconcile(id, data) {
		return $axios.patch(`/queues/reconciliation/${id}`, data)
			.then((res) => {
				return res.data;
			});
	}

	deleteDeposit(id) {
		return $axios.delete(`/deposit/${id}`)
			.then((res) => {
			});
	}

	getChecks(id) {
		return $axios.get(`clients/${id}/receivables`)
			.then((res) => {
				return res.data;
			});
	}

	getReports(date) {
		return $axios.get(`/fees?from=${date}`)
			.then((res) => {
				return res.data;
			});
	}

	getFeeByID(id, date) {
		return $axios.get(`/fees/${id}?from=${date}`)
			.then((res) => {
				return res.data;
			});
	}

	getManagingAttFeeByID(id, date, parent) {
		return $axios.get(`/fees/${parent}/attorney/${id}?from=${date}`)
			.then((res) => {
				return res.data;
			});
	}

	editCheck(id, data) {
		return $axios.post(`clients/${id}/checks/solves`, data)
			.then((res) => {
				return res.data;
			});
	}

	getFilteredReports(filter) {
		return $axios.get(`/fees/${filter}`)
			.then((res) => {
				return res.data;
			});
	}

	editReport(id, data) {
		return $axios.patch(`/fees/${id}`, data)
			.then((res) => {
				return res.data;
			});
	}

	postCheck(id, data) {
		return $axios.post(`clients/${id}/checks`, data)
			.then((res) => {
				return res.data;
			});
	}

	applyPayment(id, data) {
		return $axios.post(`clients/${id}/payments`, data)
			.then((res) => {
				return res.data;
			});
	}

	postFeePayments(data, id) {
		return $axios.post(`fees/${id}`, data)
			.then((res) => {
				return res.data;
			});
	}

	getUsers() {
		return $axios.get(`entities/my`)
			.then((res) => {
				return res.data;
			});
	}

	searchUser(searchTerm) {
		return $axios.get('/users/search', {
			params: {
				q: searchTerm,
			}
		})
			.then((res) => {
				return res.data;
			});
	}

	editUser(id, data) {
		return $axios.patch(`users/${id}`, data)
			.then((res) => {
				return res.data;
			});
	}

	deleteUser(id) {
		return $axios.delete(`users/${id}`)
			.then((res) => {
				return res.data;
			});
	}

	downloadCalculations(id) {
		return $axios.get(`/clients/${id}/downloads/calculations`)
			.then((res) => {
				return res.data;
			});
	}

	getTaxFirms() {
		return $axios.get(`pods`)
			.then((res) => {
				return res.data;
			});
	}

	postPods(data) {
		return $axios.post(`pods`, data)
			.then((res) => {
				return res.data;
			});
	}

	postRevenuereduction(id, data) {
		return $axios.post(`/clients/${id}/revenuereduction`, data)
			.then((res) => {
				return res.data;
			});
	}

	postReconciliation(data) {
		return $axios.post(`/deposit`, data)
			.then((res) => {
				return res.data;
			});
	}

	getOverpayments() {
		return $axios.get(`overpayments`, { 'filter': 'paid' })
			.then((res) => {
				return res.data;
			});
	}

	getOverpaymentsWithFilter(filter) {
		return $axios.get(`overpayments/${filter}`)
			.then((res) => {
				return res.data;
			});
	}

	getRefundedOverpayments(id, data) {
		return $axios.get(`/clients/${id}/overpayments`, data)
			.then((res) => {
				return res.data;
			});
	}

	getRefundedOverpaymentsStatus(id, data) {
		return $axios.get(`/clients/${id}/overpayments-status`, data)
			.then((res) => {
				return res.data;
			});
	}

	postOverpaymentRefund(id, data) {
		return $axios.post(`/clients/${id}/overpayments`, data)
			.then((res) => {
				return res.data;
			});
	}

	postBussinessImpact(id, data) {
		return $axios.post(`/clients/${id}/businessimpact`, data)
			.then((res) => {
				return res.data;
			});
	}

	deletePods(id) {
		return $axios.delete(`/pods/${id}`)
			.then((res) => {
				return res.data;
			});
	}
	patchPod(id, data) {
		return $axios.patch(`/pods/${id}`, data)
			.then((res) => {
				return res.data;
			});
	}

	patchUpload(id, data) {
		return $axios.patch(`/uploads/${id}`, data)
			.then((res) => {
				return res.data;
			});
	}

	getUploadReqDocs() {
		return $axios.get(`/reqdocs`)
			.then((res) => {
				return res.data;
			});
	}

	// getClientsRequiredDocuments() {
	// 	return $axios.get(`/reports/clients/documents`)
	// 		.then((res) => {
	// 			return res.data;
	// 		});
	// }	
	
	getClientsRequiredDocuments(x) {
		return $axios.get(`/reports/clients/documents`, {params:x})
			.then((res) => {
				return res.data;
			});
	}

	getReportsPages() {
		return $axios.get(`/reports`)
			.then((res) => {
				return res.data;
			});
	}

	getReportsForManagingParter() {
		return $axios.get(`/reports/leads/myentity`)
			.then((res) => {
				return res.data;
			});
	}

	getSingleLead(id) {
		return $axios.get(`/leads/${id}`)
			.then((res) => {
				return res.data;
			});
	}

	getReadyToSanitize(x) {
		return $axios.get(`/reports/clients/rts`, {params:x})
			.then((res) => {
				return res.data;
			});
	}

	getOutstandingUploads(id) {
		return $axios.get(`/clients/${id}/reqdocs`)
			.then((res) => {
				return res.data;
			});
	}

	sendEmailOutstandingUploads(id) {
		return $axios.get(`/clients/${id}/messages/email/aor/oustandingdocs`)
			.then((res) => {
				return res.data;
			});
	}

	notReqUpload(id) {
		return $axios.patch(`/uploads/${id}`)
			.then((res) => {
				return res.data;
			});
	}

	moveToReadyToFinal(data) {
		return $axios.post(`/reports/clients/final`, data)
			.then((res) => {
				return res.data;
			});
	}

	getReadyToFinal(x) {
		console.log('x',x)
		return $axios.get(`/reports/clients/final`, {params:x})
			.then((res) => {
				return res.data;
			});
	}

	moveToFiled(data) {
		return $axios.patch(`/reports/clients/filed`, data)
			.then((res) => {
				return res.data;
			});
	}

	getReadyToFile(filter) {
		return $axios.get(`/reports/clients/shipped/${filter}`)
			.then((res) => {
				return res.data;
			});
	}

	getUnreviwedDocs(x) {
		return $axios.get(`/reports/uploads/unreviewed`,{params:x})
			.then((res) => {
				return res.data;
			});
	}

	getOrphaned() {
		return $axios.get(`/contractors/unmatched`)
			.then((res) => {
				return res.data;
			});
	}

	searchOrphan(searchTerm) {
		return $axios.get('/contractors/search', {
			params: {
				q: searchTerm,
			}
		})
			.then((res) => {
				return res.data;
			});
	}
	postResourcePage(filter,data) {
		return $axios.post(`/resources/page/${filter}`, data)
			.then((res) => {
				return res.data;
			});
	}
	getUnassignedClients() {
		return $axios.get(`/queues/clients/unassigned`)
			.then((res) => {
				return res.data;
			});
	}

	patchReconciliation(id,data) {
		return $axios.patch(`/queues/reconciliation/${id}`, data)
			.then((res) => {
				return res.data;
			});
	}
	getSignatureVerification() {
		return $axios.get(`/queues/unverified-signatures`)
			.then((res) => {
				return res.data;
			});
	}

	patchSignatureQuarters(id,data) {
		return $axios.patch(`/signatures/${id}`, data)
			.then((res) => {
				return res.data;
			});
	}

	getPeoClients() {
		return $axios.get(`/reports/clients/peo`)
			.then((res) => {
				return res.data;
			});
	}

	getPeoUploads(id) {
		return $axios.get(`/clients/${id}/uploads/peo`)
			.then((res) => {
				return res.data;
			});
	}

	getSubmissionsProgressData() {
		return $axios.get(`/reports/clients/pending`)
			.then((res) => {
				return res.data;
			});
	}

	getPendingPaymentsCSV() {
		return $axios.get(`/downloads/pending/clients`)
			.then((res) => {
				return res.data;
			});
	}

	getClientsPerformance() {
		return $axios.get(`/reports/performance/clients`)
			.then((res) => {
				return res.data;
			});
	}
}

export default new ClientsService();
