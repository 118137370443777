<template>
    <v-container>

      <v-card
          class="pa-2 mb-10"
      >
        <v-row no-gutters>
          <v-col
              cols="12"
              sm="12"
              md="6"
          >
            <div class="d-flex flex-column">
              <p class="text-h5">Shipment ID: {{ shipmentID }}</p>
              <p class="text-h5">Shipping To: {{ shippingTo }}</p>
            </div>

          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
          >

            <div class="d-flex flex-md-row-reverse">
              <div class="d-flex flex-column">

                <v-btn color="red" class="white--text" @click="DeletePackage">Delete Package</v-btn>

              </div>
            </div>

          </v-col>
        </v-row>
      </v-card>

      <v-data-table
          :headers="headers"
          :items="readyToShipItems"
          item-key="id"
          show-select
          v-model="selectedClients"
          :hide-default-footer="true"
          disable-pagination
      >
        <template v-slot:top>
          <v-toolbar flat>
            <p v-if="readyToShipItems.length <= 1">{{readyToShipItems.length}} client selected for new shipment to {{shippingTo}}. Download and print 941-X forms and the label, then mark printed</p>
            <p v-else-if="readyToShipItems.length > 1">{{readyToShipItems.length}} clients selected for new shipment to {{shippingTo}}. Download and print 941-X forms and the label, then mark printed</p>
          </v-toolbar>
        </template>
      </v-data-table>

      <div class="d-flex flex-column flex-sm-row align-center mt-5">
      <v-btn color="primary" @click="DeleteSelectedClients">Delete Selected</v-btn>
      <v-spacer></v-spacer>
      <div class="d-flex flex-column flex-sm-row align-center">
      <p class="mb-0 mr-3">FedEx tracking number for the shipment</p>
      <v-text-field
          class="auth-form__field"
          placeholder="FedEx Tracking Number"
          v-model="fedExTracking"
      />
      </div>
      </div>

      <div class="d-flex justify-center justify-sm-end">
      <v-btn color="primary" @click="SealAndShip">Seal And Ship</v-btn>
      </div>

    </v-container>
</template>
<script>
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";

export default {
    data () {
      return {
        shipmentID: null,
        shippingTo: 'loading...',
        headers: [
          {
            text: 'Client',
            value: 'trade_name',
            sortable: false
          },
          { text: '', value: 'file', sortable: false },
          { text: '', value: 'label', sortable: false },
          { text: 'Printed', value: 'printed', sortable: false },
        ],
        readyToShipItems: [],
        selectedClients: [],
        clientIDs: [],
        fedExTracking: null
      }
    },
    methods: {
      GetShippment(){
        ClientsService.getShippment(this.shipmentID).then(res =>{
          this.shippingTo = res.destination
          this.readyToShipItems = res.client_ids
          //console.log(res)
        })
      },
      DeleteSelectedClients() {
        this.selectedClients.map(item => this.clientIDs.push(item.id))

        // let idModel = {
        //   "client_ids": this.clientIDs
        // }
        // this.selectedClients.map(async (item) =>
        //   ClientsService.deleteReadyToShipClients(this.shipmentID, item.id).then(res =>{
        //     this.$func.popMessage('Successfully deleted', 'success')
        //   })
        // )
        this.$axios.delete(`/shipping/${this.shipmentID}/clients`, {
          data: {
            "client_ids": this.clientIDs
          }
        });
        this.GetShippment()
      },
      SealAndShip(){
        let fedExModel = {
          "tracking_number": this.fedExTracking,
          "client_ids":this.selectedClients.map(e=>e.id)
        }

        ClientsService.patchReadyToShip(this.shipmentID, fedExModel).then(res =>{
          this.$router.push('/queues/ready-to-ship')
          this.$func.popMessage('Successfully sent', 'success')
        })
      },
      DeletePackage(){
        ClientsService.deleteReadyToShip(this.shipmentID).then(res =>{
          this.$router.push('/queues/ready-to-ship')
          this.$func.popMessage('Successfully deleted', 'success')
        })
      }
    },
    created(){
      const route = this.$route.path.split('/')
      this.shipmentID = route[3]
      this.GetShippment()
  },
}
</script>
<style scoped>
.itemRowBackground{
  background-color: #D21919;
}
</style>
