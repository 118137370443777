<template>
  <div>
    <div class="mb-5">
      <h2 class="mb-2" v-if="!switchBtn">Ready to Ship</h2>
      <v-divider class="mt-4 mb-4"></v-divider>
      <h2 class="mb-2" v-if="switchBtn">Shipped</h2>

      <div class="d-flex flex-row justify-space-between" style="width: 100%">
        <h2 class="mb-4">Ogden, UT</h2>
<!--        <v-btn color="#263B4F" v-if="!switchBtn" class="white&#45;&#45;text" @click="()=>fedexDialog = true" >Move to Filed</v-btn>-->
        <div class="d-flex align-center">
          <h3 class="mr-4">Ready to Ship/Shipped</h3>
          <v-switch v-model="switchBtn" @click="switchClicked"></v-switch>
        </div>
      </div>
      <v-data-table
          :headers="headers"
          :items="items.UT_office"
          item-key="id"
          :show-select="!switchBtn"
          v-model="selectedItemsUT"
          disable-pagination
          class="elevation-1 mt-5"
          hide-default-footer
          loading-text="Loading... Please wait"
      ></v-data-table>

      <v-btn class="mt-5" color="primary" v-if="!switchBtn" @click="SubmitSelectionUT">Create Shipment</v-btn>
    </div>
    <div >
      <h2 class="mb-4">Cincinnati, OH</h2>
      <v-data-table
          :headers="headers"
          :items="items.OH_office"
          item-key="id"
          :show-select="!switchBtn"
          disable-pagination
          v-model="selectedItemsOH"
          class="elevation-1 mt-5"
          hide-default-footer
          loading-text="Loading... Please wait"
      ></v-data-table>

      <v-btn class="mt-5" color="primary" v-if="!switchBtn" @click="SubmitSelectionOH">Create Shipment</v-btn>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-dialog  v-model="fedexDialog" >
    <v-card class="pa-10" style="height: 40vh">
    <h2 class="mb-4 font-weight-medium text-p ml-2" >Create Box</h2>
    <v-card
      elevation="4"
      outlined
      style="height: 25vh"
    >
    <div class="d-flex justify-center flex-wrap">
      <div class="box-container mx-2">
      <v-select
        v-model="selectedClients"
        :items="combined"
        item-text="trade_name"
        item-value="id"
        label="Clients"
        multiple
      >
      </v-select>
      <v-btn color="#263B4F" class="white--text" id="shipIRS" @click="shipToIRS" :disabled="!selectedClients.length">Ship to IRS</v-btn>
      </div>
    <v-text-field placeholder="Fedex tracking number" v-model="fedexNumber" class="box-container mx-2"></v-text-field>  
    </div>
    </v-card>
    </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
export default {
  name: "ReadyToFile",
  data(){
    return{
      items:[],
      selectedItemsUT: [],
      UtahIDs: [],
      selectedItemsOH: [],
      OhioIDs: [],
      switchBtn:false,
      loading:true,
      fedexDialog:false,
      fedexNumber:null,
      selectedOH:[],
      selectedUT:[],
      combined:[],
      selectedClients:[],
      selectedTraderIDs:[],
      selectedClientsIDsAndFedex:{},
      headers: [
        { text: 'Client', value: 'trade_name' },
        { text: 'Q4', value: 'additional_data[0].refund_amount_formatted' },
        { text: 'Q1', value: 'additional_data[1].refund_amount_formatted' },
        { text: 'Q2', value: 'additional_data[2].refund_amount_formatted' },
        { text: 'Q3', value: 'additional_data[3].refund_amount_formatted' },
      ],
    }
  },
  methods:{
    SubmitSelectionUT() {
      this.selectedItemsUT.map(item => this.UtahIDs.push(item.id))

      let modelUT = {
        "destination": "Utah",
        "client_ids": this.UtahIDs
      }

      ClientsService.postReadyToShip(modelUT)
        .then(res => {
          this.$router.push(`/queues/ready-to-ship/${res.id}`)
          this.$func.popMessage('Successfully submitted', 'success')
        })
    },
    SubmitSelectionOH() {
      this.selectedItemsOH.map(item => this.OhioIDs.push(item.id))

      let modelUT = {
        "destination": "Ohio",
        "client_ids": this.OhioIDs
      }

      ClientsService.postReadyToShip(modelUT)
          .then(res => {
            this.$router.push(`/queues/ready-to-ship/${res.id}`)
            this.$func.popMessage('Successfully submitted', 'success')
          })
    },
    shipToIRS(){
       let model = {
         "fedex_number":this.fedexNumber,
         "clients":this.selectedClients
       }

      ClientsService.patchReadyToFile(model)
          .then(()=>this.getItems(0)).catch((err)=> {
            this.$func.popMessage(`${err.response.data.error}`, 'fail')
          })
    },
    switchClicked(){
      this.loading=true
      this.switchBtn ? this.getItems(1) : this.getItems(0)
    },
    getItems(filter){
      this.loading=true
       ClientsService.getReadyToFile(filter).then(res=>{
         if (filter == 1){
           this.headers = [
             { text: 'Client', value: 'trade_name' },
             { text: 'Q4', value: 'additional_data[0].refund_amount_formatted' },
             { text: 'Q1', value: 'additional_data[1].refund_amount_formatted' },
             { text: 'Q2', value: 'additional_data[2].refund_amount_formatted' },
             { text: 'Q3', value: 'additional_data[3].refund_amount_formatted' },
             { text: 'Fedex Number', value: 'fedex_tracking' },
           ]
         }
         this.items=res
         this.loading=false      
       }).then(()=>{
        this.combined=[...this.items.OH_office,...this.items.UT_office]

       }).catch(()=>this.loading=false)
    }
  },
  created() {
    this.getItems(0)
  }
}
</script>

<style scoped>
.box-container{
  min-width: 300px;
  width: 45%;
}
#shipIRS{
  width: 100%;
}
</style>
