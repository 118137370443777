<template>
  <v-dialog
    v-click-outside="onClickOutside"
    :bind="clickedOutside"
    content-class="my-custom-dialog full-height"
    v-model="mutableShowDialog"
    max-width="700px"
    v-bind="{ persistent: closeOutside }"
  >
    <v-card class="d-flex flex-column" style="min-height: 100vh">
      <v-card-title style="background: #263b4f">
        <span v-if="peo" class="mr-2 icon"><v-icon style="color:white" size="x-large">mdi-donkey</v-icon></span>
        <span v-if="businessType==2" class="mr-2 icon"><v-icon style="color:white" size="x-large">mdi-tractor</v-icon></span>
        <span v-if="businessType==3" class="mr-2 icon"><v-icon style="color:white" size="x-large">mdi-charity</v-icon></span>
        <v-toolbar-title class="white--text">{{ titleProp }} </v-toolbar-title>
        <!--				<h4 class="text-center white&#45;&#45;text"> </h4>-->

        <v-spacer></v-spacer>

        <div class="header-button">
          <slot name="header-button" />
        </div>
      </v-card-title>

      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DialogRightAlign",

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    clickedOutside: {
      type: Boolean,
      default: false,
    },
    closeOutside: {
      type: Boolean,
      default: false,
    },

    titleProp: {
      type: String,
      default: "",
    },
    businessType: {
      type: Number,
      default:1
    },
    peo:{
      type:Boolean,
      default:false
    }
  },


  watch: {
    mutableShowDialog(val) {
      if (!val) {
        this.removeHashLocation();
      }
      if (!val && this.activeTourGuide){
        this.$store.dispatch('tour/changeCloseTour', true)
      }
    },
  },
  methods: {
    onClickOutside() {
      if (this.clickedOutside === true) this.$router.push("/dashboard");
      if (this.$route.path === "/leads") this.$store.dispatch('leads/changeForceRefreshPage', true)
      if (this.$route.path === "/leads") this.$store.dispatch('leads/changeForceRefreshPage', true)
      if (this.$route.path === "/unreviewed-documents") this.$store.dispatch('reports/changeRefreshUploadReports', true)
    },
    removeHashLocation() {
      history.pushState({}, null, this.$route.path);
    },
  },
  computed: {
    mutableShowDialog:{
      get(){
        return this.showDialog
      },
      set(val){
        return val
      }

    },
    heightWindow() {
      return window.innerHeight - 1;
    },
    ...mapGetters(
        {
          activeTourGuide: 'tour/getIsTourGuideActive'
        },
    ),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  margin: 0px;
  z-index: 9999;
}

.v-dialog__content {
  z-index: 9999999;
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-end;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
}

i.v-icon.notranslate.mdi.mdi-tractor.theme--light, i.v-icon.notranslate.mdi.mdi-charity.theme--light {
    font-size: 2.4rem !important;
}

</style>
