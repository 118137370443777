<template>
	<div>

    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
		<DialogRightAlign :peo="client.uses_peo == 1 ? true : false" :showDialog.sync="showRightDialogChosenClient"  :titleProp="`${client.legal_name}`" :businessType="business_type"
  >
      <template #header-button>
     <div class="d-flex align-center">
       <h5 class="white--text mr-3">Inactive/Active</h5>
       <v-switch v-model="activeClient" color="white" @click="patchClient">
       </v-switch>
     </div>
      </template>
      <v-btn class="d-md-none d-md-flex-and-up" fab dark large color="primary" fixed right bottom @click="closeDialog">
        X
      </v-btn>


      <div style="background: #f7f9ac 90%!important;" class="md-primary tour-guide-ribbon px-12 py-2" v-if="showTourGuideRibbon && usersRole === 1 && navTabOne">
        <div id="tour-ribbon">
          <span style="color: #AF0808;font-weight: 500"> Learn how to use our website. </span>
          <a @click="startTourGuide">  Click through Tutorial <u> here</u>. </a>
        </div>
      </div>
			<v-card-text style="padding: 10px !important">
				<v-container style="padding: 0 !important">
          <template>
<!--          <v-row v-if="usersRole === 1">-->
<!--            <v-col class="navBarTabsAdmin"-->
<!--             @click="()=> changeNavTab(1)"-->
<!--              v-bind:class="{ navBarTabsActiveAdmin : navTabOne}">PROFILE</v-col>-->
<!--            <v-col class="navBarTabsAdmin"-->
<!--             @click="()=> changeNavTab(2)"-->
<!--              v-bind:class="{ navBarTabsActiveAdmin : navTabTwo}">UPLOADS</v-col>-->
<!--            <v-col class="navBarTabsAdmin"-->
<!--             @click="()=> changeNavTab(3)"-->
<!--             v-bind:class="{ navBarTabsActiveAdmin : navTabThree}">NOTES</v-col>-->
<!--            <v-col class="navBarTabsAdmin"-->
<!--             @click="()=> changeNavTab(4)"-->
<!--             v-bind:class="{ navBarTabsActiveAdminFourth : navTabFour}">SUBMISSION</v-col>-->
<!--            <v-col class="navBarTabsAdmin"-->
<!--                   @click="()=> changeNavTab(5)"-->
<!--                   v-bind:class="{ navBarTabsActiveAdminFourth : navTabFive}">AGREEMENTS</v-col>-->
<!--            <v-col class="navBarTabsAdmin"-->
<!--                   @click="()=> changeNavTab(6)"-->
<!--                   v-bind:class="{ navBarTabsActiveAdminSixth : navTabSix}">ADMIN</v-col>-->
<!--            <v-col class="navBarTabsAdmin"-->
<!--                   @click="()=> changeNavTab(7)"-->
<!--                   v-bind:class="{ navBarTabsActiveAdminSeventh : navTabSeven}">DOWNLOADS</v-col>-->
<!--            <div class="underbar"></div>-->
<!--          </v-row>-->




            <!-- Mobile/Tablet navigation -->
            <v-menu offset-y
              class="hidden-md-and-up"
            >

              <template v-slot:activator="{ on, attrs }">
               <v-app-bar
                  elevation="4"
                  rounded
                  shaped
                  v-bind="attrs" v-on="on"
                  class="hidden-md-and-up mb-3"
                >
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>
                  {{ tabName }}
                </v-app-bar>
              </template>

            

              <v-list dense id="navMenu" class="hidden-md-and-up">
                  <v-list-item
                    link
                    @click="()=> changeNavTab(1)"
                  >
                  <v-list-item-content>
                    <v-list-item-title>PROFILE</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    @click="()=> changeNavTab(2)"
                  >
                  <v-list-item-content>
                    <v-list-item-title>UPLOADS</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    v-if="client.uses_peo"
                    @click="()=> changeNavTab(9)"
                >
                  <v-list-item-content>
                    <v-list-item-title>PEO DOCUMENTS</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    @click="()=> changeNavTab(3)"
                  >
                  <v-list-item-content>
                    <v-list-item-title>NOTES</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    @click="()=> changeNavTab(4)"
                  >
                  <v-list-item-content>
                    <v-list-item-title>SUBMISSION</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="usersRole !== 6"
                    link
                    @click="()=> changeNavTab(5)"
                  >
                  <v-list-item-content>
                    <v-list-item-title>DOWNLOADS</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="usersRole === 1"
                    link
                    @click="()=> changeNavTab(6)"
                  >
                  <v-list-item-content>
                    <v-list-item-title>ADMIN</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    @click="()=> changeNavTab(7)"
                  >
                  <v-list-item-content>
                    <v-list-item-title>STATUS</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="usersRole === 1 || usersRole === 4"
                  link
                  @click="()=> changeNavTab(8)"
                >
                <v-list-item-content>
                  <v-list-item-title>TOOLS</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              </v-list>


              </v-menu>


            <!-- Desktop navigation -->
            <v-slide-group
              show-arrows
              id="admin-tab"
              class="hidden-sm-and-down visible-sm-up"
            >
            <v-tabs >
              <v-tab v-model="active_tab" style="padding: 0 !important" @click="()=> changeNavTab(1)">PROFILE</v-tab>
              <v-tab v-model="active_tab" style="padding: 0 !important" @click="()=> changeNavTab(2)">UPLOADS</v-tab>
              <v-tab v-model="active_tab"  v-if="client.uses_peo && (this.usersRole == 1 || this.usersRole == 7)" style="padding: 0 !important" @click="()=> changeNavTab(9)">PEO DOCUMENTS</v-tab>
              <v-tab v-model="active_tab" style="padding: 0 !important" @click="()=> changeNavTab(3)">NOTES</v-tab>
              <v-tab v-model="active_tab" style="padding: 5px !important" @click="()=> changeNavTab(4)">SUBMISSION</v-tab>
              <v-tab v-model="active_tab" v-if="usersRole !== 6 && usersRole !== 12" style="padding: 5px !important" @click="()=> changeNavTab(5)">DOWNLOADS</v-tab>
              <v-tab v-model="active_tab" v-if="usersRole === 1 " style="padding: 0 !important" @click="()=> changeNavTab(6)">ADMIN</v-tab>
              <v-tab v-model="active_tab" v-if="usersRole !== 12" style="padding: 0 !important" @click="()=> changeNavTab(7)">STATUS</v-tab>
              <v-tab v-model="active_tab" v-if="usersRole === 1 || usersRole === 4" style="padding: 0 !important" @click="()=> changeNavTab(8)">TOOLS</v-tab>
            </v-tabs>
            </v-slide-group>


          
          </template>
          <div v-show="navTabOne">
            <v-row v-if="!roundOne && roundsDataLoaded">
              <v-col>
                <div class="no-csv-message">
                  MISSING: 2020 Round of PPP must be updated.
                </div>
              </v-col>
            </v-row>

            <v-row v-if="!roundTwo && roundsDataLoaded">
              <v-col>
                <div class="no-csv-message">
                  MISSING: 2021 Round of PPP must be updated.
                </div>
              </v-col>
            </v-row>

<!--            <v-row v-if="!client.csv_upload.id">-->
<!--              <v-col>-->
<!--                <div class="no-csv-message">-->
<!--                  MISSING: You must upload a payroll CSV for this client.-->
<!--                </div>-->
<!--              </v-col>-->
<!--            </v-row>-->

<!--            <v-row v-if="owners.length == 0 && hasOwners == false">-->
<!--              <v-col>-->
<!--                <div class="no-csv-message">-->
<!--                  MISSING: You must designate which payees in the CSV are owners or family of owners or-->
<!--                  select "No owners" because owners are not included in the payroll.-->
<!--                </div>-->
<!--              </v-col>-->
<!--            </v-row>-->

            <v-row class="d-flex justify-center align-center">

              <v-col>
                <v-hover v-slot="{ hover }">
                  <v-card
                      id="clientEditCard"
                      class="mx-auto"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      link
                      outlined
                      @click.native="goToEdit()"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h5 mb-6 font-weight-bold">
                          {{ client.legal_name }}
                          <h3 style="font-size: 0.8rem;opacity: 0.6;">Date entered: {{client.created}} ({{client.age}} days ago)</h3>
                        </v-list-item-title>

                        <v-list-item-subtitle class="mb-4">
                          {{ client.street }}, {{ client.city }}, {{ client.state }},
                          {{ client.zip }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle class="mb-4">
                          {{ client.ein }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-if="client.contact_phone !== null" class="mb-4">
                          {{ client.contact_phone }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle class="mb-4">
                          {{ client.contact_first }} {{ client.contact_last }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle class="mb-4">
                          {{ client.contact_email }}
                        </v-list-item-subtitle>

                        <v-spacer></v-spacer>

                      </v-list-item-content>

                    </v-list-item>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>

            <v-row  class="d-flex justify-start align-start">
              <v-col
                  id="pppRound1"
                  :cols="12"
                  lg="6"
                  md="12"
                  sm="12"
                  xs="12"
              >
                <v-hover  v-slot="{ hover }">
                  <v-card
                      class="mx-auto"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      link
                      outlined
                      min-height="210"
                  >
                    <v-list-item three-line >
                      <v-list-item-content >
                        <v-list-item-title class="text-h5 mb-6 font-weight-bold">
                          2020 Round of PPP
                        </v-list-item-title>

                        <div v-if="roundOne">
                          <v-list-item-subtitle class="mb-4"
                                                v-if="roundOne.did_not_receive === 1">
                            Client did not receive this round of PPP
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="mb-4" v-if="!roundOne.did_not_receive">
                            {{ roundOne.amount }}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="mb-4" v-if="!roundOne.did_not_receive">
                            {{ roundOne.start.split(' ')[0] }}
                          </v-list-item-subtitle>
                        </div>

                        <v-list-item-subtitle class="mb-4" v-if="!roundOne">
                          No Information Present!
                        </v-list-item-subtitle>
                        <v-spacer></v-spacer>

                        <!--											<v-card-actions v-if="roundOne.did_not_receive">-->
                        <!--											</v-card-actions>-->

                        <!--											<v-card-actions v-if="!roundOne.did_not_receive">-->

                        <!--											</v-card-actions>-->

                        <v-card-actions>
                          <v-btn
                              v-if="roundOne"
                              text
                              color="#1867C0"
                              @click.stop="popUp(roundOne.id,1)"
                          >
                            <u>Clear </u>
                          </v-btn>

                          <v-btn
                              v-if="!roundOne"
                              text
                              color="#1867C0"
                              @click.stop="openRoundInfo(1)"
                          >
                            Add Information
                          </v-btn>
                        </v-card-actions>

                      </v-list-item-content>

                    </v-list-item>
                  </v-card>
                </v-hover>

              </v-col>

              <v-col
                  id="pppRound2"
                  :cols="12"
                  lg="6"
                  md="12"
                  sm="12"
                  xs="12"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                      class="mx-auto"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      link
                      outlined
                      min-height="210"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h5 mb-6 font-weight-bold">
                          2021 Round of PPP
                        </v-list-item-title>

                        <div v-if="roundTwo">
                          <v-list-item-subtitle class="mb-4"
                                                v-if="roundTwo.did_not_receive === 1">
                            Client did not receive this round of PPP
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="mb-4" v-if="!roundTwo.did_not_receive">
                            {{ roundTwo.amount }}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="mb-4" v-if="!roundTwo.did_not_receive">
                            {{ roundTwo.start.split(' ')[0] }}
                          </v-list-item-subtitle>
                        </div>

                        <v-list-item-subtitle class="mb-4" v-if="!roundTwo">
                          No Information Present!
                        </v-list-item-subtitle>
                        <v-spacer></v-spacer>

                        <v-card-actions>
                          <v-btn
                              v-if="roundTwo"
                              text
                              color="#1867C0"
                              @click.stop="popUp(roundTwo.id,2)"
                          >
                            <u>Clear </u>
                          </v-btn>

                          <v-btn
                              v-else
                              text
                              color="#1867C0"
                              @click.stop="openRoundInfo(2)"
                          >
                            Add Information
                          </v-btn>
                        </v-card-actions>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-hover>

              </v-col>
            </v-row>

            <v-row class="d-flex justify-center align-center">
             <!-- <v-col
                  cols="12"
              >
                 <v-hover v-slot="{ hover }">
                  <v-card
                      class="mx-auto"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      link
                      outlined
                      @click.native="openRawUploadDialog"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h5 mb-6 font-weight-bold">
                          Payroll Raw
                        </v-list-item-title>

                        <v-row>
                          <v-col
                              cols="6"
                              style="padding-top: 5px; !important; padding-bottom: 5px;!important;"

                          >
                            <span> Raw Payroll CSV </span>
                          </v-col>
                        </v-row>

                        <div v-if="uploadedData.length">
                          <v-list-item-subtitle v-if="index<10" v-for="(upload,index) in uploadedData">
                            {{ upload.original_file_name }}
                          </v-list-item-subtitle>
                        </div>
                        <div v-else>
                          <span>No CSV uploaded</span>
                        </div>


                        <v-spacer></v-spacer>

                        <v-card-actions>
                          <v-btn
                              text
                              color="#1867C0"
                              @click.stop="openRawUploadDialog"
                          >
                            <u> Upload CSV </u>
                          </v-btn>
                        </v-card-actions>
                      </v-list-item-content>

                    </v-list-item>
                  </v-card>
                </v-hover> 
              </v-col>-->

              <v-col
                  cols="12"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                      class="mx-auto"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      link
                      outlined
                      @click.native="openSelectOwnersRawDialog"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="text-h5 mb-6 font-weight-bold">
                          Owners and family list
                        </v-list-item-title>

                        <v-row>
                          <v-col
                              cols="6"
                              style="padding-top: 5px; !important; padding-bottom: 5px;!important;"
                              v-for="(owner, index) in owners" :key="'owner' + index"
                          >
                            <span>{{ owner }} </span>
                          </v-col>
                        </v-row>

<!--                          <v-list-item-subtitle v-if="owners.length == 0 && hasOwners">-->
<!--                            You have declared the uploaded CSV file does not contain any owner or-->
<!--                            family records-->
<!--                          </v-list-item-subtitle>-->

                        <v-list-item-subtitle v-if="owners.length === 0">
                          Update your list of owners and family for this client
                        </v-list-item-subtitle>

                        <v-list-item-subtitle class="mb-4">
                        </v-list-item-subtitle>
                        <v-spacer></v-spacer>

                        <v-card-actions>
                          <v-btn
                              text
                              color="#1867C0"
                              @click.stop="openSelectOwnersRawDialog"
                          >
                            <u> Edit owners</u>
                          </v-btn>
                        </v-card-actions>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 font-weight-bold">
                        <div class="d-flex justify-space-between align-center">
                          <span>Taxable social security wages</span>

                          <v-switch
                              v-model="isTaxDisabled"
                              color="#48A64C"
                              class="switchDraftOrFinal"
                              hide-details
                          />
                        </div>
                      </v-list-item-title>
                      <v-row>
                        <v-col
                            cols="3"
                            v-for="(tax, index) in taxSocialSecurity" :key="'owner' + index"
                        >
                          <v-text-field
                              v-model="taxSocialSecurity[index].wages"
                              required
                              class="number-text-field"
                              persistent-hint
                              :hint="hints[index]"
                              color="black"
                              type="number"
                              :disabled="!isTaxDisabled"
                              @blur="saveTaxSocialSecurity"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 font-weight-bold">
                        <div class="d-flex justify-space-between align-center">
                          <span>Employee counts per quarter </span>

                          <v-switch
                              v-model="isEmployeeDisabled"
                              color="#48A64C"
                              class="switchDraftOrFinal"
                              hide-details
                          />
                        </div>
                      </v-list-item-title>
                      <v-row>
                        <v-col
                            cols="3"
                            v-for="(employeeCount, index) in employeeCounts"
                            :key="'employee-counts' + index"
                        >
                          <v-text-field
                              v-model.number="employeeCounts[index].count"
                              required
                              persistent-hint
                              :hint="hints[index]"
                              color="black"
                              type="number"
                              :disabled="!isEmployeeDisabled"
                              @blur="saveEmployeesAmounts"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
<!--              <v-col cols="12">-->
<!--                <v-card-->
<!--                    class="mx-auto"-->
<!--                    outlined-->
<!--                >-->
<!--                  <v-list-item three-line>-->
<!--                    <v-list-item-content>-->
<!--                      <v-list-item-title class="text-h5 font-weight-bold">-->
<!--                        <div class="d-flex justify-space-between align-center">-->
<!--                          <span>ERC refund totals </span>-->

<!--                          <v-switch-->
<!--                              v-model="isErcRefundDisabled"-->
<!--                              color="#48A64C"-->
<!--                              class="switchDraftOrFinal"-->
<!--                              hide-details-->
<!--                          />-->
<!--                        </div>-->
<!--                      </v-list-item-title>-->

<!--                      <v-row>-->
<!--                        <v-col-->
<!--                            cols="3"-->
<!--                            v-for="(refund, index) in ercRefundTotals" :key="'erc-refunds' + index"-->
<!--                        >-->
<!--                          <v-text-field-->
<!--                              v-model="ercRefundTotals[index]"-->
<!--                              required-->
<!--                              persistent-hint-->
<!--                              :hint="hints[index]"-->
<!--                              color="black"-->
<!--                              type="number"-->
<!--                              :disabled="!isErcRefundDisabled"-->
<!--                          />-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </v-list-item-content>-->
<!--                  </v-list-item>-->
<!--                </v-card>-->
<!--              </v-col>-->
            </v-row>

            <v-row >
              <v-col cols="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 font-weight-bold">
                        <div class="d-flex justify-space-between align-center">
                          <span> Generated packages </span>
                        </div>
                      </v-list-item-title>

                      <v-row >
                        <v-col
                            cols="12"
                        >
                          <v-list class="mt-4" v-if="generatedPackages.length" dense>
                            <v-list-item
                                class="highlight-hover"
                                v-for="(onePackage, index) in generatedPackages"
                                :key="'packages-generated-' + index"
                            >
                              <!--                            <v-icon>-->
                              <!--                              mdi-help-circle-->
                              <!--                            </v-icon>-->
                              <span>
															 <a :href="onePackage.url" :download="onePackage.download_attrib" target=”_blank”> {{ onePackage.label }} </a>
														</span>

                              <v-spacer></v-spacer>


                              <button
                                  class="ml-2"
                                  type="button"
                                  @click="shipPackage(onePackage.id)"
                                  title="Ship to IRS"
                                  link
                              >

                                <!--                              <v-icon>-->
                                <!--                                mdi-truck-->
                                <!--                              </v-icon>-->
                              </button>
                              <button
                                  v-if="!onePackage.show_loading"
                                  class="ml-2"
                                  type="button"
                                  @click="deletePackage(onePackage.id)"
                                  title="Delete package"
                                  link
                              >

                                <v-icon>
                                  mdi-close
                                </v-icon>
                              </button>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-progress-circular
                      v-if="generationInProgress"
                      indeterminate
                      color="green"
                      style="margin-bottom: 2em;"
                  />
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="usersRole === 1" class="d-flex justify-end align-end" >
              <v-spacer></v-spacer>
              <v-col>
                <span class="draft-span">Draft </span>
                <v-switch
                    v-model="isDraft"
                    label="Final"
                    color="red"
                    class="switchDraftOrFinal"
                    hide-details
                />
              </v-col>

              <v-col >
                <v-btn
                    id="generatePackageBtn"
                    color="primary"
                    @click.native="generatePackage"
                    :disabled="(!roundOne && !roundTwo) || (originalOwners.length == 0 && hasOwners == false) || !client.csv_upload.id"
                >
                  GENERATE PACKAGE
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-if="navTabTwo">
            <v-col>
              <div class="text-center">
                 <Uploads
                     @fileDropedNotSaved="handleUploadsNotSaved"
                     :clientProp=client
                     :clientId=this.client.id
                 />
              </div>
            </v-col>
          </div>
          <div v-if="navTabThree">
            <v-col>
              <div class="text-center">
                <Notes
                    :clientProp=client
                    :clientId=this.client.id
                    :user=this.user />
              </div>
            </v-col>
          </div>
          <div v-if="navTabNine">
            <v-col>
              <div class="text-center">
                <PeoUploads
                    :clientProp=client
                    :clientId=this.client.id
                    :user=this.user />
              </div>
            </v-col>
          </div>
          <div v-if="navTabFour">
            <v-col>
              <div class="text-center">
                <Submission
                    :clientProp=client
                    :clientId=this.client.id
                />
              </div>
            </v-col>
          </div>
          <div v-if="navTabSix">
            <v-col>
              <div class="text-center">
                <Admin
                    :clientProp=client
                    :clientId=this.client.id
                />
              </div>
            </v-col>
          </div>
          <div v-if="navTabFive">
            <v-col>
              <div class="text-center">
<!--                <Agreements-->
<!--                    :clientProp=clientProp-->
<!--                    :clientId=this.client.id-->
<!--                />-->
                <Downloads
                    :clientProp=client
                    :clientId=this.client.id
                />
              </div>
            </v-col>
          </div>
          <div v-if="navTabSeven">
            <v-col>
              <div class="text-center">
<!--                <Downloads-->
<!--                    :clientProp=clientProp-->
<!--                    :clientId=this.client.id-->
<!--                />-->
                <Status :clientId=this.client.id>
                </Status>
              </div>
            </v-col>
          </div>

          <div v-if="navTabEight">
            <v-col>
              <div class="text-center">
                <Tool :clientId=this.client.id></Tool>
              </div>
            </v-col>
          </div>
				</v-container>
			</v-card-text>

			<v-spacer></v-spacer>

			<v-card-actions id="clientOnHold">
          <v-switch
            v-if="(usersRole === 1 || usersRole === 7) && navTabOne"
            v-model="switchClientOnHold"
            :label="`Put Client on Hold`"
            @click="switchOnHold"
          ></v-switch>
				<v-spacer></v-spacer>
				<v-btn
          class="d-none d-md-block"
					color="#263B4F"
					text
					@click.native="closeDialog"
				>
					close
				</v-btn>

			</v-card-actions>
		</DialogRightAlign>

<!--		<ClientDragAndDropCSVFile-->
<!--			:key="keyUploadCSV + '-csv'"-->
<!--			:showRightDialog.sync="showRightDialogUploadCSV"-->
<!--			:clientIdProp="this.client.id"-->
<!--			@getDataForOwnersDialog="getAllOwners"-->
<!--			@getUpdatedInfo="getUpdatedInfo"-->
<!--		/>-->

<!--		<OwnerSelectDialog-->
<!--			:key="showOwnersSelectDialogKey + '-owners'"-->
<!--			:showRightDialog.sync="showOwnersSelectDialog"-->
<!--			:ownersProp="employees"-->
<!--			:hasOwnersProp="hasOwners"-->
<!--			:titleRoundProp="'Select Owners'"-->
<!--			@saveOwners="saveOwners"-->
<!--		/>-->
    <DeleteConfirm
     :showDialog.sync="snowDeleteDialog"
     :deleteRoundID="this.round"
     @clicked="onClickChild">

    </DeleteConfirm>
    <ClientDragAndDropRawFile
        :key="keyUploadRaw + '-raw'"
        :showRightDialog.sync="showRightDialogUploadRaw"
        :clientIdProp="this.client.id"
        :uploadedData="this.uploadedData"
    />
    <CreateClientDialog
        v-if="clientInfo"
        :key="clientInfoKey"
        :showRightDialog.sync="clientInfo"
        :clientProp="client"
        @deleteDialog="openDeleteDialog"
        @getCurrentClient="getCurrentClient"
        @forceUpdate="getUpdatedInfo()"
    />

<!--    <CreateClientDialog-->
<!--        v-if="clientInfo"-->
<!--        :key="clientInfoKey"-->
<!--        :showRightDialog.sync="clientInfo"-->
<!--        :clientProp="clientProp"-->
<!--        @deleteDialog="openDeleteDialog"-->
<!--        @getCurrentClient="getCurrentClient"-->
<!--    />-->


    <OwnerRawDialog
    :key="showRawOnerKey + '-rawOwn'"
    :showRawOner.sync="showRawOner"
    :clientIdProp="this.client.id"
    :raw-oners="this.rawOwnersString"
    :owners-submitted="this.isGetOwnersSubmitted"
    :raw-owner-i-d="this.rawOnersId"
    />

    <OwnerDialog
        :key="keyOwn + '-own'"
        :showRightDialog.sync="showRightDialogOwner"
        :clientIdProp="this.client.id"
    />
    <LeaveUploadsConfirm
        :showDialog.sync="leaveUploads"
        @clicked="onLeaveUploads"
    />

    <PPPRoundDialog
        :showRightRoundDialog.sync="showRightRoundDialog"
        :titleRoundProp="'Add round'"
        :currentRound="currentRound"
        :clientId="client.id"
        :key="dialogRoundKey"
        @getCurrentClient="getAllRounds"
    />

    <ClientDeleteDialog
        v-if="showDialogDelete"
        v-model="showDialogDelete"
        :edited-item-prop="client"
        @deleteDialog="openDeleteDialog"
        @getCurrentClient="getCurrentClient(this.clientProp.id)"
    />

	</div>
</template>

<script>
 import Status from "./NavTabs/Status";
	import ClientDragAndDropCSVFile from "@/components/Client/ClientDragAndDropCSVFile";
	import DialogRightAlign from "@/components/Base/DialogRightAlign";
	import ClientService from "../../services/clients.service";
	import OwnersService from "../../services/owners.service";
	import PackageService from "../../services/packages.service";
  import Submission from "@/components/Client/NavTabs/Submission";
  import Uploads from "@/components/Client/NavTabs/Uploads";
  import OwnerDialog from "@/components/Client/OwnerDialog";
import { assertDeclareTypeAlias, FLOWBASEANNOTATION_TYPES } from "@babel/types";
import DeleteConfirm from "./DeleteConfirm.vue";
import ClientDragAndDropRawFile from "@/components/Client/ClientDragAndDropRawFile";
import AuthService from "@/services/auth.service";
import Admin from "@/components/Client/NavTabs/Admin";
  import {mapGetters} from "vuex";
  import Notes from "@/components/Client/NavTabs/Notes";
  import OwnerRawDialog from "@/components/Client/OwnerRawDialog";
  import LeaveUploadsConfirm from "@/components/LeaveUploadsConfirm";
 import { mapState } from 'vuex';

  import PPPRoundDialog from "@/components/PPPRoundDialog";
  import CreateClientDialog from "@/components/Client/CreateClientDialog";
  import ClientDeleteDialog from "@/components/Client/ClientDeleteDialog";
  import ClientsService from "../../services/clients.service";
  import Downloads from "./NavTabs/Downloads";
  import Tool from "./NavTabs/Tool";
  import PeoUploads from "@/components/Client/NavTabs/PeoUploads.vue";
	export default {
		name: 'ProfileClientDialog',
		components: {
      Status,
      Downloads,
      Tool,
      OwnerRawDialog,
      LeaveUploadsConfirm,
      Notes,
      Admin,
      ClientDragAndDropRawFile,
      OwnerDialog,
    Uploads,
    DialogRightAlign,
    ClientDragAndDropCSVFile,
    Submission,
    DeleteConfirm,
      PPPRoundDialog,
      CreateClientDialog,
      ClientDeleteDialog,
      Downloads,
      PeoUploads

},

		props: {
			showRightDialogChosenClient: {
				type: Boolean,
				default: false
			},
			clientProp: {
				type: Object
			},
      roundId: {
        type: Number
      }
		},

		data: () => ({
      loading:false,
      activeClient:null,
      tabName: "PROFILE",
      active_tab: 1,
      showDialogDelete:false,
      dialogKeyDelete:0,
      clientInfo:false,
      clientInfoKey:42,
      showRightRoundDialog: false,
      dialogRoundKey: 300,
      currentRound:0,
      navTabOne: true,
      leaveUploads: false,
      navTabTwo: false,
      navTabThree: false,
      navTabFour: false,
      navTabFive: false,
      navTabSix: false,
      navTabSeven: false,
      navTabEight: false,
      navTabNine: false,
      generationInProgress: false,
      client: {},
      rounds: [],
      owners: [],
      uploadedFileNotSaved: false,
      lastClickedNav: null,
      employees: [],
      isDraft: false,
      showRightDialogUploadCSV: false,
      keyUploadCSV: 0,
      snowDeleteDialog:false,
      showOwnersSelectDialog: false,
      showOwnersSelectDialogKey: 0,
      idForChild: 0,
      showRightDialogUploadRaw: false,
      isOwnerPresented: true,
      showRightDialogOwner: false,
      hasOwners: false,
      keyOwn: 0,
      keyUploadRaw: 0,
      user: null,
      switchClientOnHold: false,
      business_type:1,
			taxSocialSecurity: [
				{
					"quarter": 4,
					"year": 2020,
					"wages": null
				},

				{
					"quarter": 1,
					"year": 2021,
					"wages": null
				},
				{
					"quarter": 2,
					"year": 2021,
					"wages": null
				},
				{
					"quarter": 3,
					"year": 2021,
					"wages": null
				}
			],
			employeeCounts: [
				{
					"quarter": 4,
					"year": 2020,
					"count": null
				},

				{
					"quarter": 1,
					"year": 2021,
					"count": null
				},
				{
					"quarter": 2,
					"year": 2021,
					"count": null
				},
				{
					"quarter": 3,
					"year": 2021,
					"count": null
				}
			],
      ercRefundTotals: [{ refund_total: 0 }, { refund_total: 0 }, { refund_total: 0 }, { refund_total: 0 }],
			isTaxDisabled: false,
			isEmployeeDisabled: false,
			isErcRefundDisabled: false,
      showRawOner: false,
      showRawOnerKey: 50,
			hints: ["2020", "2021-Q1", "2021-Q2", "2021-Q3"],
			generatedPackages: [],
      usersRole: null,
      uploadedData: [],
      roundsDataLoaded: false,
      rawOwnersString: "",
      isGetOwnersSubmitted: false,
      rawOnersId:0,
      originalOwners: [],
      round: 1,
      currentTab: 1,
      openPeoDocs:false,
      openPeoDocsKey:false,
		}),
    watch: {
      openProfileTab(newValue, oldValue){
        if (newValue == true){
          this.changeNavTab(1)
          let objDiv = document.getElementsByClassName("my-custom-dialog");
          objDiv[0].scrollTop += objDiv[0].scrollHeight
          this.$func.popMessage('You are all set! \n Click on "Generate Package" Button to get your package','success')
        }
      },
      openAdminTab(newValue, oldValue){
        if (newValue == true){
          this.changeNavTab(6)
        }
      },

      navTabTwo(value){
        if (value===true){
          this.navTabFour = false
          this.navTabThree = false
          this.navTabOne = false
          this.navTabFive = false
          this.navTabSix = false
          this.navTabSeven = false
          this.navTabEight = false
          this.navTabNine = false
        }

      },
      navTabOne(value){
        if (value===true){
          this.navTabFour = false
          this.navTabThree = false
          this.navTabTwo = false
          this.navTabFive = false
          this.navTabSix = false
          this.navTabSeven = false
          this.navTabEight = false
          this.navTabNine = false
        }
      },
      navTabThree(value){
        if (value===true){
          this.navTabFour = false
          this.navTabTwo = false
          this.navTabOne = false
          this.navTabFive = false
          this.navTabSix = false
          this.navTabSeven = false
          this.navTabEight = false
          this.navTabNine = false
        }
      },
      navTabFour(value){
        if (value===true){
          this.navTabTwo = false
          this.navTabThree = false
          this.navTabOne = false
          this.navTabFive = false
          this.navTabSix = false
          this.navTabSeven = false
          this.navTabEight = false
          this.navTabNine = false
        }
      },
      navTabFive(value){
        if (value===true){
          this.navTabTwo = false
          this.navTabThree = false
          this.navTabOne = false
          this.navTabFour = false
          this.navTabSix = false
          this.navTabSeven = false
          this.navTabEight = false
          this.navTabNine = false
        }
      },
      navTabSix(value){
        if (value===true){
          this.navTabTwo = false
          this.navTabThree = false
          this.navTabOne = false
          this.navTabFour = false
          this.navTabFive = false
          this.navTabSeven = false
          this.navTabEight = false
          this.navTabNine = false
        }
      },
      navTabSeven(value){
        if (value===true){
          this.navTabTwo = false
          this.navTabThree = false
          this.navTabOne = false
          this.navTabFour = false
          this.navTabFive = false
          this.navTabSix = false
          this.navTabEight = false
          this.navTabNine = false
        }
      },
      navTabEight(value){
        if (value===true){
          this.navTabTwo = false
          this.navTabThree = false
          this.navTabOne = false
          this.navTabFour = false
          this.navTabFive = false
          this.navTabSix = false
          this.navTabSeven = false
          this.navTabNine = false
        }
      },
      navTabNine(value){
        if (value===true){
          this.navTabTwo = false
          this.navTabThree = false
          this.navTabOne = false
          this.navTabFour = false
          this.navTabFive = false
          this.navTabSix = false
          this.navTabSeven = false
          this.navTabEight = false
        }
      },
      showRawOner(val){
        if (val === false){
          this.getRawOwners()
        }
      }
    },
		methods: {
      switchOnHold(){
        this.patchClientOnHold(this.client.id, this.switchClientOnHold)
      },

      startTourGuide() {

        this.$store.dispatch('tour/changeShowTourGuideRibbon', false);

        this.$store.dispatch('tour/changeIsTourGuideActive', true);
        this.$tours['myTour'].start();

      },
      myCustomPreviousStepCallback(currentStep) {

        document.body.classList.remove('v-tour--active');
      },

      myCustomNextStepCallback(currentStep) {
      },

      myCustomSkipCallback() {

        this.tourGuideFinish();
      },

      myCustomFinish() {

        this.tourGuideFinish();
        this.$tours['myTour'].finish();
      },

      tourGuideFinish() {

        this.$store.dispatch('tour/changeIsTourGuideActive', false);
        this.$store.dispatch('tour/changeShowTourGuideRibbon', true);

        // UserService.setActiveUserTourGuide();

      },
      getCurrentClient(id) {
        return ClientService.getOneClient(id)
            .then((data) => {
              // this.clientProp = data;
              this.client = data
              this.activeClient = this.client.active == 1 ? true : false
              this.select = null;
              this.clientId = Number(this.clientProp.id);
              // this.showResults = false;
              // this.inputSearch = null;
            })
            .catch((error) => {

              this.$store.dispatch('snackbar/showSnackbarMessage', {
                message: `${error.response.data.error}. Please, try again.`,
                duration: 4000,
                mode: 'fail'
              });
            });
      },
      handleUploadsNotSaved(value){
        if (value === true){
          this.uploadedFileNotSaved = true;
        }else if(value === false){
          this.uploadedFileNotSaved = false;
        }
      },
      onClickChild({closeWindow,acceptedDelete,id}){
      this.snowDeleteDialog = closeWindow;
      if(acceptedDelete){
        this.deleteRound(this.idForChild)
      }
      },
      onLeaveUploads({returnToUploads,acceptedDelete}){
        if(returnToUploads){
          this.leaveUploads = false
          switch (this.lastClickedNav) {
            case 1:
              this.navTabOne = true
              break;
            case 2:
              this.navTabTwo = true
              break;
            case 3:
              this.navTabThree = true
              break;
            case 4:
              this.navTabFour = true
              break;
            case 5:
              this.navTabFive = true
              break;
            case 7:
              this.navTabSeven = true
              break;
            case 8:
              this.navTabEight = true
              break;
            case 9:
              this.navTabNine = true
              break;
          }
        }else {
          this.leaveUploads = false
        }
      },
      changeNavTab(tab){
        if(this.leaveUploads === false && this.navTabTwo ===true && this.uploadedFileNotSaved === true) {
           this.leaveUploads = true
           this.uploadedFileNotSaved = false
           this.lastClickedNav = tab
          this.onLeaveUploads()
          }else {
          switch (tab) {
            case 1:
              this.navTabOne = true
              this.active_tab = 1
              this.tabName = "PROFILE"
              break;
            case 2:
              this.navTabTwo = true
              this.active_tab = 2
              this.tabName = "UPLOADS"
              break;
            case 3:
              this.navTabThree = true
              this.active_tab = 3
              this.tabName = "NOTES"
              break;
            case 4:
              this.navTabFour = true
              this.active_tab = 4
              this.tabName = "SUBMISSION"
              break;
            case 5:
              this.navTabFive = true
              this.active_tab = 5
              this.tabName = "DOWNLOADS"
              break;
            case 6:
              this.navTabSix = true
              this.active_tab = 6
              this.tabName = "ADMIN"
              break;
            case 7:
              this.navTabSeven = true
              this.active_tab = 7
              this.tabName = "STATUS"
              break;
            case 8:
              this.navTabEight = true
              this.active_tab = 8
              this.tabName = "TOOL"
              break;
            case 9:
              this.navTabNine = true
              this.active_tab = 9
              this.tabName = "PEO DOCUMENTS"
              break;
        }}
        },


      popUp(id,butNum){
        
        if(butNum === 2){
          this.PPP="Clear PPP 2"
          this.round=2
        }else{
          this.PPP="Clear PP1"
          this.round=1
        }
        this.idForChild=id;
        this.snowDeleteDialog=true;
      
      },
      patchClient(){
        this.loading = true
        ClientService.editClient(this.client.id, {active:this.activeClient == true ? 1 : 0})
            .then(()=>this.loading=false).catch(()=>this.loading=false)
      },
			deleteRound(id) {
				ClientService.deleteRound(id)
					.then(() => {
						this.getAllRounds();
						this.$store.dispatch('snackbar/showSnackbarMessage', {
							message: `Round removed successfully`,
							duration: 4000,
							mode: 'success'
						});
					});
			},

			openRoundInfo(number, round) {

        this.showRightRoundDialog = true;
        this.dialogRoundKey++;
        this.currentRound = number;
			},

			// openClientProfileUploadCSVDialog() {
			// 	this.showRightDialogUploadCSV = true;
			// 	this.keyUploadCSV++;
			// },

      openOwnerDialog() {
        this.showRightDialogOwner = true;
        this.keyOwn++;
      },

      getUploadedData() {
        this.$axios.get(`clients/${this.client.id}/uploads/payroll/raw`,
        ).then(res => {
          if (res.data.length > 0) {
            this.uploadedData = res.data
          }
        }).catch(error => {
          this.$func.popMessage(`${error.response.data.error}`, 'fail')
        })
      },


			getAllRounds() {
				return ClientService.getRounds({clientId: this.client.id})
					.then((data) => {
						this.rounds = data;
            this.roundsDataLoaded = true
					})
					.catch((error) => {
						this.rounds = [];
            this.roundsDataLoaded = true
						this.$store.dispatch('snackbar/showSnackbarMessage', {
							message: `${error.response.data.error}`,
							duration: 4000,
							mode: 'fail'
						});
					});
			},

			uploadCSV() {

			},

			addInfo() {

			},

      async generatePackage() {
        let typeGeneration = this.isDraft ? 'final' : 'draft';

        this.generationInProgress = true;
        ClientService.postPackage(this.client.id,{ "request": typeGeneration })
            .then(res=>{
              this.getAllPackages()
              this.generationInProgress = false;
              this.$store.dispatch('snackbar/showSnackbarMessage', {
                message: `Package generation scheduled successfully.`,
                duration: 4000,
                mode: 'success'
              });
            }).catch(()=>this.generationInProgress=false)

        // PackageService.generatePackage(this.client.id, typeGeneration).then(res=>{
        //   this.getAllPackages();
        //   this.generationInProgress = false;
        // });

      },

			goToEdit() {
				this.clientInfo = true;
        this.clientInfoKey++;
			},

      getAllClients() {
        return ClientService.getClients()
            .then((data) => {

              this.clients = data;
            })
            .catch((error) => {

              this.$store.dispatch('snackbar/showSnackbarMessage', {
                message: `${error.response.data.error}. Please, try again.`,
                duration: 4000,
                mode: 'fail'
              });
            });
      },
      openDeleteDialog() {
        this.showDialogDelete = true;
        this.dialogKeyDelete++;
      },
			closeDialog() {
				this.$emit('update:clientProp', {});
				this.$emit('update:showRightDialogChosenClient', false);
        this.removeHashLocation(this.client.id)
			},

      removeHashLocation(){
        history.pushState(
            {},
            null,
            this.$route.path
        )
      },

			getAllEmployees() {
				return OwnersService.getAllEmployees({clientId: this.client.id})
					.then((data) => {
						this.employees = data;
						this.isOwnerPresented = this.employees.some(employee => employee.owner == 1)
					})
			},

			// getAllOwners() {
			// 	return OwnersService.getOwners({clientId: this.client.id})
			// 		.then((data) => {
			// 			this.owners = data.owners;
			// 			this.hasOwners = data.has_owners;
			// 		})
			// 		.catch((error) => {
      //
			// 			this.$store.dispatch('snackbar/showSnackbarMessage', {
			// 				message: `${error.response.data.error}. Please, try again.`,
			// 				duration: 4000,
			// 				mode: 'fail'
			// 			});
			// 		});
			// },

			// saveOwners(ownersData) {
			// 	return OwnersService.deleteOwner({clientId: this.client.id})
			// 		.then(() => {
			// 				OwnersService.createOwner(this.client.id, ownersData)
			// 					.then(() => {
			// 						this.$store.dispatch('snackbar/showSnackbarMessage', {
			// 							message: `Owners updated successfully`,
			// 							duration: 4000,
			// 							mode: 'success'
			// 						});
      //
			// 						// this.getAllOwners();
			// 						this.getAllEmployees();
			// 					})
			// 			}
			// 		)
			// },

			getUpdatedInfo() {
				this.$emit('getCurrentClient', this.client.id);
			},

			openSelectOwnersDialog() {
				this.showOwnersSelectDialog = true;
				this.showOwnersSelectDialogKey++;
			},

      getRawOwners(){
        ClientService.getRawOwners(this.client.id).then(res=>{
          this.isGetOwnersSubmitted = true;
          this.rawOnersId = res.id;
          const ownersArr = res.owner_family_list.split("\n");
          this.owners = ownersArr;
          this.rawOwnersString = res.owner_family_list;
        }).catch(err => {
          this.$func.popMessage(err.res.error,"danger")
          this.isGetOwnersSubmitted = false;
        })
      },
      getOwners(){
        return OwnersService.getOwners({clientId: this.client.id})
            .then((data) => {
              this.originalOwners = data.has_owners;
            })
            .catch((error) => {
              this.$func.popMessage(`${error.response.data.error}`, 'fail')
            });
      },
      openSelectOwnersRawDialog() {
        this.showRawOner = true;
        this.showRawOnerKey++;
      },

			getWages() {
				return ClientService.getWages(this.client.id)
					.then((res) => {
						// if (res.wages[2021].length > 0) {
						// 	this.taxSocialSecurity = res.wages;
						// }
            

            res.wages.forEach((e, index) => {
              this.taxSocialSecurity[index].wages = res.wages[index].wages;
            })


						// this.wages.sort((a,b) => (a.quarter > b.quarter) ? 1 : ((b.quarter > a.quarter) ? -1 : 0));
						// this.wages = res.wages;
					});
			},


      openRawUploadDialog() {
        this.showRightDialogUploadRaw = true;
        this.keyUploadRaw++;
      },

			saveTaxSocialSecurity() {
				let wagesToPost = Object.assign({}, this.taxSocialSecurity.filter(tax => tax.wages != null))
				return ClientService.deleteWages(this.client.id)
					.then((res) => {
						return ClientService.saveWages(this.client.id, Object.values(wagesToPost))
							.then((res) => {
								// if (res.wages[2021].length > 0) {
								// 	this.taxSocialSecurity = res.wages;
								// }
								//
								// taxSocialSecurity[0].wages =  res.wages[0].wages;
								// this.wages.sort((a,b) => (a.quarter > b.quarter) ? 1 : ((b.quarter > a.quarter) ? -1 : 0));
								// this.wages = res.wages;
							});
					});
			},

			getEmployeePerQuarter() {
				return ClientService.getEmployeesAmounts(this.client.id)
					.then((res) => {
						this.employeeCounts[0].count = res[0].count;
						this.employeeCounts[1].count = res[1].count;
						this.employeeCounts[2].count = res[2].count;
						this.employeeCounts[3].count = res[3].count;
					});
			},

      patchClientOnHold(id,isOnHold){
        const data={"ClientOnHold": isOnHold}
        return ClientService.patchClientOnHold(id,data)
					.then((res) => {
					}).catch((error) => {
              this.$func.popMessage(`${error.response.data.error}`, 'fail')
						});
      },

			saveEmployeesAmounts() {
				let employeeToPost = Object.assign({}, this.employeeCounts.filter(employee => employee.count != null));

				return ClientService.deleteEmployeesAmounts(this.client.id)
					.then((res) => {
						ClientService.saveEmployeesAmounts(this.client.id, Object.values(employeeToPost))
							.then((res) => {
							});
					});
			},

			async getAllPackages() {
				return PackageService.getPackages(this.client.id)
					.then((data) => {
						if (data) {
							this.generatedPackages = data;
						}
					});
			},

			getPackageUntilGenerated(packageCount) {
				this.intervalIdTimeout = window.setInterval(async () => {

					await this.getAllPackages();

					if (this.generatedPackages.length > packageCount) {
            this.generationInProgress = false;
						window.clearInterval(this.intervalIdTimeout);
					}
				}, 1000);
			},

			deletePackage(id) {
				return PackageService.deletePackage(id)
					.then((res) => {
						this.$store.dispatch('snackbar/showSnackbarMessage', {
							message: `Package was deleted successfully.`,
							duration: 4000,
							mode: 'success'
						});
					});
			},


    shipPackage(id) {
      return PackageService.shipPackage(id)
        .then((res) => {
          this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: `Package was deleted successfully.`,
            duration: 4000,
            mode: 'success'
          });
        })
    },
    },
		computed:  {
			roundOne() {
				return (this.rounds.filter((round) => round.number == 1))[0];
			},

			roundTwo() {
				return (this.rounds.filter((round) => round.number == 2))[0];
			},
      ...mapGetters(
          {
            showTourGuide: 'tour/getOpenMenuForTourGuide',
            showTourGuideActive: 'tour/getIsTourGuideActive',
            showTourGuideRibbon: 'tour/getShowTourGuideRibbon',
            currentUser: 'auth/getCurrentUser',
            openAdminTab: 'tour/getOpenAdminTab',
            openProfileTab: 'tour/getOpenProfileTab',
          },
      ),
		},

		filters: {
			// formatToUSD: function (value) {
			// 	const formatter = new Intl.NumberFormat('de-DE', {
			// 		style: 'currency',
			// 		currency: 'USD',
			// 		minimumFractionDigits: 2
			// 	});
			//
			// 	return formatter.format(value);
			// }
		},
		async created() {
			this.client = this.clientProp;
      this.business_type=this.client.business_type
      this.activeClient = this.client.active == 1 ? true : false
      this.switchClientOnHold=this.clientProp.on_hold
      await AuthService.getCurrentUser(this.currentUser.current_user_id)
          .then(res=>{
            this.usersRole = res.data.role_id
            this.user = res.data
          })
		},

		mounted() {
			this.$nextTick(() => {
				// this.getAllOwners();
				this.getAllEmployees();
				this.getAllRounds();
				this.getWages();
				this.getEmployeePerQuarter();
				this.getAllPackages();
			});
      this.getOwners()
      this.getRawOwners()
      this.getUploadedData()
      window.addEventListener('keyup', function(ev) {
        if (ev.keyCode == 4){
          self.closeDialog()

        }
      });
    },
    beforeDestroy () {
      document.removeEventListener("keyup", this.closeDialog);
    }
	}
</script>

<style lang="scss">

	.owner-box {
		margin-top: 13px;
	}

	.owner-check-box {
		margin-top: 13px;
	}

	.no-csv-message {
		color: white;
		padding-top: 10px;
		padding-left: 10px;
		padding-bottom: 10px;
		background-color: #FF5252;
		border: 1px solid gray;
	}

	.switchDraftOrFinal {
		margin-left: 5px !important;
		display: inline-block !important;
	}

	.draft-span {
		font-size: 15.5px !important;
	}

	.theme--light.v-input--is-disabled {
		color: rgba(0, 0, 0, 0.6);
	}

	::v-deep .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
		color: rgba(0, 0, 0, 0.87) !important;
	}

	::v-deep .v-text-field__slot input::-webkit-outer-spin-button,
	::v-deep .v-text-field__slot input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0 !important;
	}

	/* Firefox */
	::v-deep .v-text-field__slot input[type=number] {
		-moz-appearance: textfield !important;
	}

  .v-progress-circular {
    display: block;
    margin: 0 auto;
  }

  .highlight-hover:hover {
    background-color: yellow;
  }


  .navTabsPlacement{
    font-size: 0.85rem;
  }
  ::v-deep .theme--light.v-card{
    background-color: transparent !important;
  }
  ::v-deep .theme--light.v-sheet{
    background-color: transparent !important;
  }
  #navMenu{
    background-color: #FFFFFF !important;
  }
  #clientOnHold .v-input__slot{
    display: flex;
    flex-direction: row-reverse;
  }
  #clientOnHold .v-label{
    margin-right:10px;
  }

</style>
