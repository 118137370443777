<template>
  <v-dialog
      v-model="alertsOpened"
      content-class="my-custom-dialog full-height"
      max-width="700px"
  >
    <v-card class="d-flex flex-column" style="min-height: 100vh">
      <v-card-title style="background: #263b4f">
        <v-toolbar-title class="white--text">Alerts</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="header-button">
          <v-btn color="white" @click="close">Close</v-btn>
        </div>
      </v-card-title>
         <div class="d-flex justify-space-between" >
           <div
               :class="{ activeMenuItem: activetab === item.id }"
               class="menuItem"
               style="width: 100%;padding: 0.5rem 0px;border: #263b4f 1px solid;margin: auto"
               v-for="item in menuItems"
               :key="item.id"
               @click="changeActiveTab(item.id)">
             <span style="display: table;margin: 0 auto;"
             >{{ item.title }}</span>
             <span class="button__badge" v-if="item.count">{{item.count}}</span>
           </div>
         </div>
      <div >
        <NotifUploads
            v-if="showUploads"
            :key="uploadsKey"/>
        <NotifNotes
            v-if="showNotes"
            :key="notesKey"/>
        <NotifSigned
            v-if="showSigned"
            :key="signedKey"/>
      </div>
    </v-card>


  </v-dialog>
</template>

<script>
import NotificationService from "@/services/notification.service";
import NotifSigned from "@/components/Client/NotificationNavTabs/NotifSigned";
import NotifUploads from "@/components/Client/NotificationNavTabs/NotifUploads";
import NotifNotes from "@/components/Client/NotificationNavTabs/NotifNotes";
export default {
  name: "Notifications",
  components: {NotifSigned, NotifNotes, NotifUploads},
  data() {
    return {
      showUploads:false,
      showSigned:false,
      showNotes:false,
      uploadsKey:13,
      signedKey:33,
      notesKey:23,
      activetab:0,
      alertsOpened: false,
      menuItems: [
        {title:'Uploads',id:1, count:null},
        {title:'Notes',id:2, count:null},
        {title:'Signed 941x',id:3, count:null},
      ],
      notIfNotesCount:null
    };
  },
  watch:{
    alertsOpened(val){
      if (!val) {
        this.showNotes =false;
        this.showUploads=false;
        this.showSigned=false;
      }
    },
    activetab(tab){
      switch (tab){
        case 1:
          this.showUploads =true;
          this.uploadsKey++;
          this.showNotes = false;
          this.showSigned = false
          break;
        case 2:
          this.showNotes =true;
          this.notesKey++;
          this.showUploads=false;
          this.showSigned=false;
          break;
        case 3:
          this.showSigned =true;
          this.signedKey++;
          this.showUploads=false;
          this.showNotes=false;
          break;
      }
    }
  },
  props: {
    unreadNotifications: {
      type: Object,
      default: {}
    },
    showNotif: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    changeActiveTab(tab){
      this.activetab=tab
    },
    close(){
      this.alertsOpened = false
    },
    // notIfNotesAlerts(count){
    //   console.log('notIfNotesAlerts count',count)
    //   // this.notIfNotesCount=count
    //   this.menuItems[1].count=count
    //   console.log("this.menuItems.count",this.menuItems[1].count)
    // }
  },
  beforeDestroy() {
  },
  created() {
    this.activetab = 1
    this.alertsOpened = this.showNotif
    this.menuItems[0].count = this.unreadNotifications.uploads_total
    this.menuItems[1].count = this.unreadNotifications.notes_total
    this.menuItems[2].count = this.unreadNotifications.signed_total
    // NotificationService.getNotifs('uploads')
    //     .then(res=>{
    //       this.menuItems[0].count=res.length
    //     })
    // NotificationService.getNotifs('notes')
    //     .then(res=>{
    //       this.menuItems[1].count=res.length
    //     })
    // NotificationService.getNotifs('signed')
    //     .then(res=>{
    //       this.menuItems[2].count=res.length
    //     })
  }
}
</script>

<style lang="scss" scoped>

.activeMenuItem{
  background: #263b4f;
  color: #FFFFFF;
}
.menuItem{
  cursor: pointer;
}
.menuItem:hover{
  box-shadow: 0px 2px #000000;

  background: #263b4f;
  color: #FFFFFF;
}
::v-deep  .v-dialog {
  margin: 0px;
  z-index: 9999;
}

.v-dialog__content {
  z-index: 99999999;
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {

  max-height: 100% !important;
}
.button__badge { 
    background-color: #f34b4b;
    border-radius: 30px;
    color: white;
    padding: 1px 10px;
    font-size: 13px;
    font-weight: bold;
    float:right;
    margin-right: 20px;
    margin-top: -23px;
  }
</style>
