<template>

  <div>
    <div class="d-flex justify-center">
      <v-btn color="#263B4F" class="white--text" :disabled="!selected.length" @click="moveToFiled" >Move to Ready to File</v-btn>
    </div>
    <div class="d-flex justify-center">
    <v-radio-group
      v-model="radioRow"
      row
    >
      <v-radio
        label="943"
        value="radio-1"
      ></v-radio>
      <v-radio
        label="944"
        value="radio-2"
      ></v-radio>
    </v-radio-group>
  </div>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="client_id"
        class="elevation-1 mt-5"
        hide-default-footer
        :loading="loadingTable"
        loading-text="Loading... Please wait"
        show-select
        v-model="selected"
    ></v-data-table>
    <h3 v-if="items.length" class="mt-4 ml-2">Total client count: {{items.length}}</h3>

  </div>

</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "ReadyToFinal",
  data(){
    return{
      singleSelect: false,
      selected: [],
      headers: [
        { text: 'Client', value: 'client_name' },
        { text: 'Days Ready', value: 'days_in_ready' },
        { text: 'Attorney', value: 'attorney' },
        { text: 'Patriot Staff', value: 'patriot_stuff' },
      ],
      items:[],
      loadingTable:false,
      radioRow:'' ,
    }
  },
  watch:{
      radioRow(newChoice, oldChoice){
        const payload= {
        business_type:'941'
      }
      if (newChoice=='radio-1'){
        payload.business_type='943'
      } else if (newChoice=='radio-2') {
        payload.business_type='944'
      }
      console.log('payload',payload)
        this.getReadyTofinal(payload)
      }
    },
  methods:{
    moveToFiled(){
      const model = this.selected.map(e=>e.client_id)
      ClientsService.moveToFiled(model).then(()=>this.getReadyTofinal())
    },
    getReadyTofinal(x) {
      this.loadingTable=true
      ClientsService.getReadyToFinal(x)
          .then(res => {
            this.loadingTable=false
            this.items = res
          }).catch(() => {})
    },
  },
  created() {
    this.getReadyTofinal()
  }
}
</script>

<style scoped>

</style>
