import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/HomeDefault.vue'
import AppContent from "../AppContent";
import SubmissionsProgress from  '../views/Reports/SubmissionsProgress.vue'
Vue.use(VueRouter)

import guest from './middleware/guest'
import auth from './middleware/auth'

import AuthLogin from "../views/Auth/AuthLogin";

import store from "../store"

import middlewarePipeline from "./middlewarePipeline";
import rolesCheck from './middleware/roles'
import { roles } from '@/constants'

// import Reporting from "../views/Reporting";
import Users from "../views/Users";
import Clients from "../views/Clients";
import Impersonate from "../views/Impersonate";
import Permission from "../views/Permissions";
import ActivateAccount from "@/views/ActivateAccount";
import ResetPassword from "@/views/ResetPassword";
import Entities from '../views/Entities'
import Pod from "@/views/Pod";
import Returns from "@/views/Returns";
import CustomReportsTemplate from "@/views/Fees";
import Maintenance from "@/views/Maintenance";
import EmployeeRetention from "@/views/EmployeeRetention";
import Overpayments from "@/views/Overpayments";
import Deposits from "@/views/Deposits";
import Reconciliation from "@/views/Reconciliation";
import Payments from "@/views/Payments";
import HomeFactory from "@/views/HomeFactory";
import Leads from "@/views/Leads";
import Documents from "@/views/Reports/Documents.vue";
import Reports from "@/views/Reports/Reports.vue";
import ReadyToSanitize from "@/views/Reports/ReadyToSanitize.vue";
import ReadyToFinal from "@/views/Reports/ReadyToFinal.vue";
import ReadyToFile from "@/views/Reports/ReadyToFile.vue";
import SanitizeClient from "@/views/SanitizeClient.vue";
import ReadyToReconcile from "@/views/Reports/ReadyToReconcile.vue";
import PageBuilder from "@/views/PageBuilder.vue";
import Resources from '@/views/Resources.vue'
import UnreviwedUploads from "@/views/Reports/UnreviwedUploads.vue";
import Orphaned from "@/views/Orphaned.vue"
import SanitizationQueue from "@/views/SanitizationQueue.vue"
import ReadyToShip from "@/views/ReadyToShip.vue"
import DownlineReport from "@/views/Reports/DownlineReport.vue";
import UnassignedClients from "@/views/UnassignedClients.vue";
import Performance from "@/views/Performance.vue";
import SignatureVerification from "@/views/SignatureVerification.vue";
import PeoClients from "@/views/Reports/PeoClients.vue";
import ClientPerformance from "@/views/Reports/ClientPerformance.vue";

const routes = [
	{
		path: '/',
		component: AppContent,
		children: [
			{
				path: '',
				redirect: '/dashboard',
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/dashboard',
				component: HomeFactory,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/returns',
				component: Returns,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/reports',
				component: Reports,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/permissions',
				component: Permission,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/users',
				component: Users,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/pods',
				component: Pod,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/clients',
				component: () => import('@/views/Clients'),
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/impersonate',
				component: Impersonate,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/Entities',
				component: Entities,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/fees',
				component: CustomReportsTemplate,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/finances',
				component: EmployeeRetention,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/payments',
				component: Payments,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/resources',
				component: Resources,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/orphaned',
				component: Orphaned,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/overpayments',
				component: Overpayments,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/reconciliation',
				component: Reconciliation,
				meta: {
					middleware: [auth]
				}
			},

			{
				path: '/deposits',
				component: Deposits,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/leads',
				component: Leads,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/required-documents',
				component: Documents,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/ready-to-sanitize',
				component: ReadyToSanitize,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/ready-for-final',
				component: ReadyToFinal,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/ready-to-reconcile',
				component: ReadyToReconcile,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/:id/jobs/:id',
				component: SanitizeClient,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/queues/sanitize',
				component: SanitizationQueue,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/queues/ready-to-ship',
				component: ReadyToFile,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/queues/ready-to-ship/:id',
				component: ReadyToShip,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/page-builder/:id',
				component: PageBuilder,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/unreviewed-documents',
				component: UnreviwedUploads,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/performance',
				component: Performance,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/downline',
				component: DownlineReport,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/unassigned-clients',
				component: UnassignedClients,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/queues/unverified-signatures',
				component: SignatureVerification,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: 'peo-clients',
				component: PeoClients,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/pending-payments',
				component: SubmissionsProgress,
				meta: {
					middleware: [auth]
				}
			},
			{
				path: '/clients-performance',
				component: ClientPerformance,
				meta: {
					middleware: [auth]
				}
			}
		],
	},

	{
		path: '/login',
		component: AuthLogin,
		meta: {
			middleware: [guest]
		}
	},
	{
		path: '/activate/*',
		component: ActivateAccount
	},
	{
		path: '/reset-password/*',
		component: ResetPassword
	},
	{
		path: '/maintenance',
		component: Maintenance
	}
	// TO DO
	// {path: '/404', component: PageNotFound},
	// {path: '*', redirect: '/404'},
]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {

	if (!to.meta.middleware) {
		return next()
	}

	const middleware = to.meta.middleware;

	const context = {
		to,
		from,
		next,
		store
	};

	return middleware[0]({
		...context,
		nextMiddleware: middlewarePipeline(context, middleware, 1)
	});
});

export default router
