<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-btn color="primary" @click="download">
      Download CSV
    </v-btn>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-5"
    ><template v-slot:item.legal_name="{ item }">
      <div style="cursor: pointer" @click="goToClient(item.client)">
        {{item.legal_name}}
      </div>
    </template>

      <template v-slot:item.trade_name="{ item }">
        <div style="cursor: pointer" @click="goToClient(item.client)">
          {{item.trade_name}}
        </div>
      </template></v-data-table>
    <ProfileClientDialog
        v-if="showRightDialogChosenClient"
        :key="dialogKeyChosenClient"
        :showRightDialogChosenClient.sync="showRightDialogChosenClient"
        :clientProp="clientProp"
        @openClientProfileDialog="openClientProfileDialog"

    />
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
import ClientService from "@/services/clients.service";
export default {
  name: "SubmissionsProgress",
  components: {ProfileClientDialog},
  data:()=>({
    showRightDialogChosenClient:false,
    dialogKeyChosenClient:33,
    clientId:'',
    clientProp:{},
    loading:false,
    selectedClient:{},
    headers: [
      {        text: 'ID',align: 'start',sortable: false,value: 'client',},
      { text: 'Legal name', value: 'legal_name' },
      { text: 'Trade name', value: 'trade_name' },
      { text: 'Submission Date', value: 'submission_dd' },
      { text: 'Fedex', value: 'fedex' },
      { text: 'Client Credits Q4', value: 'client_credits_Q4_formatted' },
      { text: 'Client Payments Q4', value: 'client_payment_Q4_formatted' },
      { text: 'Status Q4', value: 'completed_Q4' },
      { text: 'Client Credits Q1', value: 'client_credits_Q1_formatted' },
      { text: 'Client Payments Q1', value: 'client_payment_Q1_formatted' },
      { text: 'Status Q1', value: 'completed_Q1' },
      { text: 'Client Credits Q2', value: 'client_credits_Q2_formatted' },
      { text: 'Client Payments Q2', value: 'client_payment_Q2_formatted' },
      { text: 'Status Q2', value: 'completed_Q2' },
      { text: 'Client Credits Q3', value: 'client_credits_Q3_formatted' },
      { text: 'Client Payments Q3', value: 'client_payment_Q3_formatted' },
      { text: 'Status Q3', value: 'completed_Q3' },

    ],
    items:[],
  }),
  methods:{
    downloadURI(dataurl, filename) {
      const link = document.createElement("a");
      link.href = dataurl;
      link.download = filename;
      link.click();
    },
    download(){
      this.loading=true
      ClientsService.getPendingPaymentsCSV()
          .then(res=>{
            console.log(res)
            this.downloadURI(res.url,res.original_file_name)
            this.loading=false
          })
    },
    goToClient(id){
      this.loading = true
      return ClientService.getSubmissionsProgressData(id)
          .then((data) => {
            this.clientProp = data;
            this.openClientProfileDialog();
            this.clientId = Number(this.clientProp.id);
            // this.showResults = false;
            // this.inputSearch = null;
          })
          .catch((error) => {
            this.loading = false
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: `${error.response.data.error}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },
    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false
      this.showRightDialog = false;
    },
    getData(){
      this.loading=true
      ClientsService.getSubmissionsProgressData()
          .then(res=> {
            this.items = res
            this.loading=false
          })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
