import $axios from '../plugins/axios'

class NotificationService {
    getNotifs(type) {
        return $axios.get(`/notifications/${type}`)
            .then((res) => {
                return res.data;
            });
    }

	getAllNotfications() {
		return $axios.get(`/notifications`)
			.then((res) => {
				return res.data;
			});
	}
    closeNotif(id) {
		return $axios.patch(`/notifications/${id}/read`)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}
    snoozeNotif(id, filter) {
		return $axios.patch(`/notifications/${id}/snooze`,filter)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}
}

export default new NotificationService();
