import $axios from '../plugins/axios'

class PackageService {

	createPackage(data) {
		return $axios.get(`/packages`, data)
			.then((res) => {
				return res.data;
			});
	}

	getPackages(id) {
		return $axios.get(`/clients/${id}/packages`)
			.then((res) => {

				return res.data;
			});
	}

	generatePackage(id, typeGeneration) {
		return $axios.post(`/clients/${id}/packages`, {"request": typeGeneration})
			.then((res) => {
				// return res.data;
			});
	}

	deletePackage(id) {
		return $axios.delete(`/packages/${id}`,)
			.then((res) => {
				// return res.data;
			});
	}

	shipPackage(id) {
		return $axios.put(`/packages/${id}`,)
			.then((res) => {
				// return res.data;
			});
	}
}

export default new PackageService();
