<template>
  <div style="width: 100%" class="d-flex pa-2">
    <div class="d-flex flex-column">
      <h3 style="font-size: 0.8rem;max-width: 430px" class="text-left" :maxlength="4">{{ file.original_file_name }}</h3>

      <h4 style="font-weight: 500;font-size: 12px;opacity: 70%" class="text-left">Uploaded by :
        <template v-if="file.uploaded_by == currentUser.id">You</template>
        <template v-else>{{ file.uploaded_by_name }}</template>
        <span class="ml-5">{{ file.created_on }}</span>
      </h4>
    <div class="text-start" v-if="isReviewed">
      <v-chip color="green" outlined style="margin: 2px" v-if="chip.document_status == 1" small v-for="chip in file.document_progress">{{chip.document_name}}</v-chip>
<!--      <span id="documentChip" v-for="chip in reqDocuments">{{chip.document_name}}</span>-->
    </div>
      <v-progress-linear
          v-show="file.uploadingFiles && !file.id"
          indeterminate
          color="cyan"
      ></v-progress-linear>
    </div>
    <v-spacer></v-spacer>
    <div class="d-flex flex-row justify-center align-center">
      <button class="ml-2" type="button"
              @click="resetUpload"
              title="Remove file"
              v-if="isReviewed && file.document_progress.length && currentUser.role_id == 1"
      >

        <v-icon color="red">
          mdi-restart
        </v-icon>
      </button>
      <a :download="file.original_file_name"
         :href="file.url"
         v-show="file.id"
         target="_blank"
         class="ml-2"
         type="button"
         title="Download file"
      >

        <v-icon>
          mdi-download
        </v-icon>
      </a>
      <template v-if="file.uploaded_by === currentUser.id">
        <button class="ml-2" type="button"
                @click="$emit('deleteUpload',file)"
                title="Remove file"
        >

          <v-icon>
            mdi-trash-can-outline
          </v-icon>
        </button>
      </template>
      <template v-else>
        <div style="margin-left: 32px"></div>
      </template>
      <v-menu v-model="menuOpened" offset-y class="overflow-y-auto" :close-on-content-click="false" v-if="currentUser.role_id == 1">
        <template v-slot:activator="{ on, attrs }">

          <v-icon v-bind="attrs"
                  v-on="on" style="cursor: pointer">mdi-dots-vertical
          </v-icon>
        </template>
        <v-list style="max-height: 750px" class="overflow-y-auto">
          <v-list-item
              v-for="(item, index) in dropdownReqDocuments"
              :key="index"
              style="max-height: 30px"
          >
            <v-checkbox
                @click.native.capture="checkboxClicked(index,$event,file,item)"
                :indeterminate="file.indeterminate[index] == 2"
                v-model="file.selectedDocs[index]"
                color="success"
            >
            </v-checkbox>
            <!--        <v-checkbox v-else v-model="file.statuses[index].selected" @click="$emit('patchUpload',{'document':item.id,'docStatus':file.statuses[index].selected,'uploadId':file.id})"></v-checkbox>-->
            <v-list-item-title style="font-size: 0.8rem">{{ item.document_name }}</v-list-item-title>
          </v-list-item>
          <v-list-item
              v-if="!isReviewed"
          >
            <v-checkbox @click.native.capture="checkboxClicked(99,$event,file)"
                        color="success"
            >
            </v-checkbox>
            <!--        <v-checkbox v-else v-model="file.statuses[index].selected" @click="$emit('patchUpload',{'document':item.id,'docStatus':file.statuses[index].selected,'uploadId':file.id})"></v-checkbox>-->
            <v-list-item-title style="font-size: 0.8rem">None of the above</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "UploadItem",
  props: {
    isReviewed: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default: {}
    },
    reqDocuments: {
      type: Array,
      default: []
    },
    allReqDocuments: {
      type: Array,
      default: []
    },
    currentUser: {
      type: Object,
      default: {
        role_id:0
      }
    }
  },
  data: () => ({
     menuOpened:false,
     dropdownReqDocuments:[]
   }),
  watch:{
    reqDocuments(val){
      if (!this.isReviewed){
        this.dropdownReqDocuments = this.reqDocuments
        this.updateFile()
      }
    },
    allReqDocuments(val){
      if (this.isReviewed){
        this.dropdownReqDocuments = this.allReqDocuments
        this.updateFile()
      }
    },
    menuOpened(val){
      if (val == false) {
       if (!this.isReviewed){
         const checkedEls = document.getElementsByClassName('v-icon notranslate mdi mdi-checkbox-marked theme--light success--text');
         while (checkedEls.length){
           for (let i = 0; i < checkedEls.length; i++) {
             checkedEls[i].className='v-icon notranslate mdi mdi-checkbox-blank-outline theme--light'
           }
         }
       }
        this.$emit('getUploads',true)
        this.$emit('checkOutstanding',true)
      }
    }
  },
  methods: {
    resetUpload(){
      let n = 0
      this.file.document_progress.forEach(e=>{
        n++
        this.$emit('patchUpload', {'document': e.document, 'docStatus': 0, 'uploadId': this.file.id, e})
      })
      // Hacky way to do this, the server isnt working as fast to  compute the patch then the get
      // If we patch 10 documents the get isnt returning correct data otherwise
      if (n==this.file.document_progress.length){
        setTimeout(()=>{
          this.$emit('getUploads',true)
          this.$emit('checkOutstanding',true)
        },300)
      }
    },
    noneOfTheAbove(file){
       ClientsService.notReqUpload(file.id)
    },
    checkboxClicked(index, event, file,selectedFile) {
      if (index ==99){
        this.noneOfTheAbove(file)
        return;
      }
      event.stopPropagation()
      // console.log(event.target.parentElement.firstChild)
      // console.log(event.target.parentElement.firstChild.firstChild)
      // console.log(event.target.parentElement.firstChild.firstChild.firstChild)

      let el;
      if (event.target.parentElement.firstChild.tagName=='DIV') {
        el = event.target.parentElement.firstChild.firstChild.firstChild
      }else{
        el = event.target.parentElement.firstChild
      }
      //v-input--selection-controls__input
      // el.className = "v-icon notranslate mdi mdi-minus-box theme--light primary--text"
      switch (this.file.indeterminate[index]) {
        case 0:
          this.file.indeterminate[index] = 1;
          el.className = "v-icon notranslate mdi mdi-checkbox-marked theme--light success--text";
          break;
        case 1:
          this.file.indeterminate[index] = 2;
          break;
        case 2:
          this.file.indeterminate[index] = 0;
          el.className = "v-icon notranslate mdi mdi-checkbox-blank-outline theme--light"
          break;
      }
      this.$emit('patchUpload', {'document': selectedFile.document, 'docStatus': file.indeterminate[index], 'uploadId': file.id, file})
      if (this.file.indeterminate[index] == 2) {
        setTimeout(() => {
          el.className = "v-icon notranslate mdi mdi-minus-box theme--light primary--text"
          this.file.selectedDocs[index] = true
        }, 20)
      }
    },
    updateFile(){
      this.file.selectedDocs = []
      this.file.indeterminate = []
      for (const x in this.dropdownReqDocuments) {
        this.file.selectedDocs.push(0)
        this.file.indeterminate.push(0)
      }
      if (this.isReviewed) {
        for (const doc in this.file.document_progress) {
          if (this.file.document_progress[doc].document == 19){
            this.file.selectedDocs[15] = this.file.document_progress[doc].document_status
          }
          this.file.selectedDocs[this.file.document_progress[doc].document - 1] = this.file.document_progress[doc].document_status
          this.file.indeterminate[this.file.document_progress[doc].document - 1] = this.file.document_progress[doc].document_status
        }
      }
    }
  },
  beforeUpdate() {
    this.updateFile()
  },
  created() {
    if (this.isReviewed){
      this.dropdownReqDocuments = this.allReqDocuments
    }else {
      this.dropdownReqDocuments = this.reqDocuments
    }
    this.updateFile()
  }
}
</script>

<style scoped>
#documentChip{
  background-color: #4CAF50;
  margin: 3px;
  padding: 5px;
  border-radius: 15px;
  color: whitesmoke;
  font-weight: bold;
}
.v-application .primary--text {
  color: grey;
}
::v-deep > div.v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.v-input--indeterminate.success--text > div > div.v-input__slot > div > i{
  color: grey !important;
}
::v-deep .v-input--selection-controls__input .v-icon {
  color: #AF0808 !important;
}
</style>
