<template >
    <div>
      <v-data-table
          :items="items"
          :headers="headers"
          @click:row="openLead"
          disable-pagination
          class="row-pointer"
          hide-default-footer
          mobile-breakpoint="0"
          :loading="loading"
      ><template v-slot:item.trade_name="{ item }">
        <span @click="openClientSanitizationPage(item)" class="tradeName">{{item.trade_name}}</span>
      </template>
      </v-data-table>
    </div>
</template>
<script>
import sanitizerService from '../../services/sanitizer.service';

export default {
    name:'HomeSanitizer',
    data:()=>({
      items:[],
      headers: [
        {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: 'trade_name',
            },
            {
                text: 'Days in queue',
                value: 'days_in_queue'
            },
            {
                text: 'Status',
                value: 'status',
            },
        ],
    }),
    methods:{
        getData(){
            sanitizerService.getRTSClients().then(res=>{
                this.items=res
            })
        },
        openClientSanitizationPage(client){
            // this.$store.dispatch("sanitization/setSanitizeData", {
            //     id: client.client_id,
            //     days: client.days_in_queue,
            //     jobID: client.sanitization_job_id,
            //     name: client.trade_name
            //
            // });
            this.$router.push(`/${client.client_id}/jobs/${client.sanitization_job_id}`)
        }
    },

    created() {
        this.getData()
    }
    
}
</script>
<style scoped >
.tradeName{
    cursor:pointer
}
@media only screen and (min-width: 1278px) {
    .tradeName:hover{
        color: rgb(15, 67, 151);
        font-size: 1rem;
        transition: font-size 0.5s;
    }
}

</style>
