<template>
    <v-container>
      <v-overlay :value="loading">
        <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
      <h2>Total Sanitization Jobs: {{items.length}}</h2>
      <v-container fluid>
      <v-radio-group
        v-model="radioRow"
        row
      >
        <v-radio
          label="943"
          value="radio-1"
        ></v-radio>
        <v-radio
          label="944"
          value="radio-2"
        ></v-radio>
      </v-radio-group>
    </v-container>
      <v-data-table
          v-if="rejectedItems.length"
          v-model="selected"
          :headers="rejectedHeaders"
          :items="rejectedItems"
          item-key="client_id"
          @click:row="tradeFirm"
          item-class="itemRowBackground"
          class="elevation-1 mb-6  secondary"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.status="{ item }"><div style="color: #D21919;font-weight: bold">{{item.status}}</div></template>
        <template  v-slot:item.rejected_reason="{ item }">
          <div style="color: #D21919;font-weight: bold">{{item.status_reason}}</div>
        </template>
      </v-data-table>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            item-key="client_id"
            @click:row="tradeFirm"
            class="elevation-1"
            disable-pagination
            hide-default-footer
        >
          <template v-slot:item.status="{ item }">
            <span v-if="item.status == 'Checked out'" style="color: #e1d904;font-weight: bold">{{item.status}}</span>
            <span v-else-if="item.status == 'Available'" style="color: green;font-weight: bold">{{item.status}}</span>
            <span v-else-if="item.status == 'Blocked'" style="color: red;font-weight: bold">{{item.status}}</span>
            <span v-else>{{item.status}}</span>
          </template>
        </v-data-table>
    </v-container>
</template>
<script>
import ClientsService from "@/services/clients.service";

export default {
    data () {
        return {
          rejectedItems:[],
            singleSelect: false,
            selected: [],
          loading:false,
          rejectedHeaders: [
            {
              text: 'Days in Queue',
              align: 'start',
              sortable: false,
              value: 'days_in_queue',
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'trade_name',
            },
            {
              text: 'Status',
              align: 'start',
              sortable: false,
              value: 'status',
            },
            {
              text: 'Rejected Reason',
              align: 'start',
              value: 'rejected_reason',
            }
          ],
            headers: [
              {
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'client_id',
              },{
                text: 'Days in Queue',
                align: 'start',
                sortable: false,
                value: 'days_in_queue',
              },
            {
                text: 'Trade Name',
                align: 'start',
                sortable: false,
                value: 'trade_name',
            },
              {
                text: 'Attorney of record',
                align: 'start',
                sortable: false,
                value: 'aor',
              },
              {
                text: 'Legal Name',
                align: 'start',
                sortable: false,
                value: 'legal_name',
              },
            {
                text: 'Status',
                align: 'start',
                sortable: false,
                value: 'status',
            }
            ],
            items: [],
            radioRow:'' 
            }
    },
    watch:{
      radioRow(newChoice, oldChoice){
        const payload= {
        business_type:'941'
      }
      if (newChoice=='radio-1'){
        payload.business_type='943'
      } else if (newChoice=='radio-2') {
        payload.business_type='944'
      }
        this.sanitizeQueue(payload)
      }
    },
    methods: {
        sanitizeQueue(payload){
          this.loading = true
          ClientsService.getSanitazationQueue(payload).then(res =>{
            // this.items = res
          res.forEach(e=>{
            console.log('test')
            this.rejectedItems = []
            this.items = []
            if (e.status_id == 5){
              this.rejectedItems.push(e)
            }else{
              this.items.push(e)
            }
          })
            this.loading = false
        })
        },
        tradeFirm(id){
            // this.$store.dispatch("sanitization/setSanitizeData", {
            //     id: id.client_id,
            //     days: id.days_in_queue,
            //     jobID: id.sanitization_job_id,
            //     name: id.trade_name
            //
            // });
            // this.$router.push(`/sanitize-client/${id.client_id}`)
          this.$router.push(`/${id.client_id}/jobs/${id.sanitization_job_id}`)

        }
    },
    created(){
        ClientsService.getSanitazationQueue().then(res =>{
            // this.items = res
          res.forEach(e=>{
            if (e.status_id == 5){
              this.rejectedItems.push(e)
            }else{
              this.items.push(e)
            }
          })
        })
    }
}
</script>
<style scoped>
.itemRowBackground{
  background-color: #D21919;
}
</style>
