<template>
   <div>
     <v-progress-linear
         v-if="loading"
         class="mt-10"
         :size="70"
         :width="7"
         color="purple"
         indeterminate
     ></v-progress-linear>
     <component :is="dashboardComponent" ></component>
   </div>
</template>

<script>
import HomeContractor from "@/components/Home/HomeContractor";
import HomeDefault from "@/components/Home/HomeDefault";
import AuthService from "@/services/auth.service";
import HomeManagingPartner from "@/components/Home/HomeManagingPartner";
import HomeSanitizer from "../components/Home/HomeSanitizer.vue";
const RoleDashboardMapping = {
  1: HomeDefault,
  2: HomeDefault,
  3: HomeDefault,
  4: HomeDefault,
  5: HomeDefault,
  6: HomeDefault,
  7: HomeDefault,
  9: HomeManagingPartner,
  10: HomeContractor,
  11: HomeSanitizer,
  12: HomeDefault,
}
export default {
  name: "HomeFactory",
  data: () => ({
    dashboardComponent: 0,
    loading: true
  }),

  async beforeCreate() {
    this.loading = true
    await AuthService.getCurrentUser(this.$store.state.auth.user.current_user_id)
        .then(res=>{
          this.loading = false
          this.dashboardComponent = RoleDashboardMapping[res.data.role_id]
        }).catch(()=>this.loading=false)
  }
}
</script>

<style scoped>

</style>
