<template>
    <v-list class="mt-16">
        <Users v-if="showRightDialogUsers" :key="dialogKeyUsers" :showRightDialogUsers.sync="showRightDialogUsers" />
        <v-list-item class="menu-item" v-for="item in items" :key="item.title" @click="checkIfUsersRoute(item.link)"
            router>
            <v-list-item-icon>
                <v-icon color="black">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import authService from "@/services/auth.service";
import Users from "@/views/Users";
export default {
    name: "TheLayoutWrapMenuList",
    components: { Users },
    data: () => ({
        items: [],
        showRightDialogUsers: false,
        dialogKeyUsers: 100,
        showRightDialog: false

    }),
    watch: {
        currentUser() {
            this.updateNav();
        }
    },
    methods: {
        checkIfUsersRoute(route) {
            if (route == '/users') {
                this.openUsersDialog()
            } else {
                this.$router.push(route).catch(() => { });
            }
        },
        updateNav: async function () {
            let navItems = await authService.getUserNav(this.currentUser.current_user_id);
            this.items = navItems;
        },
        openUsersDialog() {
            this.showRightDialogUsers = true;
            this.dialogKeyUsers++;
        }
    },
    computed: {
        ...mapGetters(
            {
                currentUser: 'auth/getCurrentUser',
            },
        ),
    },
    async created() {
        let navItems = await authService.getUserNav(this.currentUser.current_user_id);
        this.items = navItems;
    }
};

</script>

<style lang="scss" scoped>
.v-list-item--active {
    color: #1976d2 !important;
}

@media only screen and (min-width: 1264px) {
  .v-list {
    margin: 0 !important;
  }
}
</style>
