<template>
 <div>
   <div class="d-flex justify-center">
     <v-progress-circular
         :size="70"
         :width="7"
         color="blue"
         indeterminate
         v-if="loading"
     ></v-progress-circular>
   </div>
   <div class="d-flex flex-column pa-8" id="signed" v-if="items.length">
     <v-hover v-for="(notif,index) in items" :key="index" v-slot="{ hover }">
       <v-card
           outlined
           fluid
           :class="{ disabledCard: notif.status }"
           :elevation="hover ? 12 : 3"
           class="mb-6 pa-5 activeCard d-flex align-center"
       >
         <v-icon class="mr-4" style="cursor: pointer" @click="closeNotif(notif.id,index)">mdi-close</v-icon>
         <span>{{notif.trade_name}}</span>
         <v-menu class="align-self-end" offset-y>
           <template v-slot:activator="{ on, attrs }">
             <v-btn
                 style="right: 30px;position: absolute;padding: 0px 30px;border-radius: 10px"
                 color="rgba(102, 135, 167, 0.75)"
                 :disabled="notif.status == true"
                 v-bind="attrs"
                 v-on="on"
             >
               <span v-if="notif.status">Snoozed for</span>
               <span v-else>Snooze</span>
               <CountdownTimer @enable-card="notif.status=false" v-if="notif.status" :snoozedItem="snoozedItem[index]" @update-parent="updateParent" :key="updateComponent"/>
             </v-btn>
           </template>
           <v-list>
             <v-list-item
                 style="cursor: pointer"
                 v-for="(item, index) in snoozedItems"
                 :key="index"
                 @click="snoozeNotif(notif.id, index)"
             >
               <v-list-item-title>{{ item }}</v-list-item-title>
             </v-list-item>
           </v-list>
         </v-menu>

       </v-card>
     </v-hover>
   </div>
   <div v-else-if="!loading && !items.length" class="d-flex justify-center mt-4">
     <h3>There are no new Signed document notifications</h3>
   </div>
 </div>
</template>

<script>
import NotificationService from "@/services/notification.service";
import CountdownTimer from "./CountdownTimer.vue";
export default {
  name: "NotifSigned",
  data() {
    return {
      items:[],
      loading:true,
      snoozedItems:[
        '15 Minutes',
        '30 Minutes',
        '1 Hour',
        '2 Hours',
        '1 Day'
      ],
      snoozedItem:[],
      updateComponent:false
    }
  },
  components:{
    CountdownTimer
  },
  methods:{
    updateParent(){    
      this.items = [...this.items]
    },
    closeNotif: async function (id,index) {
      await NotificationService.closeNotif(id).then(()=>
          this.items.splice(index,1)
      )
    },
    checkSnoozedTimeout(index){
      switch (this.snoozedItems[index]) {
        case '1 Day':
          return 1440
        case '1 Hour':
          return 60
        case '2 Hours':
          return 120;
        case '15 Minutes':
          return 15;
        case '30 Minutes':
          return 30;
        default:
          break;
      }

    },
      snoozeNotif: async function (id, snoozedItemsIndex) {
        const time = Number(this.snoozedItems[snoozedItemsIndex].substr(0, 2))
        let newArrayIndex=0;
        this.items.forEach((element,i) => {
          if(element.id===id){
            newArrayIndex=i
          }
        });
        this.snoozedItem[newArrayIndex]=this.snoozedItems[snoozedItemsIndex]

        const filterPayload = {"delay_time": time}   
        await NotificationService.snoozeNotif(id,filterPayload).then(()=>NotificationService.getNotifs('signed')
        .then((res)=>{
          this.items=res
          this.items.forEach((element,i)=>{
            if (element.snooze_until){
              this.items[i].status = true
              this.snoozedItem[i]=element.snooze_until
              const today = parseInt((new Date(Date.now()).getTime() / 1000).toFixed(0))
              let secondsUntilSnooze = element.snooze_until - today
              secondsUntilSnooze = Math.floor(secondsUntilSnooze)
              if (Math.floor(secondsUntilSnooze/60)>60){
                this.items[i].showTimer = false
              }else {
                this.items[i].showTimer = true
              }
            }else{
              this.snoozedItem[i]=0
            }
          })
          this.updateComponent=!this.updateComponent
        }
        ))},        
  },
  created() {
    NotificationService.getNotifs('signed')
        .then(res=>{
          this.items=res
          this.items.forEach(element => this.snoozedItem.push(0));
          this.items.forEach((element,i) => {
            if (element.snooze_until){
              this.items[i].status = true
              this.snoozedItem[i]=element.snooze_until
              const today = parseInt((new Date(Date.now()).getTime() / 1000).toFixed(0))
              let secondsUntilSnooze = element.snooze_until - today
              secondsUntilSnooze = Math.floor(secondsUntilSnooze)
              if (Math.floor(secondsUntilSnooze/60)>60){
                this.items[i].showTimer = false
              }else {
                this.items[i].showTimer = true
              }
            }else{
              this.snoozedItem[i]=0
            }
          });
          this.items = [...this.items]
          this.loading = false
        })
  }
}
</script>

<style scoped>
.activeCard{
  background-color: #B1C2D1;
}
.disabledCard{
  background-color: rgba(146, 149, 151, 0.33);
}
</style>
