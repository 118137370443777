const state = () => ({
    IsTourGuideActive: false,
    openMenuForTourGuide: false,
    showTourFinish: false,
    showTourGuideRibbon: true,
    openAdminTab: false,
    openProfileTab: false,
    openPPPtab: false,
    closeTour: false,
});

const getters = {
    getIsTourGuideActive(state) {
        return state.IsTourGuideActive;
    },

    getOpenMenuForTourGuide(state) {
        return state.openMenuForTourGuide;
    },

    getShowTourFinish(state) {
        return state.showTourFinish;
    },

    getShowTourGuideRibbon(state) {
        return state.showTourGuideRibbon;
    },

    getOpenAdminTab(state) {
        return state.openAdminTab
    },

    getOpenProfileTab(state) {
        return state.openProfileTab
    },

    getcloseTour(state) {
        return state.closeTour
    },

    getOpenPPPtab(state) {
        return state.openPPPtab
    },
}

const mutations = {
    setIsTourGuideActive(state, data) {
        state.IsTourGuideActive = data;
    },

    setOpenMenuForTourGuide(state, data) {
        state.openMenuForTourGuide = data;
    },

    setShowTourFinish(state, data) {
        state.showTourFinish = data;
    },

    setShowTourGuideRibbon(state, data) {
        state.showTourGuideRibbon = data;
    },

    setOpenAdminTab(state, data) {
        state.openAdminTab = data;
    },

    setOpenProfileTab(state, data) {
        state.openProfileTab = data;
    },

    setOpenPPPtab(state, data) {
        state.openPPPtab = data;
    },

    setCloseTour(state, data) {
        state.closeTour = data;
    },
}

const actions = {
    changeIsTourGuideActive(vuexContext, data) {
        vuexContext.commit('setIsTourGuideActive', data);
    },

    changeOpenMenuForTourGuide(vuexContext, data) {
        vuexContext.commit('setOpenMenuForTourGuide', data);
    },

    changeShowTourFinish(vuexContext, data) {
        vuexContext.commit('setShowTourFinish', data);
    },

    changeShowTourGuideRibbon(vuexContext, data) {
        vuexContext.commit('setShowTourGuideRibbon', data);
    },

    changeOpenAdminTab(vuexContext, data) {
        vuexContext.commit('setOpenAdminTab', data)
    },

    changeOpenProfileTab(vuexContext, data) {
        vuexContext.commit('setOpenProfileTab', data)
    },
    changeOpenPPPTab(vuexContext, data) {
        vuexContext.commit('setOpenPPPtab', data)
    },
    changeCloseTour(vuexContext, data) {
        vuexContext.commit('setCloseTour', data)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
