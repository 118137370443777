<template>
  <DialogRightAlign :showDialog.sync="showRightDialog" :titleProp="cardTitle">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="90"
          :width="7"
          color="black"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template v-if="isEdit" v-slot:header-button>
      <v-select
          @change="saveLeadStatus"
          :items="statusOptions"
          v-model="lead.status"
          return-object
          item-text="name"
          style="width: 12rem;max-height: 50px"
          :label="lead.status.name"
          solo
      ></v-select>
      <!-- content for the header slot -->
    </template>
    <v-card-text style="padding:30px">
      <v-container fluid style="padding: 0">
        <v-row align="center"
               justify="center" class="pa-3" v-if="lead.status.id == 4">
            <v-btn @click="promote"  class="mb-3 white--text convertButton" elevation="7"
                    color="rgb(38, 59, 79)"  >Convert to Client</v-btn>
        </v-row>

        <v-menu offset-y
                class="hidden-md-and-up"
                v-if="isEdit"
        >

          <template v-slot:activator="{ on, attrs }">
            <v-app-bar
                elevation="4"
                rounded
                shaped
                v-bind="attrs" v-on="on"
                class="hidden-md-and-up mb-3"
            >
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
              {{ tabName }}
            </v-app-bar>
          </template>


          <v-list dense id="navMenu" class="hidden-md-and-up">
            <v-list-item
                link
                @click="()=> changeNavTab(1)"
            >
              <v-list-item-content>
                <v-list-item-title>PROFILE</v-list-item-title>
              </v-list-item-content>
            </v-list-item>



            <v-list-item
                link
                @click="()=> changeNavTab(2)"
            >
              <v-list-item-content>
                <v-list-item-title>NOTES</v-list-item-title>
              </v-list-item-content>
            </v-list-item>



          </v-list>

        </v-menu>
        <v-slide-group
            v-if="isEdit"
            show-arrows
            id="admin-tab"
            class="hidden-sm-and-down visible-sm-up"
        >
          <v-tabs >
            <v-tab v-model="active_tab" style="padding: 0 !important" @click="()=> changeNavTab(1)">PROFILE</v-tab>
            <v-tab v-model="active_tab" style="padding: 0 !important" @click="()=> changeNavTab(2)">NOTES</v-tab>
          </v-tabs>
        </v-slide-group>
        <LeadNotes
            :user="currentUser"
            :client-prop="lead"
            :client-id="lead.id"
            v-if="navTabTwo"/>

        <v-row class="d-flex justify-center align-center" v-if="navTabOne" style="padding: 10px">
          <v-col cols="12" style="padding-top: 15px!important;">
            <v-text-field
                id="business-name"
                v-model="lead.legal_name"
                outlined
                label="Legal Business Name"
                color="black"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
                id="trade-name"
                v-model="lead.trade_name"
                :error-messages="trade_nameErrors"
                required
                outlined
                @input="$v.lead.trade_name.$touch()"
                @blur="$v.lead.trade_name.$touch()"
                label="Trade Name"
                color="black"/>
          </v-col>

          <v-col cols="12" id="radioGroup">
						<v-container fluid class="pa-0">
            
						<v-radio-group
						v-model="lead.business_type"
						row
						>
						 <v-radio
							v-for="(radio, i) in radioButtons"
							:key="i"
							:label="`${radio.name}`"
							:value="i+1"
							@input="$v.lead.business_type.$touch()"
							></v-radio>
						</v-radio-group>
					</v-container>
					</v-col>

          <v-col cols="12">
            <v-text-field
                id="ein"
                v-model="lead.ein"
                :error-messages="einErrors"
                :required="isEdit"
                @input="$v.lead.ein.$touch()"
                @blur="$v.lead.ein.$touch()"
                outlined
                label="EIN"
                maxlength="10"
                color="black"/>
          </v-col>

          <v-col cols="12">
            <v-text-field
                id="street"
                v-model="lead.street"
                outlined
                label="Street"
                color="black"/>
          </v-col>


          <v-col cols="12">
            <v-text-field
                id="contact_phone"
                v-model="lead.contact_phone"
                :error-messages="phone_errors"
                required
                outlined
                @input="$v.lead.contact_phone.$touch()"
                @blur="$v.lead.contact_phone.$touch()"
                label="Phone Number"
                color="black"/>
          </v-col>

          <v-col cols="12">

            <v-row>
              <v-col cols="4">
                <v-text-field
                    id="city"
                    v-model="lead.city"
                    outlined
                    label="City"
                    color="black"/>
              </v-col>
              <v-col cols="4">
                <v-select
                    id="state"
                    :append-icon="'mdi-arrow-down'"
                    outlined
                    :items="states"
                    v-model="lead.state"
                    :item-text="'name'"
                    :item-value="'abbreviation'"
                    label="States"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                    id="zip"
                    v-model="lead.zip"
                    type="number"
                    outlined
                    label="ZIP"
                    color="black"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row>


              <v-col cols="6">
                <v-select
                    id="contact-saluattion"
                    :append-icon="'mdi-arrow-down'"
                    outlined
                    :items="salutationList"
                    :error-messages="salutation_err"
                    required
                    v-model="lead.contact_salutation"
                    @input="$v.lead.contact_salutation.$touch()"
                    @blur="$v.lead.contact_salutation.$touch()"
                    :item-text="'name'"
                    :item-value="'name'"
                    label="Mrs"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field
                    id="contact-first-name-full"
                    v-model="lead.contact_first"
                    :error-messages="contact_firstErrors"
                    required
                    outlined
                    @input="$v.lead.contact_first.$touch()"
                    @blur="$v.lead.contact_first.$touch()"
                    label="First Name for the point of contact"
                    color="black"/>
              </v-col>

            </v-row>

          </v-col>

          <v-col cols="12">
            <v-text-field
                id="contact-last-name"
                v-model="lead.contact_last"
                :error-messages="contact_lastErrors"
                required
                outlined
                @input="$v.lead.contact_last.$touch()"
                @blur="$v.lead.contact_last.$touch()"
                label="Last Name for the point of contact"
                color="black"/>
          </v-col>

          <v-col cols="12">
            <v-text-field
                id="contact-email"
                v-model="lead.contact_email"
                :error-messages="contact_emailErrors"
                required
                outlined
                @input="$v.lead.contact_email.$touch()"
                @blur="$v.lead.contact_email.$touch()"
                label="Email for the point of contact"
                color="black"/>

            <v-switch v-model="lead.uses_peo" :value="1" :input-value="0" class="mt-0 mb-3" label="Client Uses PEO"></v-switch>
          </v-col>
<!--          <v-col cols="12" v-if="isEdit">-->
<!--&lt;!&ndash;            <v-text-field&ndash;&gt;-->
<!--&lt;!&ndash;                id="contact-email"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="calendly"&ndash;&gt;-->
<!--&lt;!&ndash;                @click="goToCalendlyLink"&ndash;&gt;-->
<!--&lt;!&ndash;                outlined&ndash;&gt;-->
<!--&lt;!&ndash;                label="Calendly Link"&ndash;&gt;-->
<!--&lt;!&ndash;                color="black"></v-text-field>&ndash;&gt;-->
<!--           <div style="width: 100%;position: relative;">-->
<!--             <a :href="calendly"  target="_blank" class="calenlyLink">{{calendly}}</a>-->
<!--           </div>-->
<!--          </v-col>-->
        </v-row>
      </v-container>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions  v-if="navTabOne" class="mt-5">
      <v-btn
          v-if="isEdit"
          text
          @click.native="deleteLead"
      >
        Delete Lead
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          color="#263B4F"
          text
          @click.native="closeDialog"
      >
        close
      </v-btn>

      <v-btn
          color="#263B4F"
          text
          @click.native="createNewLead"
          :disabled="$v.lead.$invalid"
      >
        {{ btnName }}
      </v-btn>

    </v-card-actions>
  </DialogRightAlign>
</template>

<script>
import {email, required, helpers} from 'vuelidate/lib/validators';
import ClientService from "../../services/clients.service";
import DialogRightAlign from "@/components/Base/DialogRightAlign";
import LeadService from "@/services/lead.service";
import {statesConstData} from '@/constants'
import {mapGetters} from "vuex"
import TaxService from "../../services/tax.service";
import LawService from "../../services/law.service"
import AuthService from "../../services/auth.service"
import LeadNotes from "@/components/Lead/LeadNotes";
const einValidator = helpers.regex('einValidator', /[0-9]{2}-[0-9]{7}/)


export default {
  name: "LeadCreateEditDialog",
  components: {LeadNotes, DialogRightAlign},

  props: {
    showRightDialog: {
      type: Boolean,
      default: false
    },

    clientProp: {
      type: Object,
      default: null
    },
    isEdit:{
      type:Boolean,
      default: false
    }
  },

  data: () => ({
    loading:false,
    einError:'',
    active_tab:1,
    statusOptions:[
      {status:'Test1',id:1},
      {status:'New',id:2},
      {status:'Completed',id:3},
    ],
    workflowOrder:0,
    selectedStatus:'New',
    navTabOne:true,
    navTabTwo:false,
    tabName:'Profile',
    btnName: '',
    cardTitle: '',
    radioButtons:[],
    salutationList: [
      {
        name: 'Mr.'
      },
      {
        name: 'Mrs.'
      },
      {
        name: 'Ms.'
      },
      {
        name: 'Dr.'
      },
    ],
    lead: {
      legal_name: "",
      trade_name: "",
      ein: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      contact_first: "",
      contact_last: "",
      contact_email: "",
      business_type:1,
      // tax_firm: '',
      // managing_firm: '',
      // contingency_rate: '',
      contact_salutation: "",
      contact_phone:"",
      uses_peo: 0,
    },
    // calendly:"https://calendly.com/ertc-posteritylegal/15min",
    states: statesConstData,
    taxes: [],
    laws: [],
    clientInitData: {},

    currentUserEntityId: null,
  }),

  validations: {

    lead: {
      contact_phone:{
        required
      },
      trade_name: {
        // required,
      },
      // business_type: {
      //   required
      // },
      ein: {
        einValidator
      },
      contact_first: {
        required,
      },
      contact_last: {
        required,
      },
      contact_email: {
        required,
        email
      },
      // tax_firm: {
      //   required,
      // },
      // managing_firm: {
      //   required,
      // },
      contact_salutation: {
        required,
      }
    }
  },

  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),



    legal_nameErrors() {
      return this.handleErrors(this.$v.lead.legal_name, 'Legal Name Field is required.');
    },
    phone_errors() {
      return this.handleErrors(this.$v.lead.contact_phone, 'Phone Field is required.');
    },
    salutation_err() {
      return this.handleErrors(this.$v.lead.contact_salutation, 'Salutation Field is required.');
    },
    trade_nameErrors() {
      return this.handleErrors(this.$v.lead.trade_name, 'Trade Name Field is required.');
    },

    streetErrors() {
      return this.handleErrors(this.$v.lead.street, 'Street Field is required.');
    },

    cityErrors() {
      return this.handleErrors(this.$v.lead.city, 'City Field is required.');
    },

    stateErrors() {
      return this.handleErrors(this.$v.lead.state, 'State Field is required.');
    },

    zipErrors() {
      return this.handleErrors(this.$v.lead.zip, 'ZIP Field is required.');
    },

    contact_firstErrors() {
      return this.handleErrors(this.$v.lead.contact_first, 'Contact First Field is required.');
    },

    contact_lastErrors() {
      return this.handleErrors(this.$v.lead.contact_last, 'Contact Last Field is required.');
    },

    einErrors() {
      const errors = []
      if (!this.$v.lead.ein.$dirty && this.lead.status.id == 4) return errors
      !this.$v.lead.ein.einValidator && errors.push('Your EIN format is wrong. Ex: 00-0000000 (Use Digits only)')
      !this.$v.lead.ein.required && errors.push('EIN is required for converting to client!')
      if (this.lead.status.id == 4) return errors
    },

    contact_emailErrors() {
      const errors = []
      if (!this.$v.lead.contact_email.$dirty) return errors
      !this.$v.lead.contact_email.email && errors.push('Please add a valid email.')
      !this.$v.lead.contact_email.required && errors.push('Email Field is required.')
      return errors
    },


  },

  methods: {
    changeNavTab(tab){
      switch (tab) {
        case 1:
          this.navTabOne = true
          this.navTabTwo = false
          this.active_tab = 1
          this.tabName = "PROFILE"
          break;
        case 2:
          this.navTabTwo = true;
          this.navTabOne = false
          this.active_tab = 2
          this.tabName = "NOTES"
          break;
      }
    },


    handleErrors(valObject, errorMsg) {
      const errors = [];

      if (!valObject.$error) return errors;
      !valObject.required && errors.push(errorMsg);
      return errors;
    },
    promote(){
      let valid = /[0-9]{2}-[0-9]{7}/.test(this.lead.ein)
      if (!this.lead.ein || !valid){
        this.$func.popMessage('EIN is required field to promote to client.','danger')
        return
      }
      if (!this.clientProp.ein){
        LeadService.editLead(this.lead.id,{"ein":this.lead.ein})
            .then(()=>{
              this.loading = true
              LeadService.leadPromoteToClient(this.clientProp.id)
                  .then(()=>{
                    this.loading = false
                    this.closeDialog()
                    this.$emit('forceUpdate');
                  }).catch(()=>this.loading=false)
            })
      }else {
        this.loading = true
        LeadService.leadPromoteToClient(this.clientProp.id)
            .then(()=>{
              this.loading = false
              this.closeDialog()
              this.$emit('forceUpdate');
            }).catch(()=>this.loading=false)
      }

    },
    saveLeadStatus(){
      this.loading = true
      LeadService.editLead(this.lead.id, {"status":this.lead.status.id})
          .then(res=>{
            this.loading=false
            this.lead = res
          }).catch(()=>this.loading=false)
    },
    createNewLead() {
      this.loading = true
      if (this.isEdit) {
        const combinedObject = {...this.clientProp, ...this.lead}

        const diff = Object.entries(combinedObject).reduce((acc, [key, value]) => {

          if (
              this.clientProp[key] !== value ||
              this.lead[key] !== value
              // !Object.values(this.clientProp).includes(value) ||
              // !Object.values(this.client).includes(value)
          )
            acc[key] = value

          return acc
        }, {})

        diff.contact_salutation= this.lead.contact_salutation
        diff.status= this.clientProp.status.id
        // diff.calendly_link = this.calendly
        return LeadService.editLead(this.lead.id, diff)
            .then(res=> {
              this.closeDialog();
              this.loading = false
              this.$emit('forceUpdate');
              this.$func.popMessage('Lead updated successfully!','success')
            })
            .catch((error) => {
              this.$func.popMessage(`${error.response.data.error}`, 'fail')
            });
      } else {
         let leadPostModel = this.lead
         delete leadPostModel.status
         if(!leadPostModel.ein) leadPostModel.ein = null
         LeadService.createLead(leadPostModel)
            .then(res => {
               this.$emit('getLeads');
               this.$emit('forceUpdate');
              this.loading = false
              this.closeDialog();

              this.$store.dispatch('snackbar/showSnackbarMessage',
                  {message: 'Lead was created!', duration: 6000})

            })
            .catch((error) => {
              this.loading = false
              this.$func.popMessage(`${error.response.data.error}`, 'fail')
            });
      }
    },
    deleteLead(){
      this.loading = true

      LeadService.deleteLead(this.clientProp.id)
            .then(()=>{
              this.closeDialog()
              this.$func.popMessage('Lead deleted successfully!','success')
              this.loading = false
            }).catch((err)=>{
        this.loading=false
        this.$func.popMessage(err,'danger')
      })
    },
    getStatuses() {
      LeadService.getLeadStatuses()
          .then(res=>this.statusOptions=res)
    },
    closeDialog() {
      this.$emit('update:showRightDialog', false);
      this.lead = {}
    },
    loadBusinessTypes(){
				ClientService.getBusinessTypes().then(res=>{
					this.radioButtons=res
				})
				
			}
  },



  async created() {
    this.loading = true
    await this.loadBusinessTypes()
    if (this.isEdit) {
      this.lead = Object.assign({}, this.clientProp)
      if (this.clientProp.contact_salutation?.length){
        this.lead.contact_salutation = this.clientProp.contact_salutation;
      }
      // this.calendly = this.clientProp.calendly_link
      this.cardTitle = 'Edit Lead Profile';
      this.btnName = 'Save Profile';
    } else {
      this.lead=this.clientProp
      this.cardTitle = 'Create Lead Profile';
      this.btnName = 'Create Lead';
    }
    
    await this.getStatuses()
    this.loading = false
    this.workflowOrder = this.clientProp.status.workflow_order
  }
}
</script>

<style scoped lang="scss">
.delete_btn {
  background-color: red;
}
.convertButton{
  height: 3.5rem !important;
  width: 100% !important;
  position: relative;
}

@media (min-width:801px) {
  .convertButton{
    width: 50% !important;
  }
}
.convertButton {
  --border-size: 3px;
  --border-angle: 0turn;
  width: 60vmin;
  height: 50vmin;
  background-image: conic-gradient(
      from var(--border-angle),
      #213,
      #112 50%,
      #213
  ),
  conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
  background-size: calc(100% - (var(--border-size) * 2))
  calc(100% - (var(--border-size) * 2)),
  cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

//&:hover {
//   animation-play-state: paused;
// }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.col-12 {
  padding: 0px !important;
}

.v-text-field {
  border-top: 0px !important;
}
.calenlyLink{
  position: absolute;
  padding: 15px 15px;
  margin-bottom: 25px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  width: 100%;
  box-sizing: border-box
}
#einError{
  color: red;
  width:100%;
  text-align: left;
  margin-left: 1rem;
}
#radioGroup{
		margin-top: -25px;
}
</style>
